import React from "react";

import { NavLink } from "react-router-dom"

const BottomMenu = (props) => {


  return (

    <div>
      <ul id="menubottom">
        {/*  <li>
            <NavLink className="link" to="/gallery" activeClassName="circle">gallery</NavLink>
          </li> */}
        <li>
          <NavLink className={props.shopClassName} to="/eshop" exact activeClassName="circle">shop</NavLink>
        </li>
        <li><NavLink className={props.collectionsClassName} to="/current" activeClassName="circle">collections</NavLink></li>
        <li><NavLink className="link" to="/shipping-returns" activeClassName="circle">shipping</NavLink></li>
        <li><NavLink className="link" to="/connect" activeClassName="circle">connect</NavLink></li>
      </ul>
    </div>

  )

}


export default BottomMenu;