import React from "react";

import Cube from "../../components/cube";

import { SS22 } from "../../js/database/database-ss22.js";
import { AW22 } from "../../js/database/database-aw22.js";
import { SS23 } from "../../js/database/database-ss23.js";
import { AW23 } from "../../js/database/database-aw23-eshop.js";

import EshopContainer from "../../components/containers/EshopContainer";

const createSaleCubes = (category) => {
  return Object.keys(category)
    .filter((key) => category[key].onSale)
    .map((key) => {
      const item = category[key];
      return (
        <Cube
          key={key}
          itemName={item.itemName}
          itemLink={item.link}
          itemPrice={item.itemPrice}
          picture01={item.picture01}
          picture02={item.picture02}
          onSale={item.onSale}
          soldOut={item.soldOut}
        />
      );
    });
};

const Sales = (props) => {
  const onSaleJacketsAW23 = createSaleCubes(AW23.jackets);
  const onSalePantsAW23 = createSaleCubes(AW23.pants);
  const onSaleTopsAW23 = createSaleCubes(AW23.tops);
  const onSaleAccessoriesAW23 = createSaleCubes(AW23.accessories);

  // Concatenate the Cube components from all categories
  const allSaleItemsCubesAW23 = [].concat(
    onSaleJacketsAW23,
    onSalePantsAW23,
    onSaleTopsAW23,
    onSaleAccessoriesAW23
  );

  return (
    <EshopContainer pageTitle={"Sales"} shopClassName={"circle"}>
      {allSaleItemsCubesAW23}

      {/* ///////// */}
      <Cube
        itemName={SS23.tops.SleazyNetKnitGreen.itemName}
        itemLink={SS23.tops.SleazyNetKnitGreen.link}
        itemPrice={SS23.tops.SleazyNetKnitGreen.itemPrice}
        re
        picture01={SS23.tops.SleazyNetKnitGreen.picture01}
        picture02={SS23.tops.SleazyNetKnitGreen.picture02}
        onSale={SS23.tops.SleazyNetKnitGreen.onSale}
        itemCategory={SS23.tops.category}
      />

      <Cube
        itemName={SS23.jackets.PanelledCoat.itemName}
        itemLink={SS23.jackets.PanelledCoat.link}
        itemPrice={SS23.jackets.PanelledCoat.itemPrice}
        picture01={SS23.jackets.PanelledCoat.picture01}
        picture02={SS23.jackets.PanelledCoat.picture02}
        onSale={SS23.jackets.PanelledCoat.onSale}
      />

      <Cube
        itemName={SS23.jackets.ChainCuffLeatherJacket.itemName}
        itemLink={SS23.jackets.ChainCuffLeatherJacket.link}
        itemPrice={SS23.jackets.ChainCuffLeatherJacket.itemPrice}
        picture01={SS23.jackets.ChainCuffLeatherJacket.picture01}
        picture02={SS23.jackets.ChainCuffLeatherJacket.picture02}
      />

      <Cube
        itemName={SS23.tops.LeatherBondedCableKnitVest.itemName}
        itemLink={SS23.tops.LeatherBondedCableKnitVest.link}
        itemPrice={SS23.tops.LeatherBondedCableKnitVest.itemPrice}
        picture01={SS23.tops.LeatherBondedCableKnitVest.picture01}
        picture02={SS23.tops.LeatherBondedCableKnitVest.picture02}
        onSale={SS23.tops.LeatherBondedCableKnitVest.onSale}
        itemCategory={SS23.tops.category}
      />

      <Cube
        itemName={SS23.tops.LeatherVest.itemName}
        itemLink={SS23.tops.LeatherVest.link}
        itemPrice={SS23.tops.LeatherVest.itemPrice}
        picture01={SS23.tops.LeatherVest.picture01}
        picture02={SS23.tops.LeatherVest.picture02}
        onSale={SS23.tops.LeatherVest.onSale}
        itemCategory={SS23.tops.category}
      />

      <Cube
        itemName={SS23.pants.ChainlockBikerPants.itemName}
        itemLink={SS23.pants.ChainlockBikerPants.link}
        itemPrice={SS23.pants.ChainlockBikerPants.itemPrice}
        picture01={SS23.pants.ChainlockBikerPants.picture01}
        picture02={SS23.pants.ChainlockBikerPants.picture02}
      />

      <Cube
        itemName={SS23.tops.SleazyNetKnitApricot.itemName}
        itemLink={SS23.tops.SleazyNetKnitApricot.link}
        itemPrice={SS23.tops.SleazyNetKnitApricot.itemPrice}
        picture01={SS23.tops.SleazyNetKnitApricot.picture01}
        picture02={SS23.tops.SleazyNetKnitApricot.picture02}
        onSale={SS23.tops.SleazyNetKnitApricot.onSale}
        itemCategory={SS23.tops.category}
      />

      <Cube
        itemName={SS23.pants.GatheredLeatherPants.itemName}
        itemLink={SS23.pants.GatheredLeatherPants.link}
        itemPrice={SS23.pants.GatheredLeatherPants.itemPrice}
        picture01={SS23.pants.GatheredLeatherPants.picture01}
        picture02={SS23.pants.GatheredLeatherPants.picture02}
      />

      <Cube
        itemName={SS23.jackets.PanelledLeatherCoat.itemName}
        itemLink={SS23.jackets.PanelledLeatherCoat.link}
        itemPrice={SS23.jackets.PanelledLeatherCoat.itemPrice}
        picture01={SS23.jackets.PanelledLeatherCoat.picture01}
        picture02={SS23.jackets.PanelledLeatherCoat.picture02}
        onSale={SS23.jackets.PanelledLeatherCoat.onSale}
      />

      <Cube
        itemName={SS23.pants.ChainlockLeatherBikerPants.itemName}
        itemLink={SS23.pants.ChainlockLeatherBikerPants.link}
        itemPrice={SS23.pants.ChainlockLeatherBikerPants.itemPrice}
        picture01={SS23.pants.ChainlockLeatherBikerPants.picture01}
        picture02={SS23.pants.ChainlockLeatherBikerPants.picture02}
        onSale={SS23.pants.ChainlockLeatherBikerPants.onSale}
      />

      <Cube
        itemName={SS23.tops.GatheredSleeveShirtRedBrown.itemName}
        itemLink={SS23.tops.GatheredSleeveShirtRedBrown.link}
        itemPrice={SS23.tops.GatheredSleeveShirtRedBrown.itemPrice}
        picture01={SS23.tops.GatheredSleeveShirtRedBrown.picture01}
        picture02={SS23.tops.GatheredSleeveShirtRedBrown.picture02}
        itemCategory={SS23.tops.category}
      />

      <Cube
        itemName={SS23.tops.RibKnitVest.itemName}
        itemLink={SS23.tops.RibKnitVest.link}
        itemPrice={SS23.tops.RibKnitVest.itemPrice}
        picture01={SS23.tops.RibKnitVest.picture01}
        picture02={SS23.tops.RibKnitVest.picture02}
        itemCategory={SS23.tops.category}
      />

      <Cube
        itemName={SS23.tops.PanelledTee.itemName}
        itemLink={SS23.tops.PanelledTee.link}
        itemPrice={SS23.tops.PanelledTee.itemPrice}
        picture01={SS23.tops.PanelledTee.picture01}
        picture02={SS23.tops.PanelledTee.picture02}
        itemCategory={SS23.tops.category}
      />

      <Cube
        itemName={SS23.pants.CowhidePencilSkirt.itemName}
        itemLink={SS23.pants.CowhidePencilSkirt.link}
        itemPrice={SS23.pants.CowhidePencilSkirt.itemPrice}
        picture01={SS23.pants.CowhidePencilSkirt.picture01}
        picture02={SS23.pants.CowhidePencilSkirt.picture02}
        onSale={SS23.pants.CowhidePencilSkirt.onSale}
      />

      <Cube
        itemName={SS23.tops.GatheredSleeveShirtWhite.itemName}
        itemLink={SS23.tops.GatheredSleeveShirtWhite.link}
        itemPrice={SS23.tops.GatheredSleeveShirtWhite.itemPrice}
        picture01={SS23.tops.GatheredSleeveShirtWhite.picture01}
        picture02={SS23.tops.GatheredSleeveShirtWhite.picture02}
        itemCategory={SS23.tops.category}
      />

      <Cube
        itemName={SS23.pants.GatheredPants.itemName}
        itemLink={SS23.pants.GatheredPants.link}
        itemPrice={SS23.pants.GatheredPants.itemPrice}
        picture01={SS23.pants.GatheredPants.picture01}
        picture02={SS23.pants.GatheredPants.picture02}
      />

      <Cube
        itemName={SS23.tops.VestDress.itemName}
        itemLink={SS23.tops.VestDress.link}
        itemPrice={SS23.tops.VestDress.itemPrice}
        picture01={SS23.tops.VestDress.picture01}
        picture02={SS23.tops.VestDress.picture02}
        onSale={SS23.tops.VestDress.onSale}
        itemCategory={SS23.tops.category}
      />

      <Cube
        itemName={SS23.tops.PhotographicPrintTee.itemName}
        itemLink={SS23.tops.PhotographicPrintTee.link}
        itemPrice={SS23.tops.PhotographicPrintTee.itemPrice}
        picture01={SS23.tops.PhotographicPrintTee.picture01}
        picture02={SS23.tops.PhotographicPrintTee.picture02}
        itemCategory={SS23.tops.category}
      />

      <Cube
        itemName={SS23.tops.GatheredSleeveShirtDarkBrown.itemName}
        itemLink={SS23.tops.GatheredSleeveShirtDarkBrown.link}
        itemPrice={SS23.tops.GatheredSleeveShirtDarkBrown.itemPrice}
        picture01={SS23.tops.GatheredSleeveShirtDarkBrown.picture01}
        picture02={SS23.tops.GatheredSleeveShirtDarkBrown.picture02}
        onSale={SS23.tops.GatheredSleeveShirtDarkBrown.onSale}
        itemCategory={SS23.tops.category}
      />

      <Cube
        itemName={SS23.pants.TyreSkirt.itemName}
        itemLink={SS23.pants.TyreSkirt.link}
        itemPrice={SS23.pants.TyreSkirt.itemPrice}
        picture01={SS23.pants.TyreSkirt.picture01}
        picture02={SS23.pants.TyreSkirt.picture02}
      />

      <Cube
        itemName={SS23.tops.SleazyNetKnitBlack.itemName}
        itemLink={SS23.tops.SleazyNetKnitBlack.link}
        itemPrice={SS23.tops.SleazyNetKnitBlack.itemPrice}
        picture01={SS23.tops.SleazyNetKnitBlack.picture01}
        picture02={SS23.tops.SleazyNetKnitBlack.picture02}
        onSale={SS23.tops.SleazyNetKnitBlack.onSale}
      />

      <Cube
        itemName={SS23.tops.CowhideVest.itemName}
        itemLink={SS23.tops.CowhideVest.link}
        itemPrice={SS23.tops.CowhideVest.itemPrice}
        picture01={SS23.tops.CowhideVest.picture01}
        picture02={SS23.tops.CowhideVest.picture02}
        onSale={SS23.tops.CowhideVest.onSale}
      />

      <Cube
        itemName={SS23.jackets.NylonBomberVest.itemName}
        itemLink={SS23.jackets.NylonBomberVest.link}
        itemPrice={SS23.jackets.NylonBomberVest.itemPrice}
        picture01={SS23.jackets.NylonBomberVest.picture01}
        picture02={SS23.jackets.NylonBomberVest.picture02}
        onSale={SS23.jackets.NylonBomberVest.onSale}
      />

      <Cube
        itemName={SS23.pants.ChainlockPencilSkirt.itemName}
        itemLink={SS23.pants.ChainlockPencilSkirt.link}
        itemPrice={SS23.pants.ChainlockPencilSkirt.itemPrice}
        picture01={SS23.pants.ChainlockPencilSkirt.picture01}
        picture02={SS23.pants.ChainlockPencilSkirt.picture02}
      />

      {/* ///////////// SALE */}

      <Cube
        itemName={AW22.jackets.CharcoalLizardBlazer.itemName}
        itemLink={AW22.jackets.CharcoalLizardBlazer.link}
        itemPrice={AW22.jackets.CharcoalLizardBlazer.itemPrice}
        onSale={AW22.jackets.CharcoalLizardBlazer.onSale}
        picture01={AW22.jackets.CharcoalLizardBlazer.picture01}
        picture02={AW22.jackets.CharcoalLizardBlazer.picture02}
      />

      <Cube
        itemName={AW22.jackets.DropShoulderPUCoat.itemName}
        itemLink={AW22.jackets.DropShoulderPUCoat.link}
        itemPrice={AW22.jackets.DropShoulderPUCoat.itemPrice}
        onSale={AW22.jackets.DropShoulderPUCoat.onSale}
        picture01={AW22.jackets.DropShoulderPUCoat.picture01}
        picture02={AW22.jackets.DropShoulderPUCoat.picture02}
      />

      <Cube
        itemName={AW22.jackets.BomberWithRestrainedArms.itemName}
        itemLink={AW22.jackets.BomberWithRestrainedArms.link}
        itemPrice={AW22.jackets.BomberWithRestrainedArms.itemPrice}
        onSale={AW22.jackets.BomberWithRestrainedArms.onSale}
        picture01={AW22.jackets.BomberWithRestrainedArms.picture01}
        picture02={AW22.jackets.BomberWithRestrainedArms.picture02}
      />

      <Cube
        itemName={AW22.tops.NightShoreSilkShirt.itemName}
        itemLink={AW22.tops.NightShoreSilkShirt.link}
        itemPrice={AW22.tops.NightShoreSilkShirt.itemPrice}
        onSale={AW22.tops.NightShoreSilkShirt.onSale}
        picture01={AW22.tops.NightShoreSilkShirt.picture01}
        picture02={AW22.tops.NightShoreSilkShirt.picture02}
      />
      <Cube
        itemName={AW22.tops.SnowWoodEmbroideredTee.itemName}
        itemLink={AW22.tops.SnowWoodEmbroideredTee.link}
        itemPrice={AW22.tops.SnowWoodEmbroideredTee.itemPrice}
        onSale={AW22.tops.SnowWoodEmbroideredTee.onSale}
        picture01={AW22.tops.SnowWoodEmbroideredTee.picture01}
        picture02={AW22.tops.SnowWoodEmbroideredTee.picture02}
      />
      <Cube
        itemName={AW22.pants.AdjustableDrawstringPants.itemName}
        itemLink={AW22.pants.AdjustableDrawstringPants.link}
        itemPrice={AW22.pants.AdjustableDrawstringPants.itemPrice}
        onSale={AW22.pants.AdjustableDrawstringPants.onSale}
        picture01={AW22.pants.AdjustableDrawstringPants.picture01}
        picture02={AW22.pants.AdjustableDrawstringPants.picture02}
      />
      <Cube
        itemName={AW22.jackets.BikerJacketWithArmStraps.itemName}
        itemLink={AW22.jackets.BikerJacketWithArmStraps.link}
        itemPrice={AW22.jackets.BikerJacketWithArmStraps.itemPrice}
        onSale={AW22.jackets.BikerJacketWithArmStraps.onSale}
        picture01={AW22.jackets.BikerJacketWithArmStraps.picture01}
        picture02={AW22.jackets.BikerJacketWithArmStraps.picture02}
      />
      <Cube
        itemName={AW22.tops.NightShoreEmbroideredTee.itemName}
        itemLink={AW22.tops.NightShoreEmbroideredTee.link}
        itemPrice={AW22.tops.NightShoreEmbroideredTee.itemPrice}
        onSale={AW22.tops.NightShoreEmbroideredTee.onSale}
        picture01={AW22.tops.NightShoreEmbroideredTee.picture01}
        picture02={AW22.tops.NightShoreEmbroideredTee.picture02}
      />
      <Cube
        itemName={AW22.jackets.LeatherBomberWithRestrainedArms.itemName}
        itemLink={AW22.jackets.LeatherBomberWithRestrainedArms.link}
        itemPrice={AW22.jackets.LeatherBomberWithRestrainedArms.itemPrice}
        onSale={AW22.jackets.LeatherBomberWithRestrainedArms.onSale}
        picture01={AW22.jackets.LeatherBomberWithRestrainedArms.picture01}
        picture02={AW22.jackets.LeatherBomberWithRestrainedArms.picture02}
      />
      <Cube
        itemName={AW22.tops.CamouflageZipUpWorkShirt.itemName}
        itemLink={AW22.tops.CamouflageZipUpWorkShirt.link}
        itemPrice={AW22.tops.CamouflageZipUpWorkShirt.itemPrice}
        onSale={AW22.tops.CamouflageZipUpWorkShirt.onSale}
        picture01={AW22.tops.CamouflageZipUpWorkShirt.picture01}
        picture02={AW22.tops.CamouflageZipUpWorkShirt.picture02}
      />
      <Cube
        itemName={AW22.pants.MudRootPrintedJeans.itemName}
        itemLink={AW22.pants.MudRootPrintedJeans.link}
        itemPrice={AW22.pants.MudRootPrintedJeans.itemPrice}
        onSale={AW22.pants.MudRootPrintedJeans.onSale}
        picture01={AW22.pants.MudRootPrintedJeans.picture01}
        picture02={AW22.pants.MudRootPrintedJeans.picture02}
      />
      <Cube
        itemName={AW22.tops.MudRootCompressionTop.itemName}
        itemLink={AW22.tops.MudRootCompressionTop.link}
        itemPrice={AW22.tops.MudRootCompressionTop.itemPrice}
        onSale={AW22.tops.MudRootCompressionTop.onSale}
        picture01={AW22.tops.MudRootCompressionTop.picture01}
        picture02={AW22.tops.MudRootCompressionTop.picture02}
      />
      <Cube
        itemName={AW22.tops.MixedRibZipUpCardigan.itemName}
        itemLink={AW22.tops.MixedRibZipUpCardigan.link}
        itemPrice={AW22.tops.MixedRibZipUpCardigan.itemPrice}
        onSale={AW22.tops.MixedRibZipUpCardigan.onSale}
        picture01={AW22.tops.MixedRibZipUpCardigan.picture01}
        picture02={AW22.tops.MixedRibZipUpCardigan.picture02}
      />
      <Cube
        itemName={AW22.jackets.PanelledMacCoat.itemName}
        itemLink={AW22.jackets.PanelledMacCoat.link}
        itemPrice={AW22.jackets.PanelledMacCoat.itemPrice}
        onSale={AW22.jackets.PanelledMacCoat.onSale}
        picture01={AW22.jackets.PanelledMacCoat.picture01}
        picture02={AW22.jackets.PanelledMacCoat.picture02}
      />
      {/*  <Cube
        itemName={AW22.tops.AdjustableDrawstringTopGrey.itemName}
        itemLink={AW22.tops.AdjustableDrawstringTopGrey.link}
        itemPrice={AW22.tops.AdjustableDrawstringTopGrey.itemPrice}
        onSale={AW22.tops.AdjustableDrawstringTopGrey.onSale}
        picture01={AW22.tops.AdjustableDrawstringTopGrey.picture01}
        picture02={AW22.tops.AdjustableDrawstringTopGrey.picture02}
      /> */}
      <Cube
        itemName={AW22.pants.LeatherBikerPants.itemName}
        itemLink={AW22.pants.LeatherBikerPants.link}
        itemPrice={AW22.pants.LeatherBikerPants.itemPrice}
        onSale={AW22.pants.LeatherBikerPants.onSale}
        picture01={AW22.pants.LeatherBikerPants.picture01}
        picture02={AW22.pants.LeatherBikerPants.picture02}
      />
      <Cube
        itemName={AW22.tops.NightShoreCompressionTop.itemName}
        itemLink={AW22.tops.NightShoreCompressionTop.link}
        itemPrice={AW22.tops.NightShoreCompressionTop.itemPrice}
        onSale={AW22.tops.NightShoreCompressionTop.onSale}
        picture01={AW22.tops.NightShoreCompressionTop.picture01}
        picture02={AW22.tops.NightShoreCompressionTop.picture02}
      />
      <Cube
        itemName={AW22.pants.CamouflageCowhidePants.itemName}
        itemLink={AW22.pants.CamouflageCowhidePants.link}
        itemPrice={AW22.pants.CamouflageCowhidePants.itemPrice}
        onSale={AW22.pants.CamouflageCowhidePants.onSale}
        picture01={AW22.pants.CamouflageCowhidePants.picture01}
        picture02={AW22.pants.CamouflageCowhidePants.picture02}
      />
      <Cube
        itemName={AW22.tops.KnittedTankWithShoulderCap.itemName}
        itemLink={AW22.tops.KnittedTankWithShoulderCap.link}
        itemPrice={AW22.tops.KnittedTankWithShoulderCap.itemPrice}
        picture01={AW22.tops.KnittedTankWithShoulderCap.picture01}
        picture02={AW22.tops.KnittedTankWithShoulderCap.picture02}
      />
      <Cube
        itemName={AW22.tops.AdjustableDrawstringTopBeige.itemName}
        itemLink={AW22.tops.AdjustableDrawstringTopBeige.link}
        itemPrice={AW22.tops.AdjustableDrawstringTopBeige.itemPrice}
        onSale={AW22.tops.AdjustableDrawstringTopBeige.onSale}
        picture01={AW22.tops.AdjustableDrawstringTopBeige.picture01}
        picture02={AW22.tops.AdjustableDrawstringTopBeige.picture02}
      />
      <Cube
        itemName={AW22.tops.MudRootEmbroideredTee.itemName}
        itemLink={AW22.tops.MudRootEmbroideredTee.link}
        itemPrice={AW22.tops.MudRootEmbroideredTee.itemPrice}
        onSale={AW22.tops.MudRootEmbroideredTee.onSale}
        picture01={AW22.tops.MudRootEmbroideredTee.picture01}
        picture02={AW22.tops.MudRootEmbroideredTee.picture02}
      />

      {/*   01 Chainlock Leather Coat   */}

      <Cube
        itemName={SS22.jackets.chainlockLeatherCoat.itemName}
        itemLink={SS22.jackets.chainlockLeatherCoat.link}
        itemPrice={SS22.jackets.chainlockLeatherCoat.itemPrice}
        onSale={SS22.jackets.chainlockLeatherCoat.onSale}
        picture01={SS22.jackets.chainlockLeatherCoat.picture01}
        picture02={SS22.jackets.chainlockLeatherCoat.picture02}
      />

      {/*   02 Mohair Loose Knit  Jumper Sand   */}

      <Cube
        itemName={SS22.tops.mohairLooseKnitJumper.itemName}
        itemPrice={SS22.tops.mohairLooseKnitJumper.itemPrice}
        itemLink={SS22.tops.mohairLooseKnitJumper.sand.link}
        onSale={SS22.tops.mohairLooseKnitJumper.onSale}
        picture01={SS22.tops.mohairLooseKnitJumper.sand.picture01}
        picture02={SS22.tops.mohairLooseKnitJumper.sand.picture02}
      />

      {/*   03  Cable Knit tank  black */}

      {/*    <Cube
        itemName={SS22.tops.cableKnitTank.itemName}
        itemPrice={SS22.tops.cableKnitTank.itemPrice}
        itemLink={SS22.tops.cableKnitTank.black.link}
        onSale={SS22.tops.cableKnitTank.onSale}
        picture01={SS22.tops.cableKnitTank.black.picture01}
        picture02={SS22.tops.cableKnitTank.black.picture02}
      /> */}

      {/*   04 Motobike Compression top    */}

      <Cube
        itemName={SS22.tops.motorbikeCompressionTop.itemName}
        itemPrice={SS22.tops.motorbikeCompressionTop.itemPrice}
        itemLink={SS22.tops.motorbikeCompressionTop.link}
        onSale={SS22.tops.motorbikeCompressionTop.onSale}
        picture01={SS22.tops.motorbikeCompressionTop.picture01}
        picture02={SS22.tops.motorbikeCompressionTop.picture02}
      />

      {/*   05 Two-piece_Leather_Vest orange  */}

      <Cube
        itemName={SS22.tops.twopieceLeatherVestOrange.itemName}
        itemPrice={SS22.tops.twopieceLeatherVestOrange.itemPrice}
        itemLink={SS22.tops.twopieceLeatherVestOrange.link}
        onSale={SS22.tops.twopieceLeatherVestOrange.onSale}
        picture01={SS22.tops.twopieceLeatherVestOrange.picture01}
        picture02={SS22.tops.twopieceLeatherVestOrange.picture02}
      />

      {/*   06 Chainlock_Nylon_Pants   */}

      <Cube
        itemName={SS22.pants.chainlockNylonPants.itemName}
        itemPrice={SS22.pants.chainlockNylonPants.itemPrice}
        itemLink={SS22.pants.chainlockNylonPants.link}
        onSale={SS22.pants.chainlockNylonPants.onSale}
        picture01={SS22.pants.chainlockNylonPants.picture01}
        picture02={SS22.pants.chainlockNylonPants.picture02}
      />

      {/*   07 Detachable_Sleeve_Bomber  grey */}

      <Cube
        itemName={SS22.jackets.detachableSleeveBomberGrey.itemName}
        itemPrice={SS22.jackets.detachableSleeveBomberGrey.itemPrice}
        itemLink={SS22.jackets.detachableSleeveBomberGrey.link}
        onSale={SS22.jackets.detachableSleeveBomberGrey.onSale}
        picture01={SS22.jackets.detachableSleeveBomberGrey.picture01}
        picture02={SS22.jackets.detachableSleeveBomberGrey.picture02}
      />

      {/*   08 Shark Speciment Resort shirt   */}

      <Cube
        itemName={SS22.tops.sharkSpecimenResortShirt.itemName}
        itemPrice={SS22.tops.sharkSpecimenResortShirt.itemPrice}
        itemLink={SS22.tops.sharkSpecimenResortShirt.link}
        onSale={SS22.tops.sharkSpecimenResortShirt.onSale}
        picture01={SS22.tops.sharkSpecimenResortShirt.picture01}
        picture02={SS22.tops.sharkSpecimenResortShirt.picture02}
      />

      {/*   09 Wet_Specimen_Tee   */}

      <Cube
        itemName={SS22.tops.wetSpecimenTee.itemName}
        itemPrice={SS22.tops.wetSpecimenTee.itemPrice}
        itemLink={SS22.tops.wetSpecimenTee.link}
        onSale={SS22.tops.wetSpecimenTee.onSale}
        picture01={SS22.tops.wetSpecimenTee.picture01}
        picture02={SS22.tops.wetSpecimenTee.picture02}
      />

      {/*   10 Shark_Specimen_Boxer_Shorts   */}

      <Cube
        itemName={SS22.pants.sharkSpecimenBoxerShorts.itemName}
        itemPrice={SS22.pants.sharkSpecimenBoxerShorts.itemPrice}
        itemLink={SS22.pants.sharkSpecimenBoxerShorts.link}
        onSale={SS22.pants.sharkSpecimenBoxerShorts.onSale}
        picture01={SS22.pants.sharkSpecimenBoxerShorts.picture01}
        picture02={SS22.pants.sharkSpecimenBoxerShorts.picture02}
      />

      {/*   11 Panneled Wool coat   */}

      <Cube
        itemName={SS22.jackets.panneledWoolCoat.itemName}
        itemLink={SS22.jackets.panneledWoolCoat.link}
        itemPrice={SS22.jackets.panneledWoolCoat.itemPrice}
        onSale={SS22.jackets.panneledWoolCoat.onSale}
        picture01={SS22.jackets.panneledWoolCoat.picture01}
        picture02={SS22.jackets.panneledWoolCoat.picture02}
      />

      {/*   13 Panneled_Leather_Biker_Pants  */}

      <Cube
        itemName={SS22.pants.panneledLeatherBikerPants.itemName}
        itemPrice={SS22.pants.panneledLeatherBikerPants.itemPrice}
        itemLink={SS22.pants.panneledLeatherBikerPants.link}
        onSale={SS22.pants.panneledLeatherBikerPants.onSale}
        picture01={SS22.pants.panneledLeatherBikerPants.picture01}
        picture02={SS22.pants.panneledLeatherBikerPants.picture02}
      />

      {/*   14 Leather_Zip_Shirt_Jacket  */}

      <Cube
        itemName={SS22.jackets.leatherZipShirtJacket.itemName}
        itemLink={SS22.jackets.leatherZipShirtJacket.link}
        itemPrice={SS22.jackets.leatherZipShirtJacket.itemPrice}
        picture01={SS22.jackets.leatherZipShirtJacket.picture01}
        picture02={SS22.jackets.leatherZipShirtJacket.picture02}
      />

      {/*   15 Two-Piece_Leather-Vest  black*/}

      <Cube
        itemName={SS22.tops.twopieceLeatherVestBlack.itemName}
        itemPrice={SS22.tops.twopieceLeatherVestBlack.itemPrice}
        itemLink={SS22.tops.twopieceLeatherVestBlack.link}
        onSale={SS22.tops.twopieceLeatherVestBlack.onSale}
        picture01={SS22.tops.twopieceLeatherVestBlack.picture01}
        picture02={SS22.tops.twopieceLeatherVestBlack.picture02}
      />

      {/*   16 Lamb_Skin_Work_Jacket green */}

      {/*  <Cube
        itemName={SS22.jackets.lambSkinWorkJacket.itemName}
        itemPrice={SS22.jackets.lambSkinWorkJacket.itemPrice}
        itemLink={SS22.jackets.lambSkinWorkJacket.green.link}
        onSale={SS22.jackets.lambSkinWorkJacket.onSale}
        picture01={SS22.jackets.lambSkinWorkJacket.green.picture01}
        picture02={SS22.jackets.lambSkinWorkJacket.green.picture02}
      /> */}

      {/*   17 Charcoal_Lizard_Speciment_pant  */}

      <Cube
        itemName={SS22.pants.charcoalLizardSpecimenPants.itemName}
        itemPrice={SS22.pants.charcoalLizardSpecimenPants.itemPrice}
        itemLink={SS22.pants.charcoalLizardSpecimenPants.link}
        onSale={SS22.pants.charcoalLizardSpecimenPants.onSale}
        picture01={SS22.pants.charcoalLizardSpecimenPants.picture01}
        picture02={SS22.pants.charcoalLizardSpecimenPants.picture02}
      />

      {/*   18 Asymetrical_Bleached_Tank  */}

      <Cube
        itemName={SS22.tops.asymetricalBleachedTank.itemName}
        itemPrice={SS22.tops.asymetricalBleachedTank.itemPrice}
        itemLink={SS22.tops.asymetricalBleachedTank.link}
        onSale={SS22.tops.asymetricalBleachedTank.onSale}
        picture01={SS22.tops.asymetricalBleachedTank.picture01}
        picture02={SS22.tops.asymetricalBleachedTank.picture02}
      />

      {/*   19 Iron Gate Compression top  */}

      <Cube
        itemName={SS22.tops.ironGateCompressionTop.itemName}
        itemPrice={SS22.tops.ironGateCompressionTop.itemPrice}
        itemLink={SS22.tops.ironGateCompressionTop.link}
        onSale={SS22.tops.ironGateCompressionTop.onSale}
        picture01={SS22.tops.ironGateCompressionTop.picture01}
        picture02={SS22.tops.ironGateCompressionTop.picture02}
      />

      {/*   20 Chainlock Leather vest  */}

      {/*  <Cube
        itemName={SS22.tops.chainlockLeatherVest.itemName}
        itemPrice={SS22.tops.chainlockLeatherVest.itemPrice}
        itemLink={SS22.tops.chainlockLeatherVest.link}
        onSale={SS22.tops.chainlockLeatherVest.onSale}
        picture01={SS22.tops.chainlockLeatherVest.picture01}
        picture02={SS22.tops.chainlockLeatherVest.picture02}
      /> */}

      {/*   21 Chainlock_Denim_Jacket  */}

      <Cube
        itemName={SS22.jackets.chainlockDenimJacket.itemName}
        itemPrice={SS22.jackets.chainlockDenimJacket.itemPrice}
        itemLink={SS22.jackets.chainlockDenimJacket.link}
        onSale={SS22.jackets.chainlockDenimJacket.onSale}
        picture01={SS22.jackets.chainlockDenimJacket.picture01}
        picture02={SS22.jackets.chainlockDenimJacket.picture02}
      />

      {/*   22 Skycrapers_Compression_Top */}

      {/* <Cube
        itemName={SS22.tops.skyscrapersCompressionTop.itemName}
        itemPrice={SS22.tops.skyscrapersCompressionTop.itemPrice}
        itemLink={SS22.tops.skyscrapersCompressionTop.link}
        onSale={SS22.tops.skyscrapersCompressionTop.onSale}
        picture01={SS22.tops.skyscrapersCompressionTop.picture01}
        picture02={SS22.tops.skyscrapersCompressionTop.picture02}
      /> */}

      {/*   23 Shark_Specimen_Shirt*/}

      <Cube
        itemName={SS22.tops.sharkSpecimenShirt.itemName}
        itemPrice={SS22.tops.sharkSpecimenShirt.itemPrice}
        itemLink={SS22.tops.sharkSpecimenShirt.link}
        onSale={SS22.tops.sharkSpecimenShirt.onSale}
        picture01={SS22.tops.sharkSpecimenShirt.picture01}
        picture02={SS22.tops.sharkSpecimenShirt.picture02}
      />

      {/*   24 Charcoal Lizard Specimen jacket*/}

      <Cube
        itemName={SS22.jackets.charcoalLizardSpecimenJacket.itemName}
        itemPrice={SS22.jackets.charcoalLizardSpecimenJacket.itemPrice}
        itemLink={SS22.jackets.charcoalLizardSpecimenJacket.link}
        onSale={SS22.jackets.charcoalLizardSpecimenJacket.onSale}
        picture01={SS22.jackets.charcoalLizardSpecimenJacket.picture01}
        picture02={SS22.jackets.charcoalLizardSpecimenJacket.picture02}
      />

      {/*   25 Detachable_Sleeve_Bomber_pink*/}

      <Cube
        itemName={SS22.jackets.detachableSleeveBomberPink.itemName}
        itemPrice={SS22.jackets.detachableSleeveBomberPink.itemPrice}
        itemLink={SS22.jackets.detachableSleeveBomberPink.link}
        onSale={SS22.jackets.detachableSleeveBomberPink.onSale}
        picture01={SS22.jackets.detachableSleeveBomberPink.picture01}
        picture02={SS22.jackets.detachableSleeveBomberPink.picture02}
      />

      {/*   26 Lamb_Skin_Work_Jacket black*/}

      <Cube
        itemName={SS22.jackets.lambSkinWorkJacket.itemName}
        itemPrice={SS22.jackets.lambSkinWorkJacket.itemPrice}
        itemLink={SS22.jackets.lambSkinWorkJacket.black.link}
        onSale={SS22.jackets.lambSkinWorkJacket.onSale}
        picture01={SS22.jackets.lambSkinWorkJacket.black.picture01}
        picture02={SS22.jackets.lambSkinWorkJacket.black.picture02}
      />

      {/*   27 Mohair_Loose_Knit_Jumper orange*/}

      {/*    <Cube
        itemName={SS22.tops.mohairLooseKnitJumper.itemName}
        itemPrice={SS22.tops.mohairLooseKnitJumper.itemPrice}
        itemLink={SS22.tops.mohairLooseKnitJumper.orange.link}
        onSale={SS22.tops.mohairLooseKnitJumper.onSale}
        picture01={SS22.tops.mohairLooseKnitJumper.orange.picture01}
        picture02={SS22.tops.mohairLooseKnitJumper.orange.picture02}
      /> */}

      {/*   28 Lizard_Specimen_Compression_Top*/}
      <Cube
        itemName={SS22.tops.lizardSpecimenCompressionTop.itemName}
        itemPrice={SS22.tops.lizardSpecimenCompressionTop.itemPrice}
        itemLink={SS22.tops.lizardSpecimenCompressionTop.link}
        onSale={SS22.tops.lizardSpecimenCompressionTop.onSale}
        picture01={SS22.tops.lizardSpecimenCompressionTop.picture01}
        picture02={SS22.tops.lizardSpecimenCompressionTop.picture02}
      />

      {/*   29 Cable_Knit_Tank cream*/}

      <Cube
        itemName={SS22.tops.cableKnitTank.itemName}
        itemPrice={SS22.tops.cableKnitTank.itemPrice}
        itemLink={SS22.tops.cableKnitTank.cream.link}
        onSale={SS22.tops.cableKnitTank.onSale}
        picture01={SS22.tops.cableKnitTank.cream.picture01}
        picture02={SS22.tops.cableKnitTank.cream.picture02}
      />

      {/*   30 Charcoal_Lizard_Specimen_Overshirt*/}

      <Cube
        itemName={SS22.tops.charcoalLizardSpecimenOvershirt.itemName}
        itemPrice={SS22.tops.charcoalLizardSpecimenOvershirt.itemPrice}
        itemLink={SS22.tops.charcoalLizardSpecimenOvershirt.link}
        onSale={SS22.tops.charcoalLizardSpecimenOvershirt.onSale}
        picture01={SS22.tops.charcoalLizardSpecimenOvershirt.picture01}
        picture02={SS22.tops.charcoalLizardSpecimenOvershirt.picture02}
      />

      {/*   31 Chainlock_Jeans*/}

      <Cube
        itemName={SS22.pants.chainlockJeans.itemName}
        itemPrice={SS22.pants.chainlockJeans.itemPrice}
        itemLink={SS22.pants.chainlockJeans.link}
        onSale={SS22.pants.chainlockJeans.onSale}
        picture01={SS22.pants.chainlockJeans.picture01}
        picture02={SS22.pants.chainlockJeans.picture02}
      />

      {/*   32 Chainlock_Tee*/}

      <Cube
        itemName={SS22.tops.chainlockTee.itemName}
        itemPrice={SS22.tops.chainlockTee.itemPrice}
        itemLink={SS22.tops.chainlockTee.link}
        onSale={SS22.tops.chainlockTee.onSale}
        picture01={SS22.tops.chainlockTee.picture01}
        picture02={SS22.tops.chainlockTee.picture02}
      />

      {/*   33 Eyelet Denim Shorts*/}

      <Cube
        itemName={SS22.pants.eyeletDenimShorts.itemName}
        itemPrice={SS22.pants.eyeletDenimShorts.itemPrice}
        itemLink={SS22.pants.eyeletDenimShorts.link}
        onSale={SS22.pants.eyeletDenimShorts.onSale}
        picture01={SS22.pants.eyeletDenimShorts.picture01}
        picture02={SS22.pants.eyeletDenimShorts.picture02}
      />

      {/*   34 Lizard_Specimen_Tee*/}

      <Cube
        itemName={SS22.tops.lizardSpecimenTee.itemName}
        itemPrice={SS22.tops.lizardSpecimenTee.itemPrice}
        itemLink={SS22.tops.lizardSpecimenTee.link}
        onSale={SS22.tops.lizardSpecimenTee.onSale}
        picture01={SS22.tops.lizardSpecimenTee.picture01}
        picture02={SS22.tops.lizardSpecimenTee.picture02}
      />

      {/*   3 Chainlock Leather shorts*/}

      {/*     <Cube
        itemName={SS22.pants.chainlockLeatherShorts.itemName}
        itemPrice={SS22.pants.chainlockLeatherShorts.itemPrice}
        itemLink={SS22.pants.chainlockLeatherShorts.link}
        picture01={SS22.pants.chainlockLeatherShorts.picture01}
        picture02={SS22.pants.chainlockLeatherShorts.picture02}
      /> */}

      {/*   36 Detachable Sleeve bomber black*/}

      <Cube
        itemName={SS22.jackets.detachableSleeveBomberBlack.itemName}
        itemPrice={SS22.jackets.detachableSleeveBomberBlack.itemPrice}
        itemLink={SS22.jackets.detachableSleeveBomberBlack.link}
        onSale={SS22.jackets.detachableSleeveBomberBlack.onSale}
        picture01={SS22.jackets.detachableSleeveBomberBlack.picture01}
        picture02={SS22.jackets.detachableSleeveBomberBlack.picture02}
      />

      {/*   37 Charcoal Wet Specimen shorts */}

      {/*  <Cube
        itemName={SS22.pants.charcoalWetSpecimenShorts.itemName}
        itemPrice={SS22.pants.charcoalWetSpecimenShorts.itemPrice}
        itemLink={SS22.pants.charcoalWetSpecimenShorts.link}
        onSale={SS22.pants.charcoalWetSpecimenShorts.onSale}
        picture01={SS22.pants.charcoalWetSpecimenShorts.picture01}
        picture02={SS22.pants.charcoalWetSpecimenShorts.picture02}
      />
 */}
      {/*   38 Double_Breasted_Lock_Blazer */}

      <Cube
        itemName={SS22.jackets.doubleBreastedLockBlazer.itemName}
        itemPrice={SS22.jackets.doubleBreastedLockBlazer.itemPrice}
        itemLink={SS22.jackets.doubleBreastedLockBlazer.link}
        onSale={SS22.jackets.doubleBreastedLockBlazer.onSale}
        picture01={SS22.jackets.doubleBreastedLockBlazer.picture01}
        picture02={SS22.jackets.doubleBreastedLockBlazer.picture02}
      />

      {/*   39 Skycrapers_Tee */}

      {/*     <Cube
        itemName={SS22.tops.skyscrapersTee.itemName}
        itemPrice={SS22.tops.skyscrapersTee.itemPrice}
        itemLink={SS22.tops.skyscrapersTee.link}
        onSale={SS22.tops.skyscrapersTee.onSale}
        picture01={SS22.tops.skyscrapersTee.picture01}
        picture02={SS22.tops.skyscrapersTee.picture02}
      /> */}

      {/*   39 Skycrapers_Tee */}

      {/*   <Cube
        itemName={SS22.tops.skyscrapersTee.itemName}
        itemPrice={SS22.tops.skyscrapersTee.itemPrice}
        itemLink={SS22.tops.skyscrapersTee.link}
        onSale={SS22.tops.skyscrapersTee.onSale}
        picture01={SS22.tops.skyscrapersTee.picture01}
        picture02={SS22.tops.skyscrapersTee.picture02}
      /> */}

      {/* 
      <Cube
        itemName={AW21.jackets.CutUpChainedOvercoatBlack.itemName}
        itemPrice={AW21.jackets.CutUpChainedOvercoatBlack.itemPrice}
        itemLink={AW21.jackets.CutUpChainedOvercoatBlack.link}
        picture01={AW21.jackets.CutUpChainedOvercoatBlack.picture01}
        picture02={AW21.jackets.CutUpChainedOvercoatBlack.picture02}
      />

      <Cube
        itemName={AW21.jackets.OStuddedLeatherJacket.itemName}
        itemPrice={AW21.jackets.OStuddedLeatherJacket.itemPrice}
        itemLink={AW21.jackets.OStuddedLeatherJacket.link}
        picture01={AW21.jackets.OStuddedLeatherJacket.picture01}
        picture02={AW21.jackets.OStuddedLeatherJacket.picture02}
      />

      <Cube
        itemName={AW21.jackets.PeepholeUniformJacket.itemName}
        itemPrice={AW21.jackets.PeepholeUniformJacket.itemPrice}
        itemLink={AW21.jackets.PeepholeUniformJacket.link}
        picture01={AW21.jackets.PeepholeUniformJacket.picture01}
        picture02={AW21.jackets.PeepholeUniformJacket.picture02}
      />

      <Cube
        itemName={AW21.jackets.DetachableCollarWindJacketBlack.itemName}
        itemPrice={AW21.jackets.DetachableCollarWindJacketBlack.itemPrice}
        itemLink={AW21.jackets.DetachableCollarWindJacketBlack.link}
        picture01={AW21.jackets.DetachableCollarWindJacketBlack.picture01}
        picture02={AW21.jackets.DetachableCollarWindJacketBlack.picture02}
      />

      <Cube
        itemName={AW21.jackets.CutUpChainedOvercoatStripe.itemName}
        itemPrice={AW21.jackets.CutUpChainedOvercoatStripe.itemPrice}
        itemLink={AW21.jackets.CutUpChainedOvercoatStripe.link}
        picture01={AW21.jackets.CutUpChainedOvercoatStripe.picture01}
        picture02={AW21.jackets.CutUpChainedOvercoatStripe.picture02}
      />

      <Cube
        itemName={AW21.jackets.DrummerBomberJacketRed.itemName}
        itemPrice={AW21.jackets.DrummerBomberJacketRed.itemPrice}
        itemLink={AW21.jackets.DrummerBomberJacketRed.link}
        picture01={AW21.jackets.DrummerBomberJacketRed.picture01}
        picture02={AW21.jackets.DrummerBomberJacketRed.picture02}
      />

      <Cube
        itemName={AW21.jackets.ScrapbookJacquardJacket.itemName}
        itemPrice={AW21.jackets.ScrapbookJacquardJacket.itemPrice}
        itemLink={AW21.jackets.ScrapbookJacquardJacket.link}
        picture01={AW21.jackets.ScrapbookJacquardJacket.picture01}
        picture02={AW21.jackets.ScrapbookJacquardJacket.picture02}
      />

      <Cube
        itemName={AW21.jackets.DrummerBomberJacketArmyGreen.itemName}
        itemPrice={AW21.jackets.DrummerBomberJacketArmyGreen.itemPrice}
        itemLink={AW21.jackets.DrummerBomberJacketArmyGreen.link}
        picture01={AW21.jackets.DrummerBomberJacketArmyGreen.picture01}
        picture02={AW21.jackets.DrummerBomberJacketArmyGreen.picture02}
      />

      <Cube
        itemName={AW21.jackets.DetachableCollarWindJacketArmyGreen.itemName}
        itemPrice={AW21.jackets.DetachableCollarWindJacketArmyGreen.itemPrice}
        itemLink={AW21.jackets.DetachableCollarWindJacketArmyGreen.link}
        picture01={AW21.jackets.DetachableCollarWindJacketArmyGreen.picture01}
        picture02={AW21.jackets.DetachableCollarWindJacketArmyGreen.picture02}
      />

      <Cube
        itemName={AW21.jackets.DrummerBomberJacketChampagne.itemName}
        itemPrice={AW21.jackets.DrummerBomberJacketChampagne.itemPrice}
        itemLink={AW21.jackets.DrummerBomberJacketChampagne.link}
        picture01={AW21.jackets.DrummerBomberJacketChampagne.picture01}
        picture02={AW21.jackets.DrummerBomberJacketChampagne.picture02}
      />


      <Cube
        itemName={AW21.pants.PeepholeLeatherTrousers.itemName}
        itemPrice={AW21.pants.PeepholeLeatherTrousers.itemPrice}
        itemLink={AW21.pants.PeepholeLeatherTrousers.link}
        picture01={AW21.pants.PeepholeLeatherTrousers.picture01}
        picture02={AW21.pants.PeepholeLeatherTrousers.picture02}
      />

      <Cube
        itemName={AW21.pants.PeepholeVTrousers.itemName}
        itemPrice={AW21.pants.PeepholeVTrousers.itemPrice}
        itemLink={AW21.pants.PeepholeVTrousers.link}
        picture01={AW21.pants.PeepholeVTrousers.picture01}
        picture02={AW21.pants.PeepholeVTrousers.picture02}
      />
      <Cube
        itemName={AW21.pants.BikerPanelledLeatherTrousers.itemName}
        itemPrice={AW21.pants.BikerPanelledLeatherTrousers.itemPrice}
        itemLink={AW21.pants.BikerPanelledLeatherTrousers.link}
        picture01={AW21.pants.BikerPanelledLeatherTrousers.picture01}
        picture02={AW21.pants.BikerPanelledLeatherTrousers.picture02}
      />

      <Cube
        itemName={AW21.pants.BikerPanelledTrousersRed.itemName}
        itemPrice={AW21.pants.BikerPanelledTrousersRed.itemPrice}
        itemLink={AW21.pants.BikerPanelledTrousersRed.link}
        picture01={AW21.pants.BikerPanelledTrousersRed.picture01}
        picture02={AW21.pants.BikerPanelledTrousersRed.picture02}
      />

      <Cube
        itemName={AW21.pants.BikerPanelledTrousersCreamWhite.itemName}
        itemPrice={AW21.pants.BikerPanelledTrousersCreamWhite.itemPrice}
        itemLink={AW21.pants.BikerPanelledTrousersCreamWhite.link}
        picture01={AW21.pants.BikerPanelledTrousersCreamWhite.picture01}
        picture02={AW21.pants.BikerPanelledTrousersCreamWhite.picture02}
      />

      <Cube
        itemName={AW21.pants.ScrapbookComboBikerPants.itemName}
        itemPrice={AW21.pants.ScrapbookComboBikerPants.itemPrice}
        itemLink={AW21.pants.ScrapbookComboBikerPants.link}
        picture01={AW21.pants.ScrapbookComboBikerPants.picture01}
        picture02={AW21.pants.ScrapbookComboBikerPants.picture02}
      />

      <Cube
        itemName={AW21.pants.MixedCheckeredTrousers.itemName}
        itemPrice={AW21.pants.MixedCheckeredTrousers.itemPrice}
        itemLink={AW21.pants.MixedCheckeredTrousers.link}
        picture01={AW21.pants.MixedCheckeredTrousers.picture01}
        picture02={AW21.pants.MixedCheckeredTrousers.picture02}
      />

      <Cube
        itemName={AW21.pants.PeepholeJeans.itemName}
        itemPrice={AW21.pants.PeepholeJeans.itemPrice}
        itemLink={AW21.pants.PeepholeJeans.link}
        picture01={AW21.pants.PeepholeJeans.picture01}
        picture02={AW21.pants.PeepholeJeans.picture02}
      />

      <Cube
        itemName={AW21.pants.PeepholeVJogger.itemName}
        itemPrice={AW21.pants.PeepholeVJogger.itemPrice}
        itemLink={AW21.pants.PeepholeVJogger.link}
        picture01={AW21.pants.PeepholeVJogger.picture01}
        picture02={AW21.pants.PeepholeVJogger.picture02}
      />

      <Cube
        itemName={AW21.pants.CheckeredPeepholeTrousers.itemName}
        itemPrice={AW21.pants.CheckeredPeepholeTrousers.itemPrice}
        itemLink={AW21.pants.CheckeredPeepholeTrousers.link}
        picture01={AW21.pants.CheckeredPeepholeTrousers.picture01}
        picture02={AW21.pants.CheckeredPeepholeTrousers.picture02}
      />

      <Cube
        itemName={AW21.pants.BikerPanelledTrousersDarkBlue.itemName}
        itemPrice={AW21.pants.BikerPanelledTrousersDarkBlue.itemPrice}
        itemLink={AW21.pants.BikerPanelledTrousersDarkBlue.link}
        picture01={AW21.pants.BikerPanelledTrousersDarkBlue.picture01}
        picture02={AW21.pants.BikerPanelledTrousersDarkBlue.picture02}
      />


      <Cube
        itemName={AW21.tops.ShadowWaveJumper.itemName}
        itemPrice={AW21.tops.ShadowWaveJumper.itemPrice}
        itemLink={AW21.tops.ShadowWaveJumper.link}
        picture01={AW21.tops.ShadowWaveJumper.picture01}
        picture02={AW21.tops.ShadowWaveJumper.picture02}
      />

      <Cube
        itemName={AW21.tops.ShadowWaveZipupBlackWhite.itemName}
        itemPrice={AW21.tops.ShadowWaveZipupBlackWhite.itemPrice}
        itemLink={AW21.tops.ShadowWaveZipupBlackWhite.link}
        picture01={AW21.tops.ShadowWaveZipupBlackWhite.picture01}
        picture02={AW21.tops.ShadowWaveZipupBlackWhite.picture02}
      />

      <Cube
        itemName={AW21.tops.ShadowWaveZipupCaramelRed.itemName}
        itemPrice={AW21.tops.ShadowWaveZipupCaramelRed.itemPrice}
        itemLink={AW21.tops.ShadowWaveZipupCaramelRed.link}
        picture01={AW21.tops.ShadowWaveZipupCaramelRed.picture01}
        picture02={AW21.tops.ShadowWaveZipupCaramelRed.picture02}
      />

      <Cube
        itemName={AW21.tops.KnitTankBlueGrey.itemName}
        itemPrice={AW21.tops.KnitTankBlueGrey.itemPrice}
        itemLink={AW21.tops.KnitTankBlueGrey.link}
        picture01={AW21.tops.KnitTankBlueGrey.picture01}
        picture02={AW21.tops.KnitTankBlueGrey.picture02}
      />

      <Cube
        itemName={AW21.tops.KnitTankBrownCream.itemName}
        itemPrice={AW21.tops.KnitTankBrownCream.itemPrice}
        itemLink={AW21.tops.KnitTankBrownCream.link}
        picture01={AW21.tops.KnitTankBrownCream.picture01}
        picture02={AW21.tops.KnitTankBrownCream.picture02}
      />

      <Cube
        itemName={AW21.tops.KnitTankRedOlive.itemName}
        itemPrice={AW21.tops.KnitTankRedOlive.itemPrice}
        itemLink={AW21.tops.KnitTankRedOlive.link}
        picture01={AW21.tops.KnitTankRedOlive.picture01}
        picture02={AW21.tops.KnitTankRedOlive.picture02}
      />

      <Cube
        itemName={AW21.tops.KnitTankWhiteGrey.itemName}
        itemPrice={AW21.tops.KnitTankWhiteGrey.itemPrice}
        itemLink={AW21.tops.KnitTankWhiteGrey.link}
        picture01={AW21.tops.KnitTankWhiteGrey.picture01}
        picture02={AW21.tops.KnitTankWhiteGrey.picture02}
      />

      <Cube
        itemName={AW21.tops.KnitTankYellow.itemName}
        itemPrice={AW21.tops.KnitTankYellow.itemPrice}
        itemLink={AW21.tops.KnitTankYellow.link}
        picture01={AW21.tops.KnitTankYellow.picture01}
        picture02={AW21.tops.KnitTankYellow.picture02}
      />

      <Cube
        itemName={AW21.tops.KnitTankOliveBlack.itemName}
        itemPrice={AW21.tops.KnitTankOliveBlack.itemPrice}
        itemLink={AW21.tops.KnitTankOliveBlack.link}
        picture01={AW21.tops.KnitTankOliveBlack.picture01}
        picture02={AW21.tops.KnitTankOliveBlack.picture02}
      />

      <Cube
        itemName={AW21.tops.BodyTankBlack.itemName}
        itemPrice={AW21.tops.BodyTankBlack.itemPrice}
        itemLink={AW21.tops.BodyTankBlack.link}
        picture01={AW21.tops.BodyTankBlack.picture01}
        picture02={AW21.tops.BodyTankBlack.picture02}
      />

      <Cube
        itemName={AW21.tops.BodyTankGreen.itemName}
        itemPrice={AW21.tops.BodyTankGreen.itemPrice}
        itemLink={AW21.tops.BodyTankGreen.link}
        picture01={AW21.tops.BodyTankGreen.picture01}
        picture02={AW21.tops.BodyTankGreen.picture02}
      />

      <Cube
        itemName={AW21.tops.BodyTankRed.itemName}
        itemPrice={AW21.tops.BodyTankRed.itemPrice}
        itemLink={AW21.tops.BodyTankRed.link}
        picture01={AW21.tops.BodyTankRed.picture01}
        picture02={AW21.tops.BodyTankRed.picture02}
      />

      <Cube
        itemName={AW21.tops.BodyTankWhite.itemName}
        itemPrice={AW21.tops.BodyTankWhite.itemPrice}
        itemLink={AW21.tops.BodyTankWhite.link}
        picture01={AW21.tops.BodyTankWhite.picture01}
        picture02={AW21.tops.BodyTankWhite.picture02}
      />

      <Cube
        itemName={AW21.tops.CheckeredVest.itemName}
        itemPrice={AW21.tops.CheckeredVest.itemPrice}
        itemLink={AW21.tops.CheckeredVest.link}
        picture01={AW21.tops.CheckeredVest.picture01}
        picture02={AW21.tops.CheckeredVest.picture02}
      />

      <Cube
        itemName={AW21.tops.JigsawPlantSweater.itemName}
        itemPrice={AW21.tops.JigsawPlantSweater.itemPrice}
        itemLink={AW21.tops.JigsawPlantSweater.link}
        picture01={AW21.tops.JigsawPlantSweater.picture01}
        picture02={AW21.tops.JigsawPlantSweater.picture02}
      />

      <Cube
        itemName={AW21.tops.JigsawPlantVest.itemName}
        itemPrice={AW21.tops.JigsawPlantVest.itemPrice}
        itemLink={AW21.tops.JigsawPlantVest.link}
        picture01={AW21.tops.JigsawPlantVest.picture01}
        picture02={AW21.tops.JigsawPlantVest.picture02}
      />

      <Cube
        itemName={AW21.tops.MixedCheckeredShirt.itemName}
        itemPrice={AW21.tops.MixedCheckeredShirt.itemPrice}
        itemLink={AW21.tops.MixedCheckeredShirt.link}
        picture01={AW21.tops.MixedCheckeredShirt.picture01}
        picture02={AW21.tops.MixedCheckeredShirt.picture02}
      />

      <Cube
        itemName={AW21.tops.PeepholeLeatherTank.itemName}
        itemPrice={AW21.tops.PeepholeLeatherTank.itemPrice}
        itemLink={AW21.tops.PeepholeLeatherTank.link}
        picture01={AW21.tops.PeepholeLeatherTank.picture01}
        picture02={AW21.tops.PeepholeLeatherTank.picture02}
      />

      <Cube
        itemName={AW21.tops.PeepholeZipupJacketShirt.itemName}
        itemPrice={AW21.tops.PeepholeZipupJacketShirt.itemPrice}
        itemLink={AW21.tops.PeepholeZipupJacketShirt.link}
        picture01={AW21.tops.PeepholeZipupJacketShirt.picture01}
        picture02={AW21.tops.PeepholeZipupJacketShirt.picture02}
      />

      <Cube
        itemName={AW21.tops.ScrapbookJacquardShirt.itemName}
        itemPrice={AW21.tops.ScrapbookJacquardShirt.itemPrice}
        itemLink={AW21.tops.ScrapbookJacquardShirt.link}
        picture01={AW21.tops.ScrapbookJacquardShirt.picture01}
        picture02={AW21.tops.ScrapbookJacquardShirt.picture02}
      />

      <Cube
        itemName={AW21.tops.ScrapbookPeepholeTank.itemName}
        itemPrice={AW21.tops.ScrapbookPeepholeTank.itemPrice}
        itemLink={AW21.tops.ScrapbookPeepholeTank.link}
        picture01={AW21.tops.ScrapbookPeepholeTank.picture01}
        picture02={AW21.tops.ScrapbookPeepholeTank.picture02}
      />

      <Cube
        itemName={AW21.tops.SunsetPrintTshirtBlackI.itemName}
        itemPrice={AW21.tops.SunsetPrintTshirtBlackI.itemPrice}
        itemLink={AW21.tops.SunsetPrintTshirtBlackI.link}
        picture01={AW21.tops.SunsetPrintTshirtBlackI.picture01}
        picture02={AW21.tops.SunsetPrintTshirtBlackI.picture02}
      />

      <Cube
        itemName={AW21.tops.SunsetPrintTshirtBlackII.itemName}
        itemPrice={AW21.tops.SunsetPrintTshirtBlackII.itemPrice}
        itemLink={AW21.tops.SunsetPrintTshirtBlackII.link}
        picture01={AW21.tops.SunsetPrintTshirtBlackII.picture01}
        picture02={AW21.tops.SunsetPrintTshirtBlackII.picture02}
      />

      <Cube
        itemName={AW21.tops.SunsetPrintTshirtBlackIII.itemName}
        itemPrice={AW21.tops.SunsetPrintTshirtBlackIII.itemPrice}
        itemLink={AW21.tops.SunsetPrintTshirtBlackIII.link}
        picture01={AW21.tops.SunsetPrintTshirtBlackIII.picture01}
        picture02={AW21.tops.SunsetPrintTshirtBlackIII.picture02}
      />

      <Cube
        itemName={AW21.tops.SunsetPrintTshirtBlackIV.itemName}
        itemPrice={AW21.tops.SunsetPrintTshirtBlackIV.itemPrice}
        itemLink={AW21.tops.SunsetPrintTshirtBlackIV.link}
        picture01={AW21.tops.SunsetPrintTshirtBlackIV.picture01}
        picture02={AW21.tops.SunsetPrintTshirtBlackIV.picture02}
      />

      <Cube
        itemName={AW21.tops.SunsetPrintTshirtWhiteI.itemName}
        itemPrice={AW21.tops.SunsetPrintTshirtWhiteI.itemPrice}
        itemLink={AW21.tops.SunsetPrintTshirtWhiteI.link}
        picture01={AW21.tops.SunsetPrintTshirtWhiteI.picture01}
        picture02={AW21.tops.SunsetPrintTshirtWhiteI.picture02}
      />

      <Cube
        itemName={AW21.tops.SunsetPrintTshirtWhiteII.itemName}
        itemPrice={AW21.tops.SunsetPrintTshirtWhiteII.itemPrice}
        itemLink={AW21.tops.SunsetPrintTshirtWhiteII.link}
        picture01={AW21.tops.SunsetPrintTshirtWhiteII.picture01}
        picture02={AW21.tops.SunsetPrintTshirtWhiteII.picture02}
      />

      <Cube
        itemName={AW21.tops.SunsetPrintTshirtWhiteIII.itemName}
        itemPrice={AW21.tops.SunsetPrintTshirtWhiteIII.itemPrice}
        itemLink={AW21.tops.SunsetPrintTshirtWhiteIII.link}
        picture01={AW21.tops.SunsetPrintTshirtWhiteIII.picture01}
        picture02={AW21.tops.SunsetPrintTshirtWhiteIII.picture02}
      />

      <Cube
        itemName={AW21.tops.UniformShirtBlackBrownCombo.itemName}
        itemPrice={AW21.tops.UniformShirtBlackBrownCombo.itemPrice}
        itemLink={AW21.tops.UniformShirtBlackBrownCombo.link}
        picture01={AW21.tops.UniformShirtBlackBrownCombo.picture01}
        picture02={AW21.tops.UniformShirtBlackBrownCombo.picture02}
      />
      <Cube
        itemName={AW21.tops.UniformShirtCheckered.itemName}
        itemPrice={AW21.tops.UniformShirtCheckered.itemPrice}
        itemLink={AW21.tops.UniformShirtCheckered.link}
        picture01={AW21.tops.UniformShirtCheckered.picture01}
        picture02={AW21.tops.UniformShirtCheckered.picture02}
      />

      <Cube
        itemName={AW21.tops.UniformShirtSand.itemName}
        itemPrice={AW21.tops.UniformShirtSand.itemPrice}
        itemLink={AW21.tops.UniformShirtSand.link}
        picture01={AW21.tops.UniformShirtSand.picture01}
        picture02={AW21.tops.UniformShirtSand.picture02}
      /> */}
    </EshopContainer>
  );
};

export default Sales;
