import parse from 'html-react-parser';

export const AW23 = {

    alt: "Collection AW23",
    imgWidth: 834,
    imgHeight: 1250,
    loading: "eager",

    jackets: {
        LoopsBomber: {

            order: 2,
            itemName: parse('<s>Loops Bomber</s>'),
            itemPrice: parse('<s>965€</s> 675€'),
            itemFabric: "body · lambskin 100%/lining · down 90% feather 10%",
            sizeChart: "../content/photos/eshop/AW23/02_XL_AW23_Loops_Bomber/XL_AW23_Loops_Bomber_Size_Chart.jpg",

            soldOut: true,
            onSale: true,

            shopifyDivId: "1699472032623",
            shopifyId: "8353411596552",
            link: "/loops-bomber",
            picture01: "../content/photos/eshop/AW23/02_XL_AW23_Loops_Bomber/XL_AW23_Loops_Bomber_01.jpg",
            picture02: "../content/photos/eshop/AW23/02_XL_AW23_Loops_Bomber/XL_AW23_Loops_Bomber_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/02_XL_AW23_Loops_Bomber/XL_AW23_Loops_Bomber_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/02_XL_AW23_Loops_Bomber/XL_AW23_Loops_Bomber_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/02_XL_AW23_Loops_Bomber/XL_AW23_Loops_Bomber_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/02_XL_AW23_Loops_Bomber/XL_AW23_Loops_Bomber_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/AW23/02_XL_AW23_Loops_Bomber/XL_AW23_Loops_Bomber_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/AW23/02_XL_AW23_Loops_Bomber/XL_AW23_Loops_Bomber_06.jpg",
                    loading: "eager"
                },
                {
                    key: 7,
                    srcJpeg: "../content/photos/eshop/AW23/02_XL_AW23_Loops_Bomber/XL_AW23_Loops_Bomber_07.jpg",
                    loading: "eager"
                },
            ],
        },
        FlameBikerJacket: {
            order: 6,
            itemName: "Flame Biker jacket",
            itemPrice: parse('<s>965€</s> 675€'),
            itemFabric: "body · lambskin 100%/lining · down 90% feather 10%",
            sizeChart: "../content/photos/eshop/AW23/06_XL_AW23_Flame_Biker_Jacket/XL_AW23_Flame_Biker_Jacket_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1699472228712",
            shopifyId: "8353403371784",
            link: "/flame-biker-jacket",
            picture01: "../content/photos/eshop/AW23/06_XL_AW23_Flame_Biker_Jacket/XL_AW23_Flame_Biker_Jacket_01.jpg",
            picture02: "../content/photos/eshop/AW23/06_XL_AW23_Flame_Biker_Jacket/XL_AW23_Flame_Biker_Jacket_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/06_XL_AW23_Flame_Biker_Jacket/XL_AW23_Flame_Biker_Jacket_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/06_XL_AW23_Flame_Biker_Jacket/XL_AW23_Flame_Biker_Jacket_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/06_XL_AW23_Flame_Biker_Jacket/XL_AW23_Flame_Biker_Jacket_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/06_XL_AW23_Flame_Biker_Jacket/XL_AW23_Flame_Biker_Jacket_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/AW23/06_XL_AW23_Flame_Biker_Jacket/XL_AW23_Flame_Biker_Jacket_05.jpg",
                    loading: "eager"
                },

            ],
        },
        MemoryLeatherJacket: {
            order: 7,
            itemName: parse('<s>Memory Leather jacket</s>'),
            itemPrice: parse('1400€'),
            itemFabric: "body · leather 100%/lining · down 90% feather 10%",
            sizeChart: "../content/photos/eshop/AW23/07_XL_AW23_Memory_Leather_Jacket/XL_AW23_Memory_Leather_Jacket_Size_Chart.jpg",


            onSale: false,
            soldOut: true,

            shopifyDivId: "1699472398258",
            shopifyId: "8353491091720",
            link: "/memory-leather-jacket",
            picture01: "../content/photos/eshop/AW23/07_XL_AW23_Memory_Leather_Jacket/XL_AW23_Memory_Leather_Jacket_01.jpg",
            picture02: "../content/photos/eshop/AW23/07_XL_AW23_Memory_Leather_Jacket/XL_AW23_Memory_Leather_Jacket_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/07_XL_AW23_Memory_Leather_Jacket/XL_AW23_Memory_Leather_Jacket_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/07_XL_AW23_Memory_Leather_Jacket/XL_AW23_Memory_Leather_Jacket_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/07_XL_AW23_Memory_Leather_Jacket/XL_AW23_Memory_Leather_Jacket_03.jpg",
                    loading: "eager"
                },

            ],
        },

        SculpturedLeatherBlazer: {

            itemName: "Sculptured Leather blazer",
            itemPrice: parse('<s>1400€</s> 980€'),
            itemFabric: "leather 100%",
            sizeChart: "../content/photos/eshop/AW23/12_XL_AW23_Sculptured_Leather_Blazer/XL_AW23_Sculptured_Leather_Blazer_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702411036277",
            shopifyId: "8388432134408",
            link: "/sculptured-leather-blazer",
            picture01: "../content/photos/eshop/AW23/12_XL_AW23_Sculptured_Leather_Blazer/XL_AW23_Sculptured_Leather_Blazer_01.jpg",
            picture02: "../content/photos/eshop/AW23/12_XL_AW23_Sculptured_Leather_Blazer/XL_AW23_Sculptured_Leather_Blazer_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/12_XL_AW23_Sculptured_Leather_Blazer/XL_AW23_Sculptured_Leather_Blazer_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/12_XL_AW23_Sculptured_Leather_Blazer/XL_AW23_Sculptured_Leather_Blazer_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/12_XL_AW23_Sculptured_Leather_Blazer/XL_AW23_Sculptured_Leather_Blazer_03.jpg",
                    loading: "eager"
                },

            ],
        },

        GrippedArmJacket: {

            itemName: "Gripped Arm jacket",
            itemPrice: parse('<s>580€</s> 410€'),
            itemFabric: "body · cotton 100%/lining · PU 100%",
            sizeChart: "../content/photos/eshop/AW23/21_XL_AW23_Gripped_Arm_Jacket/XL_AW23_Gripped_Arm_Jacket_Size_Chart.jpg",


            soldOut: false,
            onSale: true,

            shopifyDivId: "1702411233152",
            shopifyId: "8388431511816",
            link: "/gripped-arm-jacket",
            picture01: "../content/photos/eshop/AW23/21_XL_AW23_Gripped_Arm_Jacket/XL_AW23_Gripped_Arm_Jacket_01.jpg",
            picture02: "../content/photos/eshop/AW23/21_XL_AW23_Gripped_Arm_Jacket/XL_AW23_Gripped_Arm_Jacket_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/21_XL_AW23_Gripped_Arm_Jacket/XL_AW23_Gripped_Arm_Jacket_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/21_XL_AW23_Gripped_Arm_Jacket/XL_AW23_Gripped_Arm_Jacket_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/21_XL_AW23_Gripped_Arm_Jacket/XL_AW23_Gripped_Arm_Jacket_03.jpg",
                    loading: "eager"
                },

            ],
        },


    },
    tops: {
        PrintedSweatshirt: {
            order: 3,
            itemName: "Printed sweatshirt",
            itemPrice: parse('<s>270€</s> 190€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW23/03_XL_AW23_Printed_Sweatshirt/XL_AW23_Printed_Sweatshirt_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1699472570034",
            shopifyId: "8353449345288",
            link: "/printed-sweatshirt",
            picture01: "../content/photos/eshop/AW23/03_XL_AW23_Printed_Sweatshirt/XL_AW23_Printed_Sweatshirt_01.jpg",
            picture02: "../content/photos/eshop/AW23/03_XL_AW23_Printed_Sweatshirt/XL_AW23_Printed_Sweatshirt_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/03_XL_AW23_Printed_Sweatshirt/XL_AW23_Printed_Sweatshirt_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/03_XL_AW23_Printed_Sweatshirt/XL_AW23_Printed_Sweatshirt_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/03_XL_AW23_Printed_Sweatshirt/XL_AW23_Printed_Sweatshirt_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/03_XL_AW23_Printed_Sweatshirt/XL_AW23_Printed_Sweatshirt_04.jpg",
                    loading: "eager"
                },
            ],
        },
        SlouchyLongTee: {
            order: 8,
            itemName: "Slouchy Long tee",
            itemPrice: parse('<s>310€</s> 220€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW23/08_XL_AW23_Slouchy_Long_Tee/XL_AW23_Slouchy_Long_Tee_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1699472659251",
            shopifyId: "8353464156424",
            link: "/slouchy-long-tee",
            picture01: "../content/photos/eshop/AW23/08_XL_AW23_Slouchy_Long_Tee/XL_AW23_Slouchy_Long_Tee_01.jpg",
            picture02: "../content/photos/eshop/AW23/08_XL_AW23_Slouchy_Long_Tee/XL_AW23_Slouchy_Long_Tee_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/08_XL_AW23_Slouchy_Long_Tee/XL_AW23_Slouchy_Long_Tee_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/08_XL_AW23_Slouchy_Long_Tee/XL_AW23_Slouchy_Long_Tee_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/08_XL_AW23_Slouchy_Long_Tee/XL_AW23_Slouchy_Long_Tee_03.jpg",
                    loading: "eager"
                },

            ],
        },
        CutOutRibKnit: {
            order: 10,
            itemName: "Cut Out Rib knit",
            itemPrice: parse('<s>350€</s> 245€'),
            itemFabric: "viscose 76% PBT 14%",
            sizeChart: "../content/photos/eshop/AW23/02_XL_AW23_Slouchy_Long_Tee/XL_AW23_Slouchy_Long_Tee_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1699472795672",
            shopifyId: "8353468940552",
            link: "/cut-out-rib-knit",
            picture01: "../content/photos/eshop/AW23/10_XL_AW23_Cut_Out_Rib_Knit/XL_AW23_Cut_Out_Rib_Knit_01.jpg",
            picture02: "../content/photos/eshop/AW23/10_XL_AW23_Cut_Out_Rib_Knit/XL_AW23_Cut_Out_Rib_Knit_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/10_XL_AW23_Cut_Out_Rib_Knit/XL_AW23_Cut_Out_Rib_Knit_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/10_XL_AW23_Cut_Out_Rib_Knit/XL_AW23_Cut_Out_Rib_Knit_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/10_XL_AW23_Cut_Out_Rib_Knit/XL_AW23_Cut_Out_Rib_Knit_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/10_XL_AW23_Cut_Out_Rib_Knit/XL_AW23_Cut_Out_Rib_Knit_04.jpg",
                    loading: "eager"
                },

            ],
        },
        SignatureTank: {

            itemName: "Signature tank",
            itemPrice: parse('<s>175€</s> 125€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW23/14_XL_AW23_Signature_Tank/XL_AW23_Signature_Tank_Tee_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1699473046154",
            shopifyId: "8353451901192",
            link: "/signature-tank",
            picture01: "../content/photos/eshop/AW23/14_XL_AW23_Signature_Tank/XL_AW23_Signature_Tank_01.jpg",
            picture02: "../content/photos/eshop/AW23/14_XL_AW23_Signature_Tank/XL_AW23_Signature_Tank_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/14_XL_AW23_Signature_Tank/XL_AW23_Signature_Tank_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/14_XL_AW23_Signature_Tank/XL_AW23_Signature_Tank_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/14_XL_AW23_Signature_Tank/XL_AW23_Signature_Tank_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/14_XL_AW23_Signature_Tank/XL_AW23_Signature_Tank_04.jpg",
                    loading: "eager"
                },

            ],
        },

        PrintedHoodie: {

            itemName: "Printed hoodie",
            itemPrice: parse('<s>330€</s> 230€'),
            itemFabric: "cotton 50% terylene 50% ",
            sizeChart: "../content/photos/eshop/AW23/02_XL_AW23_Printed_Hoodie/XL_AW23_Printed_Hoodie_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702411860143",
            shopifyId: "8388898521352",
            link: "/printed-hoodie",
            picture01: "../content/photos/eshop/AW23/02_XL_AW23_Printed_Hoodie/XL_AW23_Printed_Hoodie_01.jpg",
            picture02: "../content/photos/eshop/AW23/02_XL_AW23_Printed_Hoodie/XL_AW23_Printed_Hoodie_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/02_XL_AW23_Printed_Hoodie/XL_AW23_Printed_Hoodie_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/02_XL_AW23_Printed_Hoodie/XL_AW23_Printed_Hoodie_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/02_XL_AW23_Printed_Hoodie/XL_AW23_Printed_Hoodie_03.jpg",
                    loading: "eager"
                },


            ],
        },

        PrintedTee: {

            itemName: "Printed tee",
            itemPrice: parse('<s>250€</s> 175€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW23/04_XL_AW23_Printed_Tee/XL_AW23_Printed_Tee_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702411943796",
            shopifyId: "8388895834376",
            link: "/printed-tee",
            picture01: "../content/photos/eshop/AW23/04_XL_AW23_Printed_Tee/XL_AW23_Printed_Tee_01.jpg",
            picture02: "../content/photos/eshop/AW23/04_XL_AW23_Printed_Tee/XL_AW23_Printed_Tee_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/04_XL_AW23_Printed_Tee/XL_AW23_Printed_Tee_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/04_XL_AW23_Printed_Tee/XL_AW23_Printed_Tee_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/04_XL_AW23_Printed_Tee/XL_AW23_Printed_Tee_03.jpg",
                    loading: "eager"
                },
            ],
        },

        LogoZipUpKnit: {
            order: '',
            itemName: "Logo Zip-Up knit",
            itemPrice: parse('<s>620€</s> 435€'),
            itemFabric: "alpaca 73% nylon 19% spandex 3% ",
            sizeChart: "../content/photos/eshop/AW23/08_XL_AW23_Logo_Zip-Up_Knit/XL_AW23_Logo_Zip-Up_Knit_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702412063375",
            shopifyId: "8388908843272",
            link: "/logo-zip-up-knit",
            picture01: "../content/photos/eshop/AW23/08_XL_AW23_Logo_Zip-Up_Knit/XL_AW23_Logo_Zip-Up_Knit_01.jpg",
            picture02: "../content/photos/eshop/AW23/08_XL_AW23_Logo_Zip-Up_Knit/XL_AW23_Logo_Zip-Up_Knit_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/08_XL_AW23_Logo_Zip-Up_Knit/XL_AW23_Logo_Zip-Up_Knit_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/08_XL_AW23_Logo_Zip-Up_Knit/XL_AW23_Logo_Zip-Up_Knit_02.jpg",
                    loading: "eager"
                },

            ],
        },

        BaroqueShirtWhite: {
            order: '',
            itemName: "Baroque shirt",
            itemPrice: parse('<s>420€</s> 295€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW23/16_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702412247045",
            shopifyId: "8388850417928",
            link: "/baroque-shirt-white",
            picture01: "../content/photos/eshop/AW23/16_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_01.jpg",
            picture02: "../content/photos/eshop/AW23/16_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/16_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/16_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/16_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/16_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_04.jpg",
                    loading: "eager"
                },
            ],
        },

        TrippleBeltedHighNeckVest: {
            order: '',
            itemName: "Tripple Belted High Neck vest",
            itemPrice: parse('<s>710€</s> 500€'),
            itemFabric: "body · cotton 100% PU 50% viscose 50% / filling · down 90% feather 10% ",
            sizeChart: "../content/photos/eshop/AW23/17_XL_AW23_Tripple_Belted_High_Neck_Vest/_XL_AW23_Tripple_Belted_High_Neck_Vest_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702412460123",
            shopifyId: "8388435214600",
            link: "/tripple-belted-high-neck-vest",
            picture01: "../content/photos/eshop/AW23/17_XL_AW23_Tripple_Belted_High_Neck_Vest/XL_AW23_Tripple_Belted_High_Neck_Vest_01.jpg",
            picture02: "../content/photos/eshop/AW23/17_XL_AW23_Tripple_Belted_High_Neck_Vest/XL_AW23_Tripple_Belted_High_Neck_Vest_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/17_XL_AW23_Tripple_Belted_High_Neck_Vest/XL_AW23_Tripple_Belted_High_Neck_Vest_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/17_XL_AW23_Tripple_Belted_High_Neck_Vest/XL_AW23_Tripple_Belted_High_Neck_Vest_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/17_XL_AW23_Tripple_Belted_High_Neck_Vest/XL_AW23_Tripple_Belted_High_Neck_Vest_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/17_XL_AW23_Tripple_Belted_High_Neck_Vest/XL_AW23_Tripple_Belted_High_Neck_Vest_04.jpg",
                    loading: "eager"
                },
            ],
        },


        CutOutRibKnitBlack: {
            order: '',
            itemName: "Cut Out Rib knit",
            itemPrice: parse('<s>350€</s> 245€'),
            itemFabric: "viscose 76% PBT 14%",
            sizeChart: "../content/photos/eshop/AW23/19_XL_AW23_Cut_Out_Rib_Knit/XL_AW23_Cut_Out_Rib_Knit_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702412593864",
            shopifyId: "8388907139336",
            link: "/cut-out-rib-knit-black",
            picture01: "../content/photos/eshop/AW23/19_XL_AW23_Cut_Out_Rib_Knit/XL_AW23_Cut_Out_Rib_Knit_01.jpg",
            picture02: "../content/photos/eshop/AW23/19_XL_AW23_Cut_Out_Rib_Knit/XL_AW23_Cut_Out_Rib_Knit_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/19_XL_AW23_Cut_Out_Rib_Knit/XL_AW23_Cut_Out_Rib_Knit_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/19_XL_AW23_Cut_Out_Rib_Knit/XL_AW23_Cut_Out_Rib_Knit_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/19_XL_AW23_Cut_Out_Rib_Knit/XL_AW23_Cut_Out_Rib_Knit_03.jpg",
                    loading: "eager"
                },

            ],
        },

        ChunkyRibKnitBlack: {
            order: '',
            itemName: "Chunky Rib knit",
            itemPrice: parse('<s>350€</s> 245€'),
            itemFabric: "cotton 80% wool 20%",
            sizeChart: "../content/photos/eshop/AW23/24_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702412746744",
            shopifyId: "8388900225288",
            link: "/chunky-rib-knit-black",
            picture01: "../content/photos/eshop/AW23/24_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_01.jpg",
            picture02: "../content/photos/eshop/AW23/24_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/24_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/24_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/24_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_03.jpg",
                    loading: "eager"
                },
            ],
        },

        BaroqueShirtBlack: {
            order: '',
            itemName: "Baroque Shirt",
            itemPrice: parse('<s>420€</s> 295€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW23/25_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702412877091",
            shopifyId: "8388847763720",
            link: "/baroque-shirt-black",
            picture01: "../content/photos/eshop/AW23/25_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_01.jpg",
            picture02: "../content/photos/eshop/AW23/25_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/25_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/25_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/25_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/25_XL_AW23_Baroque_Shirt/XL_AW23_Baroque_Shirt_04.jpg",
                    loading: "eager"
                },

            ],
        },

        SignatureTankBlack: {
            order: '',
            itemName: "Signature tank",
            itemPrice: parse('<s>175€</s> 125€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW23/26_XL_AW23_Signature_Tank/XL_AW23_Signature_Tank_Size_Chart.jpg",


            soldOut: false,
            onSale: true,

            shopifyDivId: "1702412958904",
            shopifyId: "8388889116936",
            link: "/signature-tank-black",
            picture01: "../content/photos/eshop/AW23/26_XL_AW23_Signature_Tank/XL_AW23_Signature_Tank_01.jpg",
            picture02: "../content/photos/eshop/AW23/26_XL_AW23_Signature_Tank/XL_AW23_Signature_Tank_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/26_XL_AW23_Signature_Tank/XL_AW23_Signature_Tank_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/26_XL_AW23_Signature_Tank/XL_AW23_Signature_Tank_02.jpg",
                    loading: "eager"
                },

            ],
        },

        ChunkyRibKnitRed: {
            order: '',
            itemName: "Chunky Rib knit",
            itemPrice: parse('<s>350€</s> 245€'),
            itemFabric: "cotton 80% wool 20%",
            sizeChart: "../content/photos/eshop/AW23/28_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702413093810",
            shopifyId: "8388904124680",
            link: "/chunky-rib-knit-red",
            picture01: "../content/photos/eshop/AW23/28_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_01.jpg",
            picture02: "../content/photos/eshop/AW23/28_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/28_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/28_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/28_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/28_XL_AW23_Chunky_Rib_Knit/XL_AW23_Chunky_Rib_Knit_04.jpg",
                    loading: "eager"
                },

            ],
        },

        CurtainShirt: {
            order: '',
            itemName: "Curtain shirt",
            itemPrice: parse('<s>490€</s> 345€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW23/31_XL_AW23_Curtain_Shirt/XL_AW23_Curtain_Shirt_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702413245928",
            shopifyId: "8388851335432",
            link: "/curtain-shirt",
            picture01: "../content/photos/eshop/AW23/31_XL_AW23_Curtain_Shirt/XL_AW23_Curtain_Shirt_01.jpg",
            picture02: "../content/photos/eshop/AW23/31_XL_AW23_Curtain_Shirt/XL_AW23_Curtain_Shirt_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/31_XL_AW23_Curtain_Shirt/XL_AW23_Curtain_Shirt_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/31_XL_AW23_Curtain_Shirt/XL_AW23_Curtain_Shirt_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/31_XL_AW23_Curtain_Shirt/XL_AW23_Curtain_Shirt_03.jpg",
                    loading: "eager"
                },

            ],
        },


    },
    pants: {
        StonewashedBeltedCombatPants: {
            order: 1,
            itemName: "Stonewashed Belted Combat pants",
            itemPrice: parse('<s>305€</s> 215€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW23/01_XL_AW23_Stonewashed_Belted_Combat_Pants/XL_AW23_Stonewashed_Belted_Combat_Pants_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1699473225991",
            shopifyId: "8353484243208",
            link: "/stonewashed-belted-combat-pants",
            picture01: "../content/photos/eshop/AW23/01_XL_AW23_Stonewashed_Belted_Combat_Pants/XL_AW23_Stonewashed_Belted_Combat_Pants_01.jpg",
            picture02: "../content/photos/eshop/AW23/01_XL_AW23_Stonewashed_Belted_Combat_Pants/XL_AW23_Stonewashed_Belted_Combat_Pants_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/01_XL_AW23_Stonewashed_Belted_Combat_Pants/XL_AW23_Stonewashed_Belted_Combat_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/01_XL_AW23_Stonewashed_Belted_Combat_Pants/XL_AW23_Stonewashed_Belted_Combat_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/01_XL_AW23_Stonewashed_Belted_Combat_Pants/XL_AW23_Stonewashed_Belted_Combat_Pants_03.jpg",
                    loading: "eager"
                },

            ],
        },
        ChainlockBikerPantsAW23: {
            order: 11,
            itemName: "Chainlock Biker pants",
            itemPrice: parse('<s>380€</s> 270€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW23/11_XL_AW23_Chainlock_Biker_Pants/XL_AW23_Chainlock_Biker_Pants_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1699473390962",
            shopifyId: "8353473331464",
            link: "/chainlock-biker-pants-aw23",
            picture01: "../content/photos/eshop/AW23/11_XL_AW23_Chainlock_Biker_Pants/XL_AW23_Chainlock_Biker_Pants_01.jpg",
            picture02: "../content/photos/eshop/AW23/11_XL_AW23_Chainlock_Biker_Pants/XL_AW23_Chainlock_Biker_Pants_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/11_XL_AW23_Chainlock_Biker_Pants/XL_AW23_Chainlock_Biker_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/11_XL_AW23_Chainlock_Biker_Pants/XL_AW23_Chainlock_Biker_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/11_XL_AW23_Chainlock_Biker_Pants/XL_AW23_Chainlock_Biker_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/11_XL_AW23_Chainlock_Biker_Pants/XL_AW23_Chainlock_Biker_Pants_04.jpg",
                    loading: "eager"
                },

            ],
        },
        PUBeltedCombatPants: {
            order: 11,
            itemName: "PU Belted Combat pants",
            itemPrice: parse('<s>495€</s> 350€'),
            itemFabric: "PU 50% T 50%",
            sizeChart: "../content/photos/eshop/AW23/15_XL_AW23_PU_Belted_Combat_Pants/XL_AW23_PU_Belted_Combat_Pants_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1699473649120",
            shopifyId: "8353479295240",
            link: "/pu-belted-combat-pants",
            picture01: "../content/photos/eshop/AW23/15_XL_AW23_PU_Belted_Combat_Pants/XL_AW23_PU_Belted_Combat_Pants_01.jpg",
            picture02: "../content/photos/eshop/AW23/15_XL_AW23_PU_Belted_Combat_Pants/XL_AW23_PU_Belted_Combat_Pants_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/15_XL_AW23_PU_Belted_Combat_Pants/XL_AW23_PU_Belted_Combat_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/15_XL_AW23_PU_Belted_Combat_Pants/XL_AW23_PU_Belted_Combat_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/15_XL_AW23_PU_Belted_Combat_Pants/XL_AW23_PU_Belted_Combat_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/15_XL_AW23_PU_Belted_Combat_Pants/XL_AW23_PU_Belted_Combat_Pants_04.jpg",
                    loading: "eager"
                },

            ],
        },

        LeatherGrippedPants: {

            itemName: "Leather Gripped pants",
            itemPrice: parse('<s>720€</s> 510€'),
            itemFabric: "cowhide 100%",
            sizeChart: "../content/photos/eshop/AW23/10_XL_AW23_Leather_Gripped_Pants/XL_AW23_Leather_Gripped_Pants_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702413689184",
            shopifyId: "8388948263176",
            link: "/leather-gripped-pants",
            picture01: "../content/photos/eshop/AW23/10_XL_AW23_Leather_Gripped_Pants/XL_AW23_Leather_Gripped_Pants_01.jpg",
            picture02: "../content/photos/eshop/AW23/10_XL_AW23_Leather_Gripped_Pants/XL_AW23_Leather_Gripped_Pants_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/10_XL_AW23_Leather_Gripped_Pants/XL_AW23_Leather_Gripped_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/10_XL_AW23_Leather_Gripped_Pants/XL_AW23_Leather_Gripped_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/10_XL_AW23_Leather_Gripped_Pants/XL_AW23_Leather_Gripped_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/10_XL_AW23_Leather_Gripped_Pants/XL_AW23_Leather_Gripped_Pants_04.jpg",
                    loading: "eager"
                },

            ],
        },

        BeltedCombatLeatherPants: {

            itemName: "Belted Combat Leather pants",
            itemPrice: parse('<s>900€</s> 630€'),
            itemFabric: "cowhide 100%",
            sizeChart: "../content/photos/eshop/AW23/18_XL_AW23_Belted_Combat_Leather_Pants/XL_AW23_Belted_Combat_Leather_Pants_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702413793101",
            shopifyId: "8388912611592",
            link: "/belted-combat-leather-pants",
            picture01: "../content/photos/eshop/AW23/18_XL_AW23_Belted_Combat_Leather_Pants/XL_AW23_Belted_Combat_Leather_Pants_01.jpg",
            picture02: "../content/photos/eshop/AW23/18_XL_AW23_Belted_Combat_Leather_Pants/XL_AW23_Belted_Combat_Leather_Pants_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/18_XL_AW23_Belted_Combat_Leather_Pants/XL_AW23_Belted_Combat_Leather_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/18_XL_AW23_Belted_Combat_Leather_Pants/XL_AW23_Belted_Combat_Leather_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/18_XL_AW23_Belted_Combat_Leather_Pants/XL_AW23_Belted_Combat_Leather_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/18_XL_AW23_Belted_Combat_Leather_Pants/XL_AW23_Belted_Combat_Leather_Pants_04.jpg",
                    loading: "eager"
                },

            ],
        },

        CurtainPants: {

            itemName: "Curtain pants",
            itemPrice: parse('<s>775€</s> 545€'),
            itemFabric: "cotton 100% cowhide 100% ",
            sizeChart: "../content/photos/eshop/AW23/22_XL_AW23_Curtain_Pants/XL_AW23_Curtain_Pants_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702413901321",
            shopifyId: "8388949147912",
            link: "/curtain-pants",
            picture01: "../content/photos/eshop/AW23/22_XL_AW23_Curtain_Pants/XL_AW23_Curtain_Pants_01.jpg",
            picture02: "../content/photos/eshop/AW23/22_XL_AW23_Curtain_Pants/XL_AW23_Curtain_Pants_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/22_XL_AW23_Curtain_Pants/XL_AW23_Curtain_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/22_XL_AW23_Curtain_Pants/XL_AW23_Curtain_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/22_XL_AW23_Curtain_Pants/XL_AW23_Curtain_Pants_03.jpg",
                    loading: "eager"
                },

            ],
        },

        WaterproofGrippedPants: {

            itemName: "Waterproof Gripped pants",
            itemPrice: parse('<s>305€</s> 215€'),
            itemFabric: "polyester 55% PU 45%",
            sizeChart: "../content/photos/eshop/AW23/29_XL_AW23_Waterproof_Gripped_Pants/XL_AW23_Waterproof_Gripped_Pants_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702414021712",
            shopifyId: "8388945903880",
            link: "/waterproof-gripped-pants",
            picture01: "../content/photos/eshop/AW23/29_XL_AW23_Waterproof_Gripped_Pants/XL_AW23_Waterproof_Gripped_Pants_01.jpg",
            picture02: "../content/photos/eshop/AW23/29_XL_AW23_Waterproof_Gripped_Pants/XL_AW23_Waterproof_Gripped_Pants_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/29_XL_AW23_Waterproof_Gripped_Pants/XL_AW23_Waterproof_Gripped_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/29_XL_AW23_Waterproof_Gripped_Pants/XL_AW23_Waterproof_Gripped_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/29_XL_AW23_Waterproof_Gripped_Pants/XL_AW23_Waterproof_Gripped_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/AW23/29_XL_AW23_Waterproof_Gripped_Pants/XL_AW23_Waterproof_Gripped_Pants_04.jpg",
                    loading: "eager"
                },


            ],
        },


    },
    accessories: {
        LeatherBraToteBagRed: {
            order: 5,
            itemName: "Leather Bra Tote Bag",
            itemPrice: parse('<s>455€</s> 320€'),
            itemFabric: "lambskin 100%",
            sizeChart: "../content/photos/eshop/AW23/05_XL_AW23_Leather_Bra_Tote_Bag/XL_AW23_Leather_Bra_Tote_Bag_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1699473742134",
            shopifyId: "8353502953736",
            link: "/leather-bra-tote-bag-red",
            picture01: "../content/photos/eshop/AW23/05_XL_AW23_Leather_Bra_Tote_Bag/XL_AW23_Leather_Bra_Tote_Bag_01.jpg",
            picture02: "../content/photos/eshop/AW23/05_XL_AW23_Leather_Bra_Tote_Bag/XL_AW23_Leather_Bra_Tote_Bag_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/05_XL_AW23_Leather_Bra_Tote_Bag/XL_AW23_Leather_Bra_Tote_Bag_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/05_XL_AW23_Leather_Bra_Tote_Bag/XL_AW23_Leather_Bra_Tote_Bag_02.jpg",
                    loading: "eager"
                },
            ],
        },
        StonewashedBraToteBag: {
            order: 9,
            itemName: parse('<s>Stonewashed Bra Tote bag</s>'),
            itemPrice: parse('<s>275€</s> 195€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/AW23/09_XL_AW23_Stonewashed_Bra_Tote_Bag/XL_AW23_Leather_Bra_Tote_Bag_Size_Chart.jpg",

            soldOut: true,
            onSale: true,

            shopifyDivId: "1699473997560",
            shopifyId: "8353486340360",
            link: "/stonewashed-bra-tote-bag",
            picture01: "../content/photos/eshop/AW23/09_XL_AW23_Stonewashed_Bra_Tote_Bag/XL_AW23_Stonewashed_Bra_Tote_Bag_01.jpg",
            picture02: "../content/photos/eshop/AW23/09_XL_AW23_Stonewashed_Bra_Tote_Bag/XL_AW23_Stonewashed_Bra_Tote_Bag_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/09_XL_AW23_Stonewashed_Bra_Tote_Bag/XL_AW23_Stonewashed_Bra_Tote_Bag_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/09_XL_AW23_Stonewashed_Bra_Tote_Bag/XL_AW23_Stonewashed_Bra_Tote_Bag_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/09_XL_AW23_Stonewashed_Bra_Tote_Bag/XL_AW23_Stonewashed_Bra_Tote_Bag_03.jpg",
                    loading: "eager"
                },
            ],
        },
        /*  DenimBraToteBag: {
             order: 12,
             itemName: "Denim Bra Tote bag",
             itemPrice: parse('€'),
             itemFabric: "%",
             sizeChart: "../content/photos/eshop/AW23/12_XL_AW23_Denim_Bra_Tote_Bag/XL_AW23_Leather_Bra_Tote_Bag_Size_Chart.jpg",
 
             shopifyDivId: "1667310605507",
             shopifyId: "8018449694984",
             link: "/denim-bra-tote-bag",
             picture01: "../content/photos/eshop/AW23/12_XL_AW23_Denim_Bra_Tote_Bag/XL_AW23_Denim_Bra_Tote_Bag_01.jpg",
             picture02: "../content/photos/eshop/AW23/12_XL_AW23_Denim_Bra_Tote_Bag/XL_AW23_Denim_Bra_Tote_Bag_02.jpg",
 
             pictures: [
                 {
                     key: 1,
                     srcJpeg: "../content/photos/eshop/AW23/12_XL_AW23_Denim_Bra_Tote_Bag/XL_AW23_Denim_Bra_Tote_Bag_01.jpg",
                     loading: "eager"
                 },
                 {
                     key: 2,
                     srcJpeg: "../content/photos/eshop/AW23/12_XL_AW23_Denim_Bra_Tote_Bag/XL_AW23_Denim_Bra_Tote_Bag_02.jpg",
                     loading: "eager"
                 },
 
             ],
         }, */
        LeatherBraToteBagBlack: {
            order: 13,
            itemName: "Leather Bra Tote Bag",
            itemPrice: parse('<s>490€</s> 345€'),
            itemFabric: "lambskin 100%",
            sizeChart: "../content/photos/eshop/AW23/13_XL_AW23_Leather_Bra_Tote_Bag/XL_AW23_Leather_Bra_Tote_Bag_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1699473843631",
            shopifyId: "8353503740168",
            link: "/leather-bra-tote-bag-black",
            picture01: "../content/photos/eshop/AW23/13_XL_AW23_Leather_Bra_Tote_Bag/XL_AW23_Leather_Bra_Tote_Bag_01.jpg",
            picture02: "../content/photos/eshop/AW23/13_XL_AW23_Leather_Bra_Tote_Bag/XL_AW23_Leather_Bra_Tote_Bag_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/13_XL_AW23_Leather_Bra_Tote_Bag/XL_AW23_Leather_Bra_Tote_Bag_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/13_XL_AW23_Leather_Bra_Tote_Bag/XL_AW23_Leather_Bra_Tote_Bag_02.jpg",
                    loading: "eager"
                },
            ],
        },
        BraToteBag: {

            itemName: "Bra Tote Bag",
            itemPrice: parse('<s>305€</s> 215€'),
            itemFabric: "wool 60% polyester 40% ",
            sizeChart: "../content/photos/eshop/AW23/06_XL_AW23_Bra_Tote_Bag/XL_AW23_Bra_Tote_Bag_Size_Chart.jpg",

            soldOut: false,
            onSale: true,

            shopifyDivId: "1702414113407",
            shopifyId: "8388951245064",
            link: "/bra-tote-bag",
            picture01: "../content/photos/eshop/AW23/06_XL_AW23_Bra_Tote_Bag/XL_AW23_Bra_Tote_Bag_01.jpg",
            picture02: "../content/photos/eshop/AW23/06_XL_AW23_Bra_Tote_Bag/XL_AW23_Bra_Tote_Bag_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/AW23/06_XL_AW23_Bra_Tote_Bag/XL_AW23_Bra_Tote_Bag_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/AW23/06_XL_AW23_Bra_Tote_Bag/XL_AW23_Bra_Tote_Bag_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/AW23/06_XL_AW23_Bra_Tote_Bag/XL_AW23_Bra_Tote_Bag_03.jpg",
                    loading: "eager"
                },
            ],
        },
    },

}