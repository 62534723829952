import parse from 'html-react-parser';

export const SS22 = {

    alt: "Ximon Lee eshop",


    jackets: {
        chainlockLeatherCoat: {
            order: 1,
            itemName: "Chainlock Leather coat",
            itemPrice: parse('<s>1150€</s> 575€'),
            itemFabric: "body · wool 100%/lining · polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/01_XL_SS22_Chainelock_Leather_Coat/01_XL_SS22_Chainelock_Leather_Coat_Chart_Size.jpg",

            shopifyDivId: "1645192373977",
            shopifyId: "7559365460190",
            link: "/chainlock-leather-coat",
            picture01: "../content/photos/eshop/SS22/all/01_XL_SS22_Chainelock_Leather_Coat/01_XL_SS22_Chainelock_Leather_Coat_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/01_XL_SS22_Chainelock_Leather_Coat/01_XL_SS22_Chainelock_Leather_Coat_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/01_XL_SS22_Chainelock_Leather_Coat/01_XL_SS22_Chainelock_Leather_Coat_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/01_XL_SS22_Chainelock_Leather_Coat/01_XL_SS22_Chainelock_Leather_Coat_4.jpg",
        },
        detachableSleeveBomberGrey: {
            itemName: parse('<s>Detachable Sleeve bomber</s>'),
            itemPrice: parse('<s>690€</s> 345€'),
            itemFabric: "body · nylon 100%/lining · polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/07_XL_SS22_Detachable_Sleeve_Bomber_G/07_XL_SS22_Detachable_Sleeve_Bomber_G_Size_Chart.jpg",


            order: 7,
            shopifyDivId: "1645192531968",
            shopifyId: "7559369883870",
            link: "/detachable-sleeve-bomber-grey",
            soldOut: "Sold Out",

            picture01: "../content/photos/eshop/SS22/all/07_XL_SS22_Detachable_Sleeve_Bomber_G/07_XL_SS22_Detachable_Sleeve_Bomber_G_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/07_XL_SS22_Detachable_Sleeve_Bomber_G/07_XL_SS22_Detachable_Sleeve_Bomber_G_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/07_XL_SS22_Detachable_Sleeve_Bomber_G/07_XL_SS22_Detachable_Sleeve_Bomber_G_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/07_XL_SS22_Detachable_Sleeve_Bomber_G/07_XL_SS22_Detachable_Sleeve_Bomber_G_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/07_XL_SS22_Detachable_Sleeve_Bomber_G/07_XL_SS22_Detachable_Sleeve_Bomber_G_6.jpg",

        },
        detachableSleeveBomberPink: {

            itemName: parse('<s>Detachable Sleeve bomber</s>'),
            itemPrice: parse('<s>690€</s> 345€'),
            itemFabric: "body · nylon 100%/lining · polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/07_XL_SS22_Detachable_Sleeve_Bomber_G/07_XL_SS22_Detachable_Sleeve_Bomber_G_Size_Chart.jpg",
            order: 24,
            shopifyDivId: "1645192583687",
            shopifyId: "7559371260126",
            link: "/detachable-sleeve-bomber-pink",
            soldOut: "Sold Out",

            picture01: "../content/photos/eshop/SS22/all/25_XL_SS22_Detachable_Sleeve_Bomber_P/25_XL_SS22_Detachable_Sleeve_Bomber_P_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/25_XL_SS22_Detachable_Sleeve_Bomber_P/25_XL_SS22_Detachable_Sleeve_Bomber_P_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/25_XL_SS22_Detachable_Sleeve_Bomber_P/25_XL_SS22_Detachable_Sleeve_Bomber_P_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/25_XL_SS22_Detachable_Sleeve_Bomber_P/25_XL_SS22_Detachable_Sleeve_Bomber_P_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/25_XL_SS22_Detachable_Sleeve_Bomber_P/25_XL_SS22_Detachable_Sleeve_Bomber_P_5.jpg",
            picture06: "../content/photos/eshop/SS22/all/25_XL_SS22_Detachable_Sleeve_Bomber_P/25_XL_SS22_Detachable_Sleeve_Bomber_P_6.jpg",
            picture07: "../content/photos/eshop/SS22/all/25_XL_SS22_Detachable_Sleeve_Bomber_P/25_XL_SS22_Detachable_Sleeve_Bomber_P_7.jpg",
            picture08: "../content/photos/eshop/SS22/all/25_XL_SS22_Detachable_Sleeve_Bomber_P/25_XL_SS22_Detachable_Sleeve_Bomber_P_8.jpg",
            picture09: "../content/photos/eshop/SS22/all/25_XL_SS22_Detachable_Sleeve_Bomber_P/25_XL_SS22_Detachable_Sleeve_Bomber_P_9.jpg",
            picture10: "../content/photos/eshop/SS22/all/25_XL_SS22_Detachable_Sleeve_Bomber_P/25_XL_SS22_Detachable_Sleeve_Bomber_P_10.jpg",
        },
        detachableSleeveBomberBlack: {
            itemName: parse('<s>Detachable Sleeve bomber</s> '),
            itemPrice: parse('<s>690€</s> 345€'),
            itemFabric: "body · nylon 100%/lining · polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/07_XL_SS22_Detachable_Sleeve_Bomber_G/07_XL_SS22_Detachable_Sleeve_Bomber_G_Size_Chart.jpg",
            soldOut: "Sold Out",


            order: 35,
            shopifyDivId: "1645192483439",
            shopifyId: "7559369457886",
            link: "/detachable-sleeve-bomber-black",
            picture01: "../content/photos/eshop/SS22/all/36_XL_SS22_Detachable_Sleeve_Bomber_B/36_XL_SS22_Detachable_Sleeve_Bomber_B_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/36_XL_SS22_Detachable_Sleeve_Bomber_B/36_XL_SS22_Detachable_Sleeve_Bomber_B_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/36_XL_SS22_Detachable_Sleeve_Bomber_B/36_XL_SS22_Detachable_Sleeve_Bomber_B_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/36_XL_SS22_Detachable_Sleeve_Bomber_B/36_XL_SS22_Detachable_Sleeve_Bomber_B_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/36_XL_SS22_Detachable_Sleeve_Bomber_B/36_XL_SS22_Detachable_Sleeve_Bomber_B_5.jpg",
        },

        panneledWoolCoat: {
            order: 11,
            itemName: "Panneled Wool coat",
            itemPrice: parse('<s>980€</s> 490€'),
            itemFabric: "body · wool 100%/lining · polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/11_XL_SS22_Panneled_Wool_Coat/11_XL_SS22_Panneled_Wool_Coat_Size_Chart.jpg",

            shopifyDivId: "1645192824142",
            shopifyId: "7559365755102",
            link: "/panneled-wool-coat",
            picture01: "../content/photos/eshop/SS22/all/11_XL_SS22_Panneled_Wool_Coat/11_XL_SS22_Panneled_Wool_Coat_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/11_XL_SS22_Panneled_Wool_Coat/11_XL_SS22_Panneled_Wool_Coat_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/11_XL_SS22_Panneled_Wool_Coat/11_XL_SS22_Panneled_Wool_Coat_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/11_XL_SS22_Panneled_Wool_Coat/11_XL_SS22_Panneled_Wool_Coat_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/11_XL_SS22_Panneled_Wool_Coat/11_XL_SS22_Panneled_Wool_Coat_5.jpg",
        },
        leatherZipShirtJacket: {
            order: 13,
            itemName: "Leather Zip Shirt jacket",
            itemPrice: parse('<s>790€</s> 395€'),
            itemFabric: "sheepskin 100%",
            sizeChart: "../content/photos/eshop/SS22/all/14_XL_SS22_Leather_Zip_Shirt_Jacket/14_XL_SS22_Leather_Zip_Shirt_Jacket_Size_Chart.jpg",

            shopifyDivId: "1645192784123",
            shopifyId: "7559394754782",
            link: "/leather-zip-shirt-jacket",
            picture01: "../content/photos/eshop/SS22/all/14_XL_SS22_Leather_Zip_Shirt_Jacket/14_XL_SS22_Leather_Zip_Shirt_Jacket_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/14_XL_SS22_Leather_Zip_Shirt_Jacket/14_XL_SS22_Leather_Zip_Shirt_Jacket_4.jpg",
            picture03: "../content/photos/eshop/SS22/all/14_XL_SS22_Leather_Zip_Shirt_Jacket/14_XL_SS22_Leather_Zip_Shirt_Jacket_5.jpg",
            picture04: "../content/photos/eshop/SS22/all/14_XL_SS22_Leather_Zip_Shirt_Jacket/14_XL_SS22_Leather_Zip_Shirt_Jacket_6.jpg",
            picture05: "../content/photos/eshop/SS22/all/14_XL_SS22_Leather_Zip_Shirt_Jacket/14_XL_SS22_Leather_Zip_Shirt_Jacket_7.jpg",
        },
        lambSkinWorkJacket: {
            itemName: "Lamb Skin Work jacket",
            itemPrice: parse('<s>875€</s> 438€'),
            itemFabric: "lamb skin 100%",
            sizeChart: "../content/photos/eshop/SS22/all/16_XL_SS22_Lamb_Skin_Work_Jacket_G/16_XL_SS22_Lamb_Skin_Work_Jacket_G_Size_Chart.jpg",

            green: {
                order: 15,
                shopifyDivId: "1645192731886",
                shopifyId: "7559372407006",
                link: "/lamb-skin-work-jacket-green",
                picture01: "../content/photos/eshop/SS22/all/16_XL_SS22_Lamb_Skin_Work_Jacket_G/16_XL_SS22_Lamb_Skin_Work_Jacket_G_1.jpg",
                picture02: "../content/photos/eshop/SS22/all/16_XL_SS22_Lamb_Skin_Work_Jacket_G/16_XL_SS22_Lamb_Skin_Work_Jacket_G_4.jpg",
                picture03: "../content/photos/eshop/SS22/all/16_XL_SS22_Lamb_Skin_Work_Jacket_G/16_XL_SS22_Lamb_Skin_Work_Jacket_G_5.jpg"
            },
            black: {
                order: 25,
                shopifyDivId: "1645192690865",
                shopifyId: "7559371849950",
                link: "/lamb-skin-work-jacket-black",
                picture01: "../content/photos/eshop/SS22/all/26_XL_SS22_Lamb_Skin_Work_Jacket_B/26_XL_SS22_Lamb_Skin_Work_Jacket_B_1.jpg",
                picture02: "../content/photos/eshop/SS22/all/26_XL_SS22_Lamb_Skin_Work_Jacket_B/26_XL_SS22_Lamb_Skin_Work_Jacket_B_2.jpg",
                picture03: "../content/photos/eshop/SS22/all/26_XL_SS22_Lamb_Skin_Work_Jacket_B/26_XL_SS22_Lamb_Skin_Work_Jacket_B_3.jpg",
                picture04: "../content/photos/eshop/SS22/all/26_XL_SS22_Lamb_Skin_Work_Jacket_B/26_XL_SS22_Lamb_Skin_Work_Jacket_B_4.jpg"
            },
        },
        chainlockDenimJacket: {
            order: 21,
            itemName: "Chainlock Denim jacket",
            itemPrice: parse('<s>725€</s> 363€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS22/all/21_XL_SS22_Chainlock_Denim_Jacket/21_XL_SS22_Chainlock_Denim_Jacket_Size_chart.jpg",

            shopifyDivId: "1645192304866",
            shopifyId: "7559380205790",
            link: "/chainlock-denim-jacket",
            picture01: "../content/photos/eshop/SS22/all/21_XL_SS22_Chainlock_Denim_Jacket/21_XL_SS22_Chainlock_Denim_Jacket_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/21_XL_SS22_Chainlock_Denim_Jacket/21_XL_SS22_Chainlock_Denim_Jacket_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/21_XL_SS22_Chainlock_Denim_Jacket/21_XL_SS22_Chainlock_Denim_Jacket_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/21_XL_SS22_Chainlock_Denim_Jacket/21_XL_SS22_Chainlock_Denim_Jacket_4.jpg"
        },

        charcoalLizardSpecimenJacket: {
            order: 23,
            itemName: "Charcoal Lizard Specimen jacket",
            itemPrice: parse('<s>890€</s> 445€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS22/all/24_XL_SS22_Charcoal_Lizard_Specimen_Jacket/24_XL_SS22_Charcoal_Lizard_Specimen_Jacket_Size_Chart.jpg",

            shopifyDivId: "1645192417498",
            shopifyId: "7559373488350",
            link: "/charcoal-lizard-specimen-jacket",
            picture01: "../content/photos/eshop/SS22/all/24_XL_SS22_Charcoal_Lizard_Specimen_Jacket/24_XL_SS22_Charcoal_Lizard_Specimen_Jacket_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/24_XL_SS22_Charcoal_Lizard_Specimen_Jacket/24_XL_SS22_Charcoal_Lizard_Specimen_Jacket_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/24_XL_SS22_Charcoal_Lizard_Specimen_Jacket/24_XL_SS22_Charcoal_Lizard_Specimen_Jacket_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/24_XL_SS22_Charcoal_Lizard_Specimen_Jacket/24_XL_SS22_Charcoal_Lizard_Specimen_Jacket_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/24_XL_SS22_Charcoal_Lizard_Specimen_Jacket/24_XL_SS22_Charcoal_Lizard_Specimen_Jacket_5.jpg",
        },
        doubleBreastedLockBlazer: {
            order: 37,
            itemName: "Double Breasted Lock blazer",
            itemPrice: parse('<s>890€</s> 445€'),
            itemFabric: "body · wool 100%/lining · polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/38_XL_SS22_Double_Breasted_Lock_Blazer/38_XL_SS22_Double_Breasted_Lock_Blazer_Size_Chart.jpg",

            shopifyDivId: "1645192638189",
            shopifyId: "7559367983326",
            link: "/double-breasted-lock-blazer",
            picture01: "../content/photos/eshop/SS22/all/38_XL_SS22_Double_Breasted_Lock_Blazer/38_XL_SS22_Double_Breasted_Lock_Blazer_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/38_XL_SS22_Double_Breasted_Lock_Blazer/38_XL_SS22_Double_Breasted_Lock_Blazer_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/38_XL_SS22_Double_Breasted_Lock_Blazer/38_XL_SS22_Double_Breasted_Lock_Blazer_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/38_XL_SS22_Double_Breasted_Lock_Blazer/38_XL_SS22_Double_Breasted_Lock_Blazer_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/38_XL_SS22_Double_Breasted_Lock_Blazer/38_XL_SS22_Double_Breasted_Lock_Blazer_5.jpg"
        },
    },

    tops: {
        mohairLooseKnitJumper: {
            itemName: "Mohair Loose Knit jumper",
            itemPrice: parse('<s>480€</s> 240€'),
            itemFabric: "kid-mohair 55% · nylon 30% · wool 15%",
            sizeChart: "../content/photos/eshop/SS22/all/02_XL_SS22_Mohair_Loose_Knit_Jumper_S/02_XL_SS22_Mohair_Loose_Knit_Jumper_S_Size_Chart.jpg",

            sand: {
                order: 2,
                shopifyDivId: "1645193869902",
                shopifyId: "7559387054302",
                link: "/mohair-loose-knit-jumper-sand",
                picture01: "../content/photos/eshop/SS22/all/02_XL_SS22_Mohair_Loose_Knit_Jumper_S/02_XL_SS22_Mohair_Loose_Knit_Jumper_S_1.jpg",
                picture02: "../content/photos/eshop/SS22/all/02_XL_SS22_Mohair_Loose_Knit_Jumper_S/02_XL_SS22_Mohair_Loose_Knit_Jumper_S_2.jpg",
                picture03: "../content/photos/eshop/SS22/all/02_XL_SS22_Mohair_Loose_Knit_Jumper_S/02_XL_SS22_Mohair_Loose_Knit_Jumper_S_3.jpg",
                picture04: "../content/photos/eshop/SS22/all/02_XL_SS22_Mohair_Loose_Knit_Jumper_S/02_XL_SS22_Mohair_Loose_Knit_Jumper_S_4.jpg",
                picture05: "../content/photos/eshop/SS22/all/02_XL_SS22_Mohair_Loose_Knit_Jumper_S/02_XL_SS22_Mohair_Loose_Knit_Jumper_S_5.jpg",
                picture06: "../content/photos/eshop/SS22/all/02_XL_SS22_Mohair_Loose_Knit_Jumper_S/02_XL_SS22_Mohair_Loose_Knit_Jumper_S_6.jpg",
                picture07: "../content/photos/eshop/SS22/all/02_XL_SS22_Mohair_Loose_Knit_Jumper_S/02_XL_SS22_Mohair_Loose_Knit_Jumper_S_7.jpg",
            },
            orange: {
                order: 26,
                shopifyDivId: "1645193832674",
                shopifyId: "7559387644126",
                link: "/mohair-loose-knit-jumper-orange",
                picture01: "../content/photos/eshop/SS22/all/27_XL_SS22_Mohair_Loose_Knit_Jumper_O/27_XL_SS22_Mohair_Loose_Knit_Jumper_O_1.jpg",
                picture02: "../content/photos/eshop/SS22/all/27_XL_SS22_Mohair_Loose_Knit_Jumper_O/27_XL_SS22_Mohair_Loose_Knit_Jumper_O_2.jpg",
                picture03: "../content/photos/eshop/SS22/all/27_XL_SS22_Mohair_Loose_Knit_Jumper_O/27_XL_SS22_Mohair_Loose_Knit_Jumper_O_3.jpg",
                picture04: "../content/photos/eshop/SS22/all/27_XL_SS22_Mohair_Loose_Knit_Jumper_O/27_XL_SS22_Mohair_Loose_Knit_Jumper_O_4.jpg",
                picture05: "../content/photos/eshop/SS22/all/27_XL_SS22_Mohair_Loose_Knit_Jumper_O/27_XL_SS22_Mohair_Loose_Knit_Jumper_O_5.jpg",
            },
        },
        cableKnitTank: {
            itemName: "Cable Knit tank",
            itemPrice: parse('<s>335€</s> 168€'),
            itemFabric: "cotton 60% · nylon 30% · wool 10%",
            sizeChart: "../content/photos/eshop/SS22/all/03_XL_SS22_Mohair_Cable_Knit_Tank_B/03_XL_SS22_Mohair_Cable_Knit_Tank_B_Size_Chart.jpg",

            black: {
                order: 3,
                shopifyDivId: "1645194920505",
                shopifyId: "7559388397790",
                link: "/cable-knit-tank-black",
                picture01: "../content/photos/eshop/SS22/all/03_XL_SS22_Mohair_Cable_Knit_Tank_B/03_XL_SS22_Mohair_Cable_Knit_Tank_B_1.jpg",
                picture02: "../content/photos/eshop/SS22/all/03_XL_SS22_Mohair_Cable_Knit_Tank_B/03_XL_SS22_Mohair_Cable_Knit_Tank_B_2.jpg",
                picture03: "../content/photos/eshop/SS22/all/03_XL_SS22_Mohair_Cable_Knit_Tank_B/03_XL_SS22_Mohair_Cable_Knit_Tank_B_3.jpg",
                picture04: "../content/photos/eshop/SS22/all/03_XL_SS22_Mohair_Cable_Knit_Tank_B/03_XL_SS22_Mohair_Cable_Knit_Tank_B_4.jpg",
                picture05: "../content/photos/eshop/SS22/all/03_XL_SS22_Mohair_Cable_Knit_Tank_B/03_XL_SS22_Mohair_Cable_Knit_Tank_B_5.jpg",
            },
            cream: {
                order: 28,
                shopifyDivId: "1645192194481",
                shopifyId: "7559388659934",
                link: "/cable-knit-tank-cream",
                picture01: "../content/photos/eshop/SS22/all/29_XL_SS22_Cable_Knit_Tank_C/29_XL_SS22_Cable_Knit_Tank_C_1.jpg",
                picture02: "../content/photos/eshop/SS22/all/29_XL_SS22_Cable_Knit_Tank_C/29_XL_SS22_Cable_Knit_Tank_C_2.jpg",
                picture03: "../content/photos/eshop/SS22/all/29_XL_SS22_Cable_Knit_Tank_C/29_XL_SS22_Cable_Knit_Tank_C_3.jpg",
                picture04: "../content/photos/eshop/SS22/all/29_XL_SS22_Cable_Knit_Tank_C/29_XL_SS22_Cable_Knit_Tank_C_4.jpg",
                picture05: "../content/photos/eshop/SS22/all/29_XL_SS22_Cable_Knit_Tank_C/29_XL_SS22_Cable_Knit_Tank_C_5.jpg",
            },
        },
        motorbikeCompressionTop: {
            itemName: "Motorbike Compression top",
            itemPrice: parse('<s>395€</s> 198€'),
            itemFabric: "polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/04_XL_SS22_Motobike_Compression_Top/04_XL_SS22_Motobike_Compression_Top_Size_Chart.jpg",

            order: 4,
            shopifyDivId: "1645193919264",
            shopifyId: "7559407567070",
            link: "/motorbike-compression-top",
            picture01: "../content/photos/eshop/SS22/all/04_XL_SS22_Motobike_Compression_Top/04_XL_SS22_Motobike_Compression_Top_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/04_XL_SS22_Motobike_Compression_Top/04_XL_SS22_Motobike_Compression_Top_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/04_XL_SS22_Motobike_Compression_Top/04_XL_SS22_Motobike_Compression_Top_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/04_XL_SS22_Motobike_Compression_Top/04_XL_SS22_Motobike_Compression_Top_4.jpg"
        },
        twopieceLeatherVestOrange: {
            itemName: parse('<s>Two-piece Leather vest</s>'),
            itemPrice: parse('<s>515€</s> 258€'),
            itemFabric: "sheepskin 100%",
            sizeChart: "../content/photos/eshop/SS22/all/05_XL_SS22_Two-piece_Leather_Vest_O/05_XL_SS22_Two-piece_Leather_Vest_O_Size_Chart.jpg",


            order: 5,
            shopifyDivId: "1645195303558",
            shopifyId: "7559412908254",
            link: "/twopiece-leather-vest-orange",
            picture01: "../content/photos/eshop/SS22/all/05_XL_SS22_Two-piece_Leather_Vest_O/05_XL_SS22_Two-piece_Leather_Vest_O_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/05_XL_SS22_Two-piece_Leather_Vest_O/05_XL_SS22_Two-piece_Leather_Vest_O_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/05_XL_SS22_Two-piece_Leather_Vest_O/05_XL_SS22_Two-piece_Leather_Vest_O_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/05_XL_SS22_Two-piece_Leather_Vest_O/05_XL_SS22_Two-piece_Leather_Vest_O_4.jpg",
            soldOut: "Sold Out",

        },
        twopieceLeatherVestBlack: {
            itemName: parse('<s>Two-piece Leather vest</s>'),
            itemPrice: parse('<s>515€</s> 258€'),
            itemFabric: "sheepskin 100%",
            sizeChart: "../content/photos/eshop/SS22/all/05_XL_SS22_Two-piece_Leather_Vest_O/05_XL_SS22_Two-piece_Leather_Vest_O_Size_Chart.jpg",

            soldOut: true,

            order: 14,
            shopifyDivId: "1645194225421",
            shopifyId: "7559411957982",
            link: "/twopiece-leather-vest-black",
            picture01: "../content/photos/eshop/SS22/all/15_XL_SS22_Two-Piece_Leather-Vest_B/15_XL_SS22_Two-Piece_Leather-Vest_B_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/15_XL_SS22_Two-Piece_Leather-Vest_B/15_XL_SS22_Two-Piece_Leather-Vest_B_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/15_XL_SS22_Two-Piece_Leather-Vest_B/15_XL_SS22_Two-Piece_Leather-Vest_B_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/15_XL_SS22_Two-Piece_Leather-Vest_B/15_XL_SS22_Two-Piece_Leather-Vest_B_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/15_XL_SS22_Two-Piece_Leather-Vest_B/15_XL_SS22_Two-Piece_Leather-Vest_B_5.jpg",
            picture06: "../content/photos/eshop/SS22/all/15_XL_SS22_Two-Piece_Leather-Vest_B/15_XL_SS22_Two-Piece_Leather-Vest_B_6.jpg",
            picture07: "../content/photos/eshop/SS22/all/15_XL_SS22_Two-Piece_Leather-Vest_B/15_XL_SS22_Two-Piece_Leather-Vest_B_7.jpg",
        },

        sharkSpecimenResortShirt: {
            order: 8,
            itemName: "Shark Specimen Resort shirt",
            itemPrice: parse('<s>530€</s> 265€'),
            itemFabric: "polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/08_XL_SS22_Shark_Specimen_Resort_Shirt/08_XL_SS22_Shark_Specimen_Resort_Shirt_Size_Chart.jpg",

            shopifyDivId: "1645194021366",
            shopifyId: "7559392002270",
            link: "/shark-specimen-resort-shirt",
            picture01: "../content/photos/eshop/SS22/all/08_XL_SS22_Shark_Specimen_Resort_Shirt/08_XL_SS22_Shark_Specimen_Resort_Shirt_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/08_XL_SS22_Shark_Specimen_Resort_Shirt/08_XL_SS22_Shark_Specimen_Resort_Shirt_1.jpg",
            picture03: "../content/photos/eshop/SS22/all/08_XL_SS22_Shark_Specimen_Resort_Shirt/08_XL_SS22_Shark_Specimen_Resort_Shirt_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/08_XL_SS22_Shark_Specimen_Resort_Shirt/08_XL_SS22_Shark_Specimen_Resort_Shirt_4.jpg"
        },
        wetSpecimenTee: {
            order: 9,
            itemName: "Wet Specimen tee",
            itemPrice: parse('<s>195€</s> 98€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS22/all/09_XL_SS22_Wet_Specimen_Tee/09_XL_SS22_Wet_Specimen_Tee_Size_Chart.jpg",

            shopifyDivId: "1645194303174",
            shopifyId: "7559396163806",
            link: "/wet-specimen-tee",
            picture01: "../content/photos/eshop/SS22/all/09_XL_SS22_Wet_Specimen_Tee/09_XL_SS22_Wet_Specimen_Tee_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/09_XL_SS22_Wet_Specimen_Tee/09_XL_SS22_Wet_Specimen_Tee_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/09_XL_SS22_Wet_Specimen_Tee/09_XL_SS22_Wet_Specimen_Tee_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/09_XL_SS22_Wet_Specimen_Tee/09_XL_SS22_Wet_Specimen_Tee_4.jpg"
        },
        asymetricalBleachedTank: {
            order: 17,
            itemName: "Asymetrical Bleached tank",
            itemPrice: parse('<s>210€</s> 105€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS22/all/18_XL_SS22_Asymetrical_Bleached_Tank/18_XL_SS22_Asymetrical_Bleached_Tank_Size_Chart.jpg",

            shopifyDivId: "1645191917362",
            shopifyId: "7559402750174",
            link: "/asymetrical-bleached-tank",
            picture01: "../content/photos/eshop/SS22/all/18_XL_SS22_Asymetrical_Bleached_Tank/18_XL_SS22_Asymetrical_Bleached_Tank_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/18_XL_SS22_Asymetrical_Bleached_Tank/18_XL_SS22_Asymetrical_Bleached_Tank_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/18_XL_SS22_Asymetrical_Bleached_Tank/18_XL_SS22_Asymetrical_Bleached_Tank_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/18_XL_SS22_Asymetrical_Bleached_Tank/18_XL_SS22_Asymetrical_Bleached_Tank_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/18_XL_SS22_Asymetrical_Bleached_Tank/18_XL_SS22_Asymetrical_Bleached_Tank_5.jpg",
        },
        ironGateCompressionTop: {
            order: 18,
            itemName: "Iron Gate Compression top",
            itemPrice: parse('<s>395€</s> 198€'),
            itemFabric: "polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/19_XL_SS22_Iron_Gate_Compression_Top/19_XL_SS22_Iron_Gate_Compression_Size_Chart.jpg",

            shopifyDivId: "1645193618335",
            shopifyId: "7559403667678",
            link: "/iron-gate-compression-top",
            picture01: "../content/photos/eshop/SS22/all/19_XL_SS22_Iron_Gate_Compression_Top/19_XL_SS22_Iron_Gate_Compression_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/19_XL_SS22_Iron_Gate_Compression_Top/19_XL_SS22_Iron_Gate_Compression_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/19_XL_SS22_Iron_Gate_Compression_Top/19_XL_SS22_Iron_Gate_Compression_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/19_XL_SS22_Iron_Gate_Compression_Top/19_XL_SS22_Iron_Gate_Compression_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/19_XL_SS22_Iron_Gate_Compression_Top/19_XL_SS22_Iron_Gate_Compression_5.jpg",
        },
        chainlockLeatherVest: {
            order: 19,
            itemName: parse('<s>Chainlock Leather vest</s>'),
            itemPrice: parse('<s>545€</s> 273€'),
            itemFabric: "sheepskin 100%",
            sizeChart: "../content/photos/eshop/SS22/all/20_XL_SS22_Chainlock_Leather_Vest/20_XL_SS22_Chainlock_Leather_Vest_Size_Chart.jpg",
            soldOut: "Sold Out",

            shopifyDivId: "1645193501168",
            shopifyId: "7559414251742",
            link: "/chainlock-leather-vest",
            picture01: "../content/photos/eshop/SS22/all/20_XL_SS22_Chainlock_Leather_Vest/20_XL_SS22_Chainlock_Leather_Vest_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/20_XL_SS22_Chainlock_Leather_Vest/20_XL_SS22_Chainlock_Leather_Vest_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/20_XL_SS22_Chainlock_Leather_Vest/20_XL_SS22_Chainlock_Leather_Vest_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/20_XL_SS22_Chainlock_Leather_Vest/20_XL_SS22_Chainlock_Leather_Vest_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/20_XL_SS22_Chainlock_Leather_Vest/20_XL_SS22_Chainlock_Leather_Vest_5.jpg",
            picture06: "../content/photos/eshop/SS22/all/20_XL_SS22_Chainlock_Leather_Vest/20_XL_SS22_Chainlock_Leather_Vest_6.jpg"
        },
        skyscrapersCompressionTop: {
            order: 21,
            itemName: "Skyscrapers Compression top",
            itemPrice: parse('<s>395€</s> 198€'),
            itemFabric: "polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/22_XL_SS22_Skycrapers_Compression_Top/22_XL_SS22_Skycrapers_Compression_Top_Size_Chart.jpg",

            shopifyDivId: "1645194149790",
            shopifyId: "7559406059742",
            link: "/skyscrapers-compression-top",
            picture01: "../content/photos/eshop/SS22/all/22_XL_SS22_Skycrapers_Compression_Top/22_XL_SS22_Skycrapers_Compression_Top_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/22_XL_SS22_Skycrapers_Compression_Top/22_XL_SS22_Skycrapers_Compression_Top_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/22_XL_SS22_Skycrapers_Compression_Top/22_XL_SS22_Skycrapers_Compression_Top_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/22_XL_SS22_Skycrapers_Compression_Top/22_XL_SS22_Skycrapers_Compression_Top_4.jpg"
        },
        sharkSpecimenShirt: {
            order: 22,
            itemName: "Shark Specimen shirt",
            itemPrice: parse('<s>750€</s> 375€'),
            itemFabric: "polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/23_XL_SS22_Shark_Specimen_Shirt/23_XL_SS22_Shark_Specimen_Shirt_Size_Chart.jpg",

            shopifyDivId: "1645194088242",
            shopifyId: "7559391281374",
            link: "/shark-specimen-shirt",
            picture01: "../content/photos/eshop/SS22/all/23_XL_SS22_Shark_Specimen_Shirt/23_XL_SS22_Shark_Specimen_Shirt_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/23_XL_SS22_Shark_Specimen_Shirt/23_XL_SS22_Shark_Specimen_Shirt_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/23_XL_SS22_Shark_Specimen_Shirt/23_XL_SS22_Shark_Specimen_Shirt_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/23_XL_SS22_Shark_Specimen_Shirt/23_XL_SS22_Shark_Specimen_Shirt_4.jpg",
        },
        lizardSpecimenCompressionTop: {
            order: 27,
            itemName: "Lizard Specimen Compression top",
            itemPrice: parse('<s>395€</s> 198€'),
            itemFabric: "polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/28_XL_SS22_Lizard_Specimen_Compression_Top/28_XL_SS22_Lizard_Specimen_Compression_Top_Size_Chart.jpg",

            shopifyDivId: "1645193659507",
            shopifyId: "7559408648414",
            link: "/lizard-specimen-compression-top",
            picture01: "../content/photos/eshop/SS22/all/28_XL_SS22_Lizard_Specimen_Compression_Top/28_XL_SS22_Lizard_Specimen_Compression_Top_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/28_XL_SS22_Lizard_Specimen_Compression_Top/28_XL_SS22_Lizard_Specimen_Compression_Top_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/28_XL_SS22_Lizard_Specimen_Compression_Top/28_XL_SS22_Lizard_Specimen_Compression_Top_4.jpg",
            picture04: "../content/photos/eshop/SS22/all/28_XL_SS22_Lizard_Specimen_Compression_Top/28_XL_SS22_Lizard_Specimen_Compression_Top_5.jpg"
        },
        charcoalLizardSpecimenOvershirt: {
            order: 29,
            itemName: "Charcoal Lizard Specimen overshirt",
            itemPrice: parse('<s>775€</s> 388€'),
            itemFabric: "polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/30_XL_SS22_Charcoal_Lizard_Specimen_Overshirt/30_XL_SS22_Charcoal_Lizard_Specimen_Overshirt_Size_Chart.jpg",

            shopifyDivId: "1645193577673",
            shopifyId: "7559389806814",
            link: "/charcoal-lizard-specimen-overshirt",
            picture01: "../content/photos/eshop/SS22/all/30_XL_SS22_Charcoal_Lizard_Specimen_Overshirt/30_XL_SS22_Charcoal_Lizard_Specimen_Overshirt_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/30_XL_SS22_Charcoal_Lizard_Specimen_Overshirt/30_XL_SS22_Charcoal_Lizard_Specimen_Overshirt_3.jpg",
            picture03: "../content/photos/eshop/SS22/all/30_XL_SS22_Charcoal_Lizard_Specimen_Overshirt/30_XL_SS22_Charcoal_Lizard_Specimen_Overshirt_4.jpg"
        },
        chainlockTee: {
            order: 31,
            itemName: "Chainlock tee",
            itemPrice: parse('<s>250€</s> 125€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS22/all/32_XL_SS22_Chainlock_Tee/32_XL_SS22_Chainlock_Tee_Size_Chart.jpg",

            shopifyDivId: "1645193543328",
            shopifyId: "7559399112926",
            link: "/chainlock-tee",
            picture01: "../content/photos/eshop/SS22/all/32_XL_SS22_Chainlock_Tee/32_XL_SS22_Chainlock_Tee_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/32_XL_SS22_Chainlock_Tee/32_XL_SS22_Chainlock_Tee_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/32_XL_SS22_Chainlock_Tee/32_XL_SS22_Chainlock_Tee_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/32_XL_SS22_Chainlock_Tee/32_XL_SS22_Chainlock_Tee_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/32_XL_SS22_Chainlock_Tee/32_XL_SS22_Chainlock_Tee_5.jpg",
        },
        lizardSpecimenTee: {
            order: 33,
            itemName: "Lizard Specimen tee",
            itemPrice: parse('<s>195€</s> 98€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS22/all/34_XL_SS22_Lizard_Specimen_Tee/34_XL_SS22_Lizard_Specimen_Tee_Size_Chart.jpg",

            shopifyDivId: "1645193700585",
            shopifyId: "7559397179614",
            link: "/lizard-specimen-tee",
            picture01: "../content/photos/eshop/SS22/all/34_XL_SS22_Lizard_Specimen_Tee/34_XL_SS22_Lizard_Specimen_Tee_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/34_XL_SS22_Lizard_Specimen_Tee/34_XL_SS22_Lizard_Specimen_Tee_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/34_XL_SS22_Lizard_Specimen_Tee/34_XL_SS22_Lizard_Specimen_Tee_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/34_XL_SS22_Lizard_Specimen_Tee/34_XL_SS22_Lizard_Specimen_Tee_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/34_XL_SS22_Lizard_Specimen_Tee/34_XL_SS22_Lizard_Specimen_Tee_5.jpg",
        },
        skyscrapersTee: {
            order: 38,
            itemName: "Skyscrapers tee",
            itemPrice: parse('<s>195€</s> 98€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS22/all/39_XL_SS22_Skycrapers_Tee/39_XL_SS22_Skycrapers_Tee_Size_Chart.jpg",

            shopifyDivId: "1645194178901",
            shopifyId: "7559395475678",
            link: "/skyscrapers-tee",
            picture01: "../content/photos/eshop/SS22/all/39_XL_SS22_Skycrapers_Tee/39_XL_SS22_Skycrapers_Tee_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/39_XL_SS22_Skycrapers_Tee/39_XL_SS22_Skycrapers_Tee_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/39_XL_SS22_Skycrapers_Tee/39_XL_SS22_Skycrapers_Tee_3.jpg"
        },



    },

    pants: {
        chainlockNylonPants: {
            order: 6,
            itemName: "Chainlock Nylon pants",
            itemPrice: parse('<s>565€</s> 283€'),
            itemFabric: "nylon 100%",
            sizeChart: "../content/photos/eshop/SS22/all/06_XL_SS22_Chainlock_Nylon_Pants/06_XL_SS22_Chainlock_Nylon_Pants_Size_Chart.jpg",

            shopifyDivId: "1645193144144",
            shopifyId: "7559365066974",
            link: "/chainlock-nylon-pants",
            picture01: "../content/photos/eshop/SS22/all/06_XL_SS22_Chainlock_Nylon_Pants/06_XL_SS22_Chainlock_Nylon_Pants_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/06_XL_SS22_Chainlock_Nylon_Pants/06_XL_SS22_Chainlock_Nylon_Pants_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/06_XL_SS22_Chainlock_Nylon_Pants/06_XL_SS22_Chainlock_Nylon_Pants_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/06_XL_SS22_Chainlock_Nylon_Pants/06_XL_SS22_Chainlock_Nylon_Pants_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/06_XL_SS22_Chainlock_Nylon_Pants/06_XL_SS22_Chainlock_Nylon_Pants_5.jpg",
            picture06: "../content/photos/eshop/SS22/all/06_XL_SS22_Chainlock_Nylon_Pants/06_XL_SS22_Chainlock_Nylon_Pants_6.jpg"
        },
        sharkSpecimenBoxerShorts: {
            order: 10,
            itemName: "Shark Specimen Boxer shorts",
            itemPrice: parse('<s>295€</s> 148€'),
            itemFabric: "polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/10_XL_SS22_Shark_Specimen_Boxer_Short/10_XL_SS22_Shark_Specimen_Boxer_Short_Size_Chart.jpg",

            shopifyDivId: "1645193144144",
            shopifyId: "7559365066974",
            link: "/shark-specimen-boxer-shorts",
            picture01: "../content/photos/eshop/SS22/all/10_XL_SS22_Shark_Specimen_Boxer_Short/10_XL_SS22_Shark_Specimen_Boxer_Short_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/10_XL_SS22_Shark_Specimen_Boxer_Short/10_XL_SS22_Shark_Specimen_Boxer_Short_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/10_XL_SS22_Shark_Specimen_Boxer_Short/10_XL_SS22_Shark_Specimen_Boxer_Short_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/10_XL_SS22_Shark_Specimen_Boxer_Short/10_XL_SS22_Shark_Specimen_Boxer_Short_4.jpg"
        },
        panneledLeatherBikerPants: {
            order: 12,
            itemName: "Panneled Leather Biker pants",
            itemPrice: parse('<s>670€</s> 335€'),
            itemFabric: "lamb skin 100%",
            sizeChart: "../content/photos/eshop/SS22/all/13_XL_SS22_Panneled_Leather_Biker_Pants/13_XL_SS22_Panneled_Leather_Biker_Pants_Size_Chart.jpg",

            shopifyDivId: "1645193388896",
            shopifyId: "7559364739294",
            link: "/panneled-leather-biker-pants",
            picture01: "../content/photos/eshop/SS22/all/13_XL_SS22_Panneled_Leather_Biker_Pants/13_XL_SS22_Panneled_Leather_Biker_Pants_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/13_XL_SS22_Panneled_Leather_Biker_Pants/13_XL_SS22_Panneled_Leather_Biker_Pants_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/13_XL_SS22_Panneled_Leather_Biker_Pants/13_XL_SS22_Panneled_Leather_Biker_Pants_4.jpg",
            picture04: "../content/photos/eshop/SS22/all/13_XL_SS22_Panneled_Leather_Biker_Pants/13_XL_SS22_Panneled_Leather_Biker_Pants_5.jpg"
        },
        charcoalLizardSpecimenPants: {
            order: 16,
            itemName: "Charcoal Lizard Specimen pants",
            itemPrice: parse('<s>470€</s> 235€'),
            itemFabric: "polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/17_XL_SS22_Charcoal_Lizard_Speciment_pants/17_XL_SS22_Charcoal_Lizard_Speciment_pants_Size_Chart.jpg",

            shopifyDivId: "1645193229382",
            shopifyId: "7559335706846",
            link: "/charcoal-lizard-specimen-pants",
            picture01: "../content/photos/eshop/SS22/all/17_XL_SS22_Charcoal_Lizard_Speciment_pants/17_XL_SS22_Charcoal_Lizard_Speciment_pants_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/17_XL_SS22_Charcoal_Lizard_Speciment_pants/17_XL_SS22_Charcoal_Lizard_Speciment_pants_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/17_XL_SS22_Charcoal_Lizard_Speciment_pants/17_XL_SS22_Charcoal_Lizard_Speciment_pants_1.jpg",
            picture04: "../content/photos/eshop/SS22/all/17_XL_SS22_Charcoal_Lizard_Speciment_pants/17_XL_SS22_Charcoal_Lizard_Speciment_pants_2.jpg",
            picture05: "../content/photos/eshop/SS22/all/17_XL_SS22_Charcoal_Lizard_Speciment_pants/17_XL_SS22_Charcoal_Lizard_Speciment_pants_1.jpg",
        },
        chainlockJeans: {
            order: 30,
            itemName: parse('<s>Chainlock jeans</s>'),
            itemPrice: parse('<s>490€</s> 245€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS22/all/31_XL_SS22_Chainlock_Jeans/31_XL_SS22_Chainlock_Jeans_Size_Chart.jpg",

            soldOut: true,


            shopifyDivId: "1645192863655",
            shopifyId: "7559351927006",
            link: "/chainlock-jeans",
            picture01: "../content/photos/eshop/SS22/all/31_XL_SS22_Chainlock_Jeans/31_XL_SS22_Chainlock_Jeans_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/31_XL_SS22_Chainlock_Jeans/31_XL_SS22_Chainlock_Jeans_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/31_XL_SS22_Chainlock_Jeans/31_XL_SS22_Chainlock_Jeans_1.jpg",
            picture04: "../content/photos/eshop/SS22/all/31_XL_SS22_Chainlock_Jeans/31_XL_SS22_Chainlock_Jeans_2.jpg",
            picture05: "../content/photos/eshop/SS22/all/31_XL_SS22_Chainlock_Jeans/31_XL_SS22_Chainlock_Jeans_1.jpg",
        },
        eyeletDenimShorts: {
            order: 32,
            itemName: "Eyelet Denim short",
            itemPrice: parse('<s>430€</s> 215€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS22/all/33_XL_SS22_Eyelet_Denim_Shorts/33_XL_SS22_Eyelet_Denim_Shorts_Size_Chart.jpg",

            shopifyDivId: "1645193351842",
            shopifyId: "7559359267038",
            link: "/eyelet-denim-shorts",
            picture01: "../content/photos/eshop/SS22/all/33_XL_SS22_Eyelet_Denim_Shorts/33_XL_SS22_Eyelet_Denim_Shorts_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/33_XL_SS22_Eyelet_Denim_Shorts/33_XL_SS22_Eyelet_Denim_Shorts_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/33_XL_SS22_Eyelet_Denim_Shorts/33_XL_SS22_Eyelet_Denim_Shorts_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/33_XL_SS22_Eyelet_Denim_Shorts/33_XL_SS22_Eyelet_Denim_Shorts_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/33_XL_SS22_Eyelet_Denim_Shorts/33_XL_SS22_Eyelet_Denim_Shorts_5.jpg",
            picture06: "../content/photos/eshop/SS22/all/33_XL_SS22_Eyelet_Denim_Shorts/33_XL_SS22_Eyelet_Denim_Shorts_6.jpg"
        },
        chainlockLeatherShorts: {
            order: 34,
            itemName: "Chainlock Leather shorts",
            itemPrice: parse('<s>550€</s> 275€'),
            itemFabric: "cotton 100%",
            sizeChart: "../content/photos/eshop/SS22/all/35_XL_SS22_Chainlock_Leather_Shorts/35_XL_SS22_Chainlock_Leather_Shorts_Size_Chart.jpg",

            shopifyDivId: "1645192919736",
            shopifyId: "7559331414238",
            link: "/chainlock-leather-shorts",
            picture01: "../content/photos/eshop/SS22/all/35_XL_SS22_Chainlock_Leather_Shorts/35_XL_SS22_Chainlock_Leather_Shorts_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/35_XL_SS22_Chainlock_Leather_Shorts/35_XL_SS22_Chainlock_Leather_Shorts_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/35_XL_SS22_Chainlock_Leather_Shorts/35_XL_SS22_Chainlock_Leather_Shorts_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/35_XL_SS22_Chainlock_Leather_Shorts/35_XL_SS22_Chainlock_Leather_Shorts_4.jpg",
            picture05: "../content/photos/eshop/SS22/all/35_XL_SS22_Chainlock_Leather_Shorts/35_XL_SS22_Chainlock_Leather_Shorts_5.jpg",
        },
        charcoalWetSpecimenShorts: {
            order: 36,
            itemName: "Charcoal Wet Specimen shorts",
            itemPrice: parse('<s>550€</s> 275€'),
            itemFabric: "polyester 100%",
            sizeChart: "../content/photos/eshop/SS22/all/37_XL_SS22_Charcoal_Wet_Specimen_Shorts/37_XL_SS22_Charcoal_Wet_Specimen_Shorts_Size_Chart.jpg",

            shopifyDivId: "1645193275837",
            shopifyId: "7559343145182",
            link: "/charcoal-wet-specimen-shorts",
            picture01: "../content/photos/eshop/SS22/all/37_XL_SS22_Charcoal_Wet_Specimen_Shorts/37_XL_SS22_Charcoal_Wet_Specimen_Shorts_1.jpg",
            picture02: "../content/photos/eshop/SS22/all/37_XL_SS22_Charcoal_Wet_Specimen_Shorts/37_XL_SS22_Charcoal_Wet_Specimen_Shorts_2.jpg",
            picture03: "../content/photos/eshop/SS22/all/37_XL_SS22_Charcoal_Wet_Specimen_Shorts/37_XL_SS22_Charcoal_Wet_Specimen_Shorts_3.jpg",
            picture04: "../content/photos/eshop/SS22/all/37_XL_SS22_Charcoal_Wet_Specimen_Shorts/37_XL_SS22_Charcoal_Wet_Specimen_Shorts_4.jpg"
        },


    },

}