import React from "react";

import Cube from "../../components/cube";

import { SS23 } from "../../js/database/database-ss23.js";
import { AW23 } from "../../js/database/database-aw23-eshop.js";
import { SS24 } from "../../js/database/database-ss24-eshop.js";

import EshopContainer from "../../components/containers/EshopContainer";

const Tops = (props) => {
  const topsSS24 = Object.keys(SS24.tops).map((key) => {
    const top = SS24.tops[key];
    return (
      <Cube
        key={key}
        itemName={top.itemName}
        itemLink={top.link}
        itemPrice={top.itemPrice}
        picture01={top.picture01}
        picture02={top.picture02}
        soldOut={top.soldOut}
      />
    );
  });

  const topsAW23 = Object.keys(AW23.tops).map((key) => {
    const top = AW23.tops[key];
    return (
      <Cube
        key={key}
        itemName={top.itemName}
        itemLink={top.link}
        itemPrice={top.itemPrice}
        picture01={top.picture01}
        picture02={top.picture02}
        soldOut={top.soldOut}
      />
    );
  });

  const topsSS23 = Object.keys(SS23.tops).map((key) => {
    const top = SS23.tops[key];
    return (
      <Cube
        key={key}
        itemName={top.itemName}
        itemLink={top.link}
        itemPrice={top.itemPrice}
        picture01={top.picture01}
        picture02={top.picture02}
        soldOut={top.soldOut}
      />
    );
  });

  return (
    <EshopContainer pageTitle={"Tops"} shopClassName={"circle"}>
      {topsSS24}
      {topsAW23}
      {topsSS23}
    </EshopContainer>
  );
};

export default Tops;
