import React from "react";
import DiscountMessage from "../DiscountMessage";
import GlobalMetaTags from "../globalMetaTags";

import LoaderFake from "../LoaderFake";
import Loader from "../loader";

import TopMenuEshop from "../TopMenuEshop";
import BottomMenu from "../MenuBottom";


import setBodyColor from "../../js/setBodyColor";

const EshopContainer = (props) => {
    setBodyColor({ color: "#ffffff" });

    return <div>

        <main>
            <GlobalMetaTags pageTitle={props.pageTitle} />
            <LoaderFake />

            {/*     <Loader /> */}

            <div id="up"></div>

            <TopMenuEshop />

            <BottomMenu shopClassName={props.shopClassName} />
            <a className="button-arrow-up circle" href="#up">↑</a>

            <div id="clothing">
                {props.children}
            </div>

            {/*  <DiscountMessage /> */}

        </main>
    </div>

}

export default EshopContainer;