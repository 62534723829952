import parse from 'html-react-parser';

export const SS24 = {

    alt: "Ximon Lee SS24 collection",
    imgWidth: 834,
    imgHeight: 1250,
    loading: "eager",

    jackets: {
        CroppedChainlockBikerJacket: {
            order: 4,
            itemName: parse('Cropped Chainlock Biker jacket'),
            itemPrice: parse('1140€'),
            itemFabric: "cowhide 100%",
            itemDetails: parse(`Cropped fit. Chain detail on left sleeve. Zip-closure with a zigzag zipper guard and snap closing. Vertical welt pockets inside.<br><br>
            Mica is 172cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/04_XL_SS24_Cropped_Chainlock_Biker_Jacket/XL_SS24_Cropped_Chainlock_Biker_Jacket_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1699472032623",
            shopifyId: "8353411596552",
            link: "/cropped-chainlock-biker-jacket",
            picture01: "../content/photos/eshop/SS24/04_XL_SS24_Cropped_Chainlock_Biker_Jacket/XL_SS24_Cropped_Chainlock_Biker_Jacket_01.jpg",
            picture02: "../content/photos/eshop/SS24/04_XL_SS24_Cropped_Chainlock_Biker_Jacket/XL_SS24_Cropped_Chainlock_Biker_Jacket_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/04_XL_SS24_Cropped_Chainlock_Biker_Jacket/XL_SS24_Cropped_Chainlock_Biker_Jacket_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/04_XL_SS24_Cropped_Chainlock_Biker_Jacket/XL_SS24_Cropped_Chainlock_Biker_Jacket_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/04_XL_SS24_Cropped_Chainlock_Biker_Jacket/XL_SS24_Cropped_Chainlock_Biker_Jacket_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/04_XL_SS24_Cropped_Chainlock_Biker_Jacket/XL_SS24_Cropped_Chainlock_Biker_Jacket_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/04_XL_SS24_Cropped_Chainlock_Biker_Jacket/XL_SS24_Cropped_Chainlock_Biker_Jacket_05.jpg",
                    loading: "eager"
                },

            ],
        },
        CroppedChainlockMiniJacket: {
            order: 3,
            itemName: parse('Cropped Chainlock Mini jacket'),
            itemPrice: parse('1140€'),
            itemFabric: "sheepskin 100%",
            itemDetails: parse(`Cropped fit. Chain detail on left sleeve. Zip-closure with a ziazag zipper guard and snap closing. Vertical welt pockets inside.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/03_XL_SS24_Cropped_Chainlock_Mini_Jacket/XL_SS24_Cropped_Chainlock_Mini_Jacket_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714493001349",
            shopifyId: "8530548556040",
            link: "/cropped-chainlock-mini-jacket",
            picture01: "../content/photos/eshop/SS24/03_XL_SS24_Cropped_Chainlock_Mini_Jacket/XL_SS24_Cropped_Chainlock_Mini_Jacket_01.jpg",
            picture02: "../content/photos/eshop/SS24/03_XL_SS24_Cropped_Chainlock_Mini_Jacket/XL_SS24_Cropped_Chainlock_Mini_Jacket_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/03_XL_SS24_Cropped_Chainlock_Mini_Jacket/XL_SS24_Cropped_Chainlock_Mini_Jacket_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/03_XL_SS24_Cropped_Chainlock_Mini_Jacket/XL_SS24_Cropped_Chainlock_Mini_Jacket_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/03_XL_SS24_Cropped_Chainlock_Mini_Jacket/XL_SS24_Cropped_Chainlock_Mini_Jacket_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/03_XL_SS24_Cropped_Chainlock_Mini_Jacket/XL_SS24_Cropped_Chainlock_Mini_Jacket_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/03_XL_SS24_Cropped_Chainlock_Mini_Jacket/XL_SS24_Cropped_Chainlock_Mini_Jacket_05.jpg",
                    loading: "eager"
                },

            ],
        },
        LeatherLapelJacket: {
            order: 17,
            itemName: parse('Leather Lapel jacket'),
            itemPrice: parse('865€'),
            itemFabric: "body · wool 80% polyester 20% / lapel · sheepskin 100%",
            itemDetails: parse(`Front opening with snap buttons. Dart details. Raw-edge collar. Contrast top stitches. Side welt pockets with flaps. Vertical welt pockets inside.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/17_XL_SS24_Leather_Lapel_Jacket/XL_SS24_Leather_Lapel_Jacket_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714493636193",
            shopifyId: "8530536726792",
            link: "/leather-lapel-jacket",
            picture01: "../content/photos/eshop/SS24/17_XL_SS24_Leather_Lapel_Jacket/XL_SS24_Leather_Lapel_Jacket_01.jpg",
            picture02: "../content/photos/eshop/SS24/17_XL_SS24_Leather_Lapel_Jacket/XL_SS24_Leather_Lapel_Jacket_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/17_XL_SS24_Leather_Lapel_Jacket/XL_SS24_Leather_Lapel_Jacket_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/17_XL_SS24_Leather_Lapel_Jacket/XL_SS24_Leather_Lapel_Jacket_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/17_XL_SS24_Leather_Lapel_Jacket/XL_SS24_Leather_Lapel_Jacket_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/17_XL_SS24_Leather_Lapel_Jacket/XL_SS24_Leather_Lapel_Jacket_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/17_XL_SS24_Leather_Lapel_Jacket/XL_SS24_Leather_Lapel_Jacket_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/SS24/17_XL_SS24_Leather_Lapel_Jacket/XL_SS24_Leather_Lapel_Jacket_06.jpg",
                    loading: "eager"
                },

            ],
        },
        SummerBikerJacket: {
            order: 7,
            itemName: parse('Summer Biker jacket'),
            itemPrice: parse('510€'),
            itemFabric: "cotton 100%",
            itemDetails: parse(`Zip-closure with a zigzag zipper guard. Side zipped pockets. Raw-edge detais on the back. Snap closing on the collor and sleeve hems. Regular fit.<br><br>
            Ronald is 183cm and wears size L.`),
            sizeChart: "../content/photos/eshop/SS24/07_XL_SS24_Summer_Biker_Jacket/XL_SS24_Summer_Biker_Jacket_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714494006861",
            shopifyId: "8530580406536",
            link: "/summer-biker-jacket",
            picture01: "../content/photos/eshop/SS24/07_XL_SS24_Summer_Biker_Jacket/XL_SS24_Summer_Biker_Jacket_01.jpg",
            picture02: "../content/photos/eshop/SS24/07_XL_SS24_Summer_Biker_Jacket/XL_SS24_Summer_Biker_Jacket_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/07_XL_SS24_Summer_Biker_Jacket/XL_SS24_Summer_Biker_Jacket_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/07_XL_SS24_Summer_Biker_Jacket/XL_SS24_Summer_Biker_Jacket_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/07_XL_SS24_Summer_Biker_Jacket/XL_SS24_Summer_Biker_Jacket_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/07_XL_SS24_Summer_Biker_Jacket/XL_SS24_Summer_Biker_Jacket_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/07_XL_SS24_Summer_Biker_Jacket/XL_SS24_Summer_Biker_Jacket_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/SS24/07_XL_SS24_Summer_Biker_Jacket/XL_SS24_Summer_Biker_Jacket_06.jpg",
                    loading: "eager"
                },

            ],
        },
        WarriorJacket: {
            order: 10,
            itemName: parse('Warrior jacket'),
            itemPrice: parse('615€'),
            itemFabric: "cotton 100%",
            itemDetails: parse(`Raglan sleeves. Zip-closure with a zigzag zipper guard. Belts and belt loops on the waist and collar.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/10_XL_SS24_Warrior_Jacket/XL_SS24_Warrior_Jacket_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714494250947",
            shopifyId: "8530586272008",
            link: "/warrior-jacket",
            picture01: "../content/photos/eshop/SS24/10_XL_SS24_Warrior_Jacket/XL_SS24_Warrior_Jacket_01.jpg",
            picture02: "../content/photos/eshop/SS24/10_XL_SS24_Warrior_Jacket/XL_SS24_Warrior_Jacket_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/10_XL_SS24_Warrior_Jacket/XL_SS24_Warrior_Jacket_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/10_XL_SS24_Warrior_Jacket/XL_SS24_Warrior_Jacket_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/10_XL_SS24_Warrior_Jacket/XL_SS24_Warrior_Jacket_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/10_XL_SS24_Warrior_Jacket/XL_SS24_Warrior_Jacket_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/10_XL_SS24_Warrior_Jacket/XL_SS24_Warrior_Jacket_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/SS24/10_XL_SS24_Warrior_Jacket/XL_SS24_Warrior_Jacket_06.jpg",
                    loading: "eager"
                },

            ],
        },
    },
    pants: {
        CreasedPants: {
            order: 18,
            itemName: parse('Creased pants'),
            itemPrice: parse('405€'),
            itemFabric: "cotton 100%",
            itemDetails: parse(`Front opening with a zipper and a metal button. Creased details on front hems and back knees. Side pockets. Additional pockets on back yoke with button closing. Regular fit.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/18_XL_SS24_Creased_Pants/XL_SS24_Creased_Pants_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714495409372",
            shopifyId: "8532525941000",
            link: "/creased-pants",
            picture01: "../content/photos/eshop/SS24/18_XL_SS24_Creased_Pants/XL_SS24_Creased_Pants_01.jpg",
            picture02: "../content/photos/eshop/SS24/18_XL_SS24_Creased_Pants/XL_SS24_Creased_Pants_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/18_XL_SS24_Creased_Pants/XL_SS24_Creased_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/18_XL_SS24_Creased_Pants/XL_SS24_Creased_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/18_XL_SS24_Creased_Pants/XL_SS24_Creased_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/18_XL_SS24_Creased_Pants/XL_SS24_Creased_Pants_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/18_XL_SS24_Creased_Pants/XL_SS24_Creased_Pants_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/SS24/18_XL_SS24_Creased_Pants/XL_SS24_Creased_Pants_06.jpg",
                    loading: "eager"
                },


            ],
        },

        CreasedLeatherPants: {
            order: 25,
            itemName: parse('Creased Leather pants'),
            itemPrice: parse('700€'),
            itemFabric: "leather 100%",
            itemDetails: parse(`Front opening with a zipper and a metal button. Creased details on front hems and back knees. Contrast top stitches. Side pockets. Additional pockets on back yoke with button closing. Regular fit.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/25_XL_SS24_Creased_Leather_Pants/XL_SS24_Creased_Leather_Pants_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714495148247",
            shopifyId: "8532527120648",
            link: "/creased-leather-pants",
            picture01: "../content/photos/eshop/SS24/25_XL_SS24_Creased_Leather_Pants/XL_SS24_Creased_Leather_Pants_01.jpg",
            picture02: "../content/photos/eshop/SS24/25_XL_SS24_Creased_Leather_Pants/XL_SS24_Creased_Leather_Pants_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/25_XL_SS24_Creased_Leather_Pants/XL_SS24_Creased_Leather_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/25_XL_SS24_Creased_Leather_Pants/XL_SS24_Creased_Leather_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/25_XL_SS24_Creased_Leather_Pants/XL_SS24_Creased_Leather_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/25_XL_SS24_Creased_Leather_Pants/XL_SS24_Creased_Leather_Pants_04.jpg",
                    loading: "eager"
                },


            ],
        },

        FoldedWaistSkirt: {
            order: 6,
            itemName: parse('Folded Waist skirt'),
            itemPrice: parse('275€'),
            itemFabric: "body · bamboo fibers 95% spandex 5% / fold detail · viscose 55% PU 45%",
            itemDetails: parse(`Partial waist lining. Zip-closure on the back.<br><br>
            Mica is 172cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/06_XL_SS24_Folded_Waist_Skirt/XL_SS24_Folded_Waist_Skirt_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714496302548",
            shopifyId: "8532518142216",
            link: "/folded-waist-skirt",
            picture01: "../content/photos/eshop/SS24/06_XL_SS24_Folded_Waist_Skirt/XL_SS24_Folded_Waist_Skirt_01.jpg",
            picture02: "../content/photos/eshop/SS24/06_XL_SS24_Folded_Waist_Skirt/XL_SS24_Folded_Waist_Skirt_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/06_XL_SS24_Folded_Waist_Skirt/XL_SS24_Folded_Waist_Skirt_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/06_XL_SS24_Folded_Waist_Skirt/XL_SS24_Folded_Waist_Skirt_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/06_XL_SS24_Folded_Waist_Skirt/XL_SS24_Folded_Waist_Skirt_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/06_XL_SS24_Folded_Waist_Skirt/XL_SS24_Folded_Waist_Skirt_04.jpg",
                    loading: "eager"
                },
            ],
        },
        OverKneeLeatherShorts: {
            order: 16,
            itemName: parse('Over Knee Leather shorts'),
            itemPrice: parse('685€'),
            itemFabric: "cowhide 100%",
            itemDetails: parse(`Front opening with a zipper and a metal button. Pleat details on the side with multiple top stitches. Wide leg openings. Side pockets. Back yoke. Patch pockets on the back. Loose fit.<br><br>
            Ronald is 183cm and wears size L.`),
            sizeChart: "../content/photos/eshop/SS24/16_XL_SS24_Over_Knee_Leather_Shorts/XL_SS24_Over_Knee_Leather_Shorts_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714496600019",
            shopifyId: "8532528890120",
            link: "/over-knee-leather-shorts",
            picture01: "../content/photos/eshop/SS24/16_XL_SS24_Over_Knee_Leather_Shorts/XL_SS24_Over_Knee_Leather_Shorts_01.jpg",
            picture02: "../content/photos/eshop/SS24/16_XL_SS24_Over_Knee_Leather_Shorts/XL_SS24_Over_Knee_Leather_Shorts_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/16_XL_SS24_Over_Knee_Leather_Shorts/XL_SS24_Over_Knee_Leather_Shorts_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/16_XL_SS24_Over_Knee_Leather_Shorts/XL_SS24_Over_Knee_Leather_Shorts_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/16_XL_SS24_Over_Knee_Leather_Shorts/XL_SS24_Over_Knee_Leather_Shorts_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/16_XL_SS24_Over_Knee_Leather_Shorts/XL_SS24_Over_Knee_Leather_Shorts_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/16_XL_SS24_Over_Knee_Leather_Shorts/XL_SS24_Over_Knee_Leather_Shorts_05.jpg",
                    loading: "eager"
                },
            ],
        },
        OverKneeShorts: {
            order: 12,
            itemName: parse('Over Knee shorts'),
            itemPrice: parse('370€'),
            itemFabric: "cotton 100%",
            itemDetails: parse(`Front opening with a zipper and a metal button. Pleat details on the side with multiple top stitches. Wide leg openings. Side pockets. Back yoke. Patch pockets on the back. Loose fit.<br><br>
            Mica is 172cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/12_XL_SS24_Over_Knee_Shorts/XL_SS24_Over_Knee_Shorts_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714496813673",
            shopifyId: "8532530561288",
            link: "/over-knee-shorts",
            picture01: "../content/photos/eshop/SS24/12_XL_SS24_Over_Knee_Shorts/XL_SS24_Over_Knee_Shorts_01.jpg",
            picture02: "../content/photos/eshop/SS24/12_XL_SS24_Over_Knee_Shorts/XL_SS24_Over_Knee_Shorts_02.jpg",


            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/12_XL_SS24_Over_Knee_Shorts/XL_SS24_Over_Knee_Shorts_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/12_XL_SS24_Over_Knee_Shorts/XL_SS24_Over_Knee_Shorts_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/12_XL_SS24_Over_Knee_Shorts/XL_SS24_Over_Knee_Shorts_03.jpg",
                    loading: "eager"
                },

            ],
        },
        PleatedVolumePants: {
            order: 21,
            itemName: parse('Pleated Volume pants'),
            itemPrice: parse('465€'),
            itemFabric: "polyester 70% wool 30%",
            itemDetails: parse(`Front opening with a zipper and a metal button. Pleat details on the side. Side pockets and back welt pockets. Extra wide leg.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/21_XL_SS24_Pleated_Volume_Pants/XL_SS24_Pleated_Volume_Pants_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714497195897",
            shopifyId: "8532515225864",
            link: "/pleated-volume-pants",
            picture01: "../content/photos/eshop/SS24/21_XL_SS24_Pleated_Volume_Pants/XL_SS24_Pleated_Volume_Pants_01.jpg",
            picture02: "../content/photos/eshop/SS24/21_XL_SS24_Pleated_Volume_Pants/XL_SS24_Pleated_Volume_Pants_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/21_XL_SS24_Pleated_Volume_Pants/XL_SS24_Pleated_Volume_Pants_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/21_XL_SS24_Pleated_Volume_Pants/XL_SS24_Pleated_Volume_Pants_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/21_XL_SS24_Pleated_Volume_Pants/XL_SS24_Pleated_Volume_Pants_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/21_XL_SS24_Pleated_Volume_Pants/XL_SS24_Pleated_Volume_Pants_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/21_XL_SS24_Pleated_Volume_Pants/XL_SS24_Pleated_Volume_Pants_05.jpg",
                    loading: "eager"
                },

            ],
        },

    },

    tops: {
        ChainlockVest: {
            order: 19,
            itemName: parse('Chainlock vest'),
            itemPrice: parse('275€'),
            itemFabric: "cotton 100%",
            itemDetails: parse(`Panelled body connected with chains. Snap opening on the back.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/19_XL_SS24_Chainlock_Vest/XL_SS24_Chainlock_Vest_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714547999633",
            shopifyId: "8532511031560",
            link: "/chainlock-vest",
            picture01: "../content/photos/eshop/SS24/19_XL_SS24_Chainlock_Vest/XL_SS24_Chainlock_Vest_01.jpg",
            picture02: "../content/photos/eshop/SS24/19_XL_SS24_Chainlock_Vest/XL_SS24_Chainlock_Vest_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/19_XL_SS24_Chainlock_Vest/XL_SS24_Chainlock_Vest_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/19_XL_SS24_Chainlock_Vest/XL_SS24_Chainlock_Vest_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/19_XL_SS24_Chainlock_Vest/XL_SS24_Chainlock_Vest_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/19_XL_SS24_Chainlock_Vest/XL_SS24_Chainlock_Vest_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/19_XL_SS24_Chainlock_Vest/XL_SS24_Chainlock_Vest_05.jpg",
                    loading: "eager"
                },

            ],
        },
        CreasedLayeredMuscleTank: {
            order: 13,
            itemName: parse('Creased Layered Muscle tank'),
            itemPrice: parse('210€'),
            itemFabric: "under layer · cotton 95% spandex 5% / upper layer · nylon 94% spandex 6%",
            itemDetails: parse(`Signature tank with creased mesh layer. Tight fit.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/13_XL_SS24_Creased_Layered_Muscle_Tank/XL_SS24_Creased_Layered_Muscle_Tank_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714548281654",
            shopifyId: "8532496711944",
            link: "/creased-layered-muscle-tank",
            picture01: "../content/photos/eshop/SS24/13_XL_SS24_Creased_Layered_Muscle_Tank/XL_SS24_Creased_Layered_Muscle_Tank_01.jpg",
            picture02: "../content/photos/eshop/SS24/13_XL_SS24_Creased_Layered_Muscle_Tank/XL_SS24_Creased_Layered_Muscle_Tank_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/13_XL_SS24_Creased_Layered_Muscle_Tank/XL_SS24_Creased_Layered_Muscle_Tank_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/13_XL_SS24_Creased_Layered_Muscle_Tank/XL_SS24_Creased_Layered_Muscle_Tank_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/13_XL_SS24_Creased_Layered_Muscle_Tank/XL_SS24_Creased_Layered_Muscle_Tank_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/13_XL_SS24_Creased_Layered_Muscle_Tank/XL_SS24_Creased_Layered_Muscle_Tank_04.jpg",
                    loading: "eager"
                },

            ],
        },
        CreasedMeshDress: {
            order: 5,
            itemName: parse('Creased Mesh dress'),
            itemPrice: parse('355€'),
            itemFabric: "nylon 94% spandex 6%",
            itemDetails: parse(`Partially lined sheer dress with creased details. Long sleeves. Zip-closure on the back. Tight fit.<br><br>
            Mica is 172cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/05_XL_SS24_Creased_Mesh_Dress/XL_SS24_Creased_Mesh_Dress_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714548544580",
            shopifyId: "8530590761224",
            link: "/creased-mesh-dress",
            picture01: "../content/photos/eshop/SS24/05_XL_SS24_Creased_Mesh_Dress/XL_SS24_Creased_Mesh_Dress_01.jpg",
            picture02: "../content/photos/eshop/SS24/05_XL_SS24_Creased_Mesh_Dress/XL_SS24_Creased_Mesh_Dress_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/05_XL_SS24_Creased_Mesh_Dress/XL_SS24_Creased_Mesh_Dress_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/05_XL_SS24_Creased_Mesh_Dress/XL_SS24_Creased_Mesh_Dress_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/05_XL_SS24_Creased_Mesh_Dress/XL_SS24_Creased_Mesh_Dress_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/05_XL_SS24_Creased_Mesh_Dress/XL_SS24_Creased_Mesh_Dress_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/05_XL_SS24_Creased_Mesh_Dress/XL_SS24_Creased_Mesh_Dress_05.jpg",
                    loading: "eager"
                },
            ],
        },
        CreasedZipHoodie: {
            order: 1,
            itemName: parse('Creased Zip hoodie'),
            itemPrice: parse('550€'),
            itemFabric: "cotton 100%",
            itemDetails: parse(`Creased sleeves detail. Zip-closure. Front pockets with magnetic snap buttons. Regular fit.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/01_XL_SS24_Creased_Zip_Hoodie/XL_SS24_Creased_Zip_Hoodie_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714548875384",
            shopifyId: "8530533056776",
            link: "/creased-zip-hoodie",
            picture01: "../content/photos/eshop/SS24/01_XL_SS24_Creased_Zip_Hoodie/XL_SS24_Creased_Zip_Hoodie_01.jpg",
            picture02: "../content/photos/eshop/SS24/01_XL_SS24_Creased_Zip_Hoodie/XL_SS24_Creased_Zip_Hoodie_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/01_XL_SS24_Creased_Zip_Hoodie/XL_SS24_Creased_Zip_Hoodie_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/01_XL_SS24_Creased_Zip_Hoodie/XL_SS24_Creased_Zip_Hoodie_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/01_XL_SS24_Creased_Zip_Hoodie/XL_SS24_Creased_Zip_Hoodie_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/01_XL_SS24_Creased_Zip_Hoodie/XL_SS24_Creased_Zip_Hoodie_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/01_XL_SS24_Creased_Zip_Hoodie/XL_SS24_Creased_Zip_Hoodie_05.jpg",
                    loading: "eager"
                },
            ],
        },
        DistressedCroppedHoodie: {
            order: 14,
            itemName: parse('Distressed Cropped hoodie'),
            itemPrice: parse('410€'),
            itemFabric: "cotton 100%",
            itemDetails: parse(`Distressed and bleached fabric. Adjustable strap on front. Snap closing on the neck. Ribbed gusset on the sleeve hem. Loose fit.<br><br>
            Mica is 172cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/14_XL_SS24_Distressed_Cropped_Hoodie/XL_SS24_Distressed_Cropped_Hoodie_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714549807284",
            shopifyId: "8532502806792",
            link: "/distressed-cropped-hoodie",
            picture01: "../content/photos/eshop/SS24/14_XL_SS24_Distressed_Cropped_Hoodie/XL_SS24_Distressed_Cropped_Hoodie_01.jpg",
            picture02: "../content/photos/eshop/SS24/14_XL_SS24_Distressed_Cropped_Hoodie/XL_SS24_Distressed_Cropped_Hoodie_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/14_XL_SS24_Distressed_Cropped_Hoodie/XL_SS24_Distressed_Cropped_Hoodie_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/14_XL_SS24_Distressed_Cropped_Hoodie/XL_SS24_Distressed_Cropped_Hoodie_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/14_XL_SS24_Distressed_Cropped_Hoodie/XL_SS24_Distressed_Cropped_Hoodie_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/14_XL_SS24_Distressed_Cropped_Hoodie/XL_SS24_Distressed_Cropped_Hoodie_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/14_XL_SS24_Distressed_Cropped_Hoodie/XL_SS24_Distressed_Cropped_Hoodie_05.jpg",
                    loading: "eager"
                },
            ],
        },

        DrapeTankLightbrown: {
            order: 2,
            itemName: parse('Drape tank'),
            itemPrice: parse('240€'),
            itemFabric: "bamboo fibers 95% spandex 5%",
            itemDetails: parse(`Draped details. Moderately stretchy fabric. Loose fit.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/02_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714550233803",
            shopifyId: "8532474003720",
            link: "/drape-tank-lightbrown",
            picture01: "../content/photos/eshop/SS24/02_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_01.jpg",
            picture02: "../content/photos/eshop/SS24/02_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/02_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/02_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/02_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/02_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/02_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_05.jpg",
                    loading: "eager"
                },
                {
                    key: 6,
                    srcJpeg: "../content/photos/eshop/SS24/02_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_06.jpg",
                    loading: "eager"
                },
            ],
        },
        DrapeTankLightgrey: {
            order: 8,
            itemName: parse('Drape tank'),
            itemPrice: parse('240€'),
            itemFabric: "bamboo fibers 95% spandex 5%",
            itemDetails: parse(`Draped details. Moderately stretchy fabric. Loose fit.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/08_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714550491627",
            shopifyId: "8532484817160",
            link: "/drape-tank-lightgrey",
            picture01: "../content/photos/eshop/SS24/08_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_01.jpg",
            picture02: "../content/photos/eshop/SS24/08_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/08_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/08_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/08_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/08_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/08_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_05.jpg",
                    loading: "eager"
                },

            ],
        },
        DrapeTankWhite: {
            order: 23,
            itemName: parse('Drape tank'),
            itemPrice: parse('240€'),
            itemFabric: "bamboo fibers 95% spandex 5%",
            itemDetails: parse(`Draped details. Moderately stretchy fabric. Loose fit.<br><br>
            Mica is 172cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/23_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714551005906",
            shopifyId: "8532486095112",
            link: "/drape-tank-white",
            picture01: "../content/photos/eshop/SS24/23_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_01.jpg",
            picture02: "../content/photos/eshop/SS24/23_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/23_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/23_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/23_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/23_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/23_XL_SS24_Drape_Tank/XL_SS24_Drape_Tank_05.jpg",
                    loading: "eager"
                },

            ],
        },
        FoldedWaistDress: {
            order: 15,
            itemName: parse('Folded Waist dress'),
            itemPrice: parse('240€'),
            itemFabric: "body · bamboo fibers 95% spandex 5% / fold detail · viscose 55% PU 45%",
            itemDetails: parse(`Partial waist lining. Zip-closure on the back.<br><br>
            Mica is 172cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/15_XL_SS24_Folded_Waist_Dress/XL_SS24_Folded_Waist_Dress_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714658796396",
            shopifyId: "8545313587464",
            link: "/folded-waist-dress",
            picture01: "../content/photos/eshop/SS24/15_XL_SS24_Folded_Waist_Dress/XL_SS24_Folded_Waist_Dress_01.jpg",
            picture02: "../content/photos/eshop/SS24/15_XL_SS24_Folded_Waist_Dress/XL_SS24_Folded_Waist_Dress_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/15_XL_SS24_Folded_Waist_Dress/XL_SS24_Folded_Waist_Dress_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/15_XL_SS24_Folded_Waist_Dress/XL_SS24_Folded_Waist_Dress_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/15_XL_SS24_Folded_Waist_Dress/XL_SS24_Folded_Waist_Dress_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/15_XL_SS24_Folded_Waist_Dress/XL_SS24_Folded_Waist_Dress_04.jpg",
                    loading: "eager"
                },
                {
                    key: 5,
                    srcJpeg: "../content/photos/eshop/SS24/15_XL_SS24_Folded_Waist_Dress/XL_SS24_Folded_Waist_Dress_05.jpg",
                    loading: "eager"
                },

            ],
        },
        MeshLayeredTee: {
            order: 20,
            itemName: parse('Mesh Layered tee'),
            itemPrice: parse('250€'),
            itemFabric: "under layer · cotton 100% / upper layer · nylon 94% spandex 6%",
            itemDetails: parse(`T-shirt with mesh layer. Classic crewneck. Regular fit.<br><br>
            Ronald is 183cm and wears size L.`),
            sizeChart: "../content/photos/eshop/SS24/20_XL_SS24_Mesh_Layered_Tee/XL_SS24_Mesh_Layered_Tee_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714551640195",
            shopifyId: "8532488454408",
            link: "/mesh-layered-tee",
            picture01: "../content/photos/eshop/SS24/20_XL_SS24_Mesh_Layered_Tee/XL_SS24_Mesh_Layered_Tee_01.jpg",
            picture02: "../content/photos/eshop/SS24/20_XL_SS24_Mesh_Layered_Tee/XL_SS24_Mesh_Layered_Tee_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/20_XL_SS24_Mesh_Layered_Tee/XL_SS24_Mesh_Layered_Tee_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/20_XL_SS24_Mesh_Layered_Tee/XL_SS24_Mesh_Layered_Tee_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/20_XL_SS24_Mesh_Layered_Tee/XL_SS24_Mesh_Layered_Tee_03.jpg",
                    loading: "eager"
                },
            ],
        },
        SlouchyLongTeeSS24: {
            order: 11,
            itemName: parse('Slouchy Long tee'),
            itemPrice: parse('250€'),
            itemFabric: "cotton 97% spandex 3%",
            itemDetails: parse(`Oversized t-shirt with hanging gathered sleeves. Ribbed neckline and hem.<br><br>
            Mica is 172cm and wears size L.`),
            sizeChart: "../content/photos/eshop/SS24/11_XL_SS24_Slouchy_Long_Tee/XL_SS24_Slouchy_Long_Tee_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714551850415",
            shopifyId: "8532506607880",
            link: "/slouchy-long-tee-ss24",
            picture01: "../content/photos/eshop/SS24/11_XL_SS24_Slouchy_Long_Tee/XL_SS24_Slouchy_Long_Tee_01.jpg",
            picture02: "../content/photos/eshop/SS24/11_XL_SS24_Slouchy_Long_Tee/XL_SS24_Slouchy_Long_Tee_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/11_XL_SS24_Slouchy_Long_Tee/XL_SS24_Slouchy_Long_Tee_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/11_XL_SS24_Slouchy_Long_Tee/XL_SS24_Slouchy_Long_Tee_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/11_XL_SS24_Slouchy_Long_Tee/XL_SS24_Slouchy_Long_Tee_03.jpg",
                    loading: "eager"
                },
                {
                    key: 4,
                    srcJpeg: "../content/photos/eshop/SS24/11_XL_SS24_Slouchy_Long_Tee/XL_SS24_Slouchy_Long_Tee_04.jpg",
                    loading: "eager"
                },
            ],
        },
        TwinFoldTeeGrey: {
            order: 22,
            itemName: parse('Twin Fold tee'),
            itemPrice: parse('240€'),
            itemFabric: "cotton 100%",
            itemDetails: parse(`Pleated details on front and back. Classic crewneck. Regular fit.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/22_XL_SS24_Twin_Fold_Tee/XL_SS24_Twin_Fold_Tee_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714552134704",
            shopifyId: "8532487733512",
            link: "/twin-fold-tee-grey",
            picture01: "../content/photos/eshop/SS24/22_XL_SS24_Twin_Fold_Tee/XL_SS24_Twin_Fold_Tee_01.jpg",
            picture02: "../content/photos/eshop/SS24/22_XL_SS24_Twin_Fold_Tee/XL_SS24_Twin_Fold_Tee_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/22_XL_SS24_Twin_Fold_Tee/XL_SS24_Twin_Fold_Tee_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/22_XL_SS24_Twin_Fold_Tee/XL_SS24_Twin_Fold_Tee_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/22_XL_SS24_Twin_Fold_Tee/XL_SS24_Twin_Fold_Tee_03.jpg",
                    loading: "eager"
                },

            ],
        },
        TwinFoldTeeWhite: {
            order: 26,
            itemName: parse('Twin Fold tee'),
            itemPrice: parse('240€'),
            itemFabric: "cotton 100%",
            itemDetails: parse(`Pleated details on front and back. Classic crewneck. Regular fit.<br><br>
            Ronald is 183cm and wears size M.`),
            sizeChart: "../content/photos/eshop/SS24/26_XL_SS24_Twin_Fold_Tee/XL_SS24_Twin_Fold_Tee_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714552271864",
            shopifyId: "8532487012616",
            link: "/twin-fold-tee-white",
            picture01: "../content/photos/eshop/SS24/26_XL_SS24_Twin_Fold_Tee/XL_SS24_Twin_Fold_Tee_01.jpg",
            picture02: "../content/photos/eshop/SS24/26_XL_SS24_Twin_Fold_Tee/XL_SS24_Twin_Fold_Tee_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/26_XL_SS24_Twin_Fold_Tee/XL_SS24_Twin_Fold_Tee_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/26_XL_SS24_Twin_Fold_Tee/XL_SS24_Twin_Fold_Tee_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/26_XL_SS24_Twin_Fold_Tee/XL_SS24_Twin_Fold_Tee_03.jpg",
                    loading: "eager"
                },

            ],
        },

    },

    accessories: {
        MiniBraToteBag: {
            order: 9,
            itemName: parse('Mini Bra Tote bag'),
            itemPrice: parse('310€'),
            itemFabric: "body · PU 100% / lining · cotton 100%",
            itemDetails: `Fully lined. Adjustable straps on each side with snaps. Zip-closure on each side.`,
            sizeChart: "../content/photos/eshop/SS24/09_XL_SS24_Mini_Bra_Tote/XL_SS24_Mini_Bra_Tote_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714552638686",
            shopifyId: "8532532035848",
            link: "/mini-bra-tote-bag",
            picture01: "../content/photos/eshop/SS24/09_XL_SS24_Mini_Bra_Tote/XL_SS24_Mini_Bra_Tote_01.jpg",
            picture02: "../content/photos/eshop/SS24/09_XL_SS24_Mini_Bra_Tote/XL_SS24_Mini_Bra_Tote_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/09_XL_SS24_Mini_Bra_Tote/XL_SS24_Mini_Bra_Tote_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/09_XL_SS24_Mini_Bra_Tote/XL_SS24_Mini_Bra_Tote_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/09_XL_SS24_Mini_Bra_Tote/XL_SS24_Mini_Bra_Tote_03.jpg",
                    loading: "eager"
                },

            ],
        },
        MiniBraDenimToteBag: {
            order: 24,
            itemName: parse('Mini Bra Denim Tote bag'),
            itemPrice: parse('240€'),
            itemFabric: "cotton 100%",
            itemDetails: `Fully lined. Adjustable straps on each side with snaps. Zip-closure on each side.`,
            sizeChart: "../content/photos/eshop/SS24/24_XL_SS24_Mini_Bra_Denim_Tote/XL_SS24_Mini_Bra_Denim_Tote_Size_Chart.jpg",

            soldOut: false,
            onSale: false,

            shopifyDivId: "1714552889512",
            shopifyId: "8532534395144",
            link: "/mini-bra-denim-tote-bag",
            picture01: "../content/photos/eshop/SS24/24_XL_SS24_Mini_Bra_Denim_Tote/XL_SS24_Mini_Bra_Denim_Tote_01.jpg",
            picture02: "../content/photos/eshop/SS24/24_XL_SS24_Mini_Bra_Denim_Tote/XL_SS24_Mini_Bra_Denim_Tote_02.jpg",

            pictures: [
                {
                    key: 1,
                    srcJpeg: "../content/photos/eshop/SS24/24_XL_SS24_Mini_Bra_Denim_Tote/XL_SS24_Mini_Bra_Denim_Tote_01.jpg",
                    loading: "eager"
                },
                {
                    key: 2,
                    srcJpeg: "../content/photos/eshop/SS24/24_XL_SS24_Mini_Bra_Denim_Tote/XL_SS24_Mini_Bra_Denim_Tote_02.jpg",
                    loading: "eager"
                },
                {
                    key: 3,
                    srcJpeg: "../content/photos/eshop/SS24/24_XL_SS24_Mini_Bra_Denim_Tote/XL_SS24_Mini_Bra_Denim_Tote_03.jpg",
                    loading: "eager"
                },

            ],
        },


    },
}