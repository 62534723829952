import React, { useState } from "react";

import { SS22 } from "../js/database/database-ss22.js";
import { AW22 } from "../js/database/database-aw22.js";
import { SS23 } from "../js/database/database-ss23.js";
import { AW23 } from "../js/database/database-aw23-eshop.js";
import { SS24 } from "../js/database/database-ss24-eshop.js";

import Cube from "../components/cube";

import EshopContainer from "../components/containers/EshopContainer.js";
import NewsletterModal from "../components/NewsletterModal.js";

const Eshop = (props) => {
  // newsletter
  const [isModalOpen, setModalOpen] = useState(true);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  // newsletter

  const extractAndSortItems = (category) => {
    return Object.values(category) // Convert the items object to an array
      .sort((a, b) => a.order - b.order); // Sort by 'order'
  };

  // Extract items from each category and sort them
  const sortedTops = extractAndSortItems(SS24.tops);
  const sortedPants = extractAndSortItems(SS24.pants);
  const sortedJackets = extractAndSortItems(SS24.jackets);
  const sortedAccessories = extractAndSortItems(SS24.accessories);

  // Combine all the sorted items into one array and sort again
  const allSortedItems = sortedTops
    .concat(sortedPants, sortedJackets, sortedAccessories)
    .sort((a, b) => a.order - b.order);

  // Map over the sorted items array to generate the Cube components
  const allRankedSS24 = allSortedItems.map((item, index) => (
    <Cube
      key={index} // Using the index as a key because 'order' might not be unique
      itemName={item.itemName}
      itemLink={item.link}
      itemPrice={item.itemPrice}
      picture01={item.picture01}
      picture02={item.picture02}
      soldOut={item.soldOut}
    />
  ));

  return (
    <EshopContainer pageTitle={"E-shop"}>
      <NewsletterModal isOpen={isModalOpen} onClose={closeModal} />

      {allRankedSS24}

      <Cube
        itemName={AW23.pants.StonewashedBeltedCombatPants.itemName}
        itemLink={AW23.pants.StonewashedBeltedCombatPants.link}
        itemPrice={AW23.pants.StonewashedBeltedCombatPants.itemPrice}
        picture01={AW23.pants.StonewashedBeltedCombatPants.picture01}
        picture02={AW23.pants.StonewashedBeltedCombatPants.picture02}
      />

      <Cube
        itemName={AW23.tops.PrintedHoodie.itemName}
        itemLink={AW23.tops.PrintedHoodie.link}
        itemPrice={AW23.tops.PrintedHoodie.itemPrice}
        picture01={AW23.tops.PrintedHoodie.picture01}
        picture02={AW23.tops.PrintedHoodie.picture02}
      />
      <Cube
        itemName={AW23.jackets.LoopsBomber.itemName}
        itemLink={AW23.jackets.LoopsBomber.link}
        itemPrice={AW23.jackets.LoopsBomber.itemPrice}
        picture01={AW23.jackets.LoopsBomber.picture01}
        picture02={AW23.jackets.LoopsBomber.picture02}
        soldOut={AW23.jackets.LoopsBomber.soldOut}
      />

      <Cube
        itemName={AW23.tops.PrintedTee.itemName}
        itemLink={AW23.tops.PrintedTee.link}
        itemPrice={AW23.tops.PrintedTee.itemPrice}
        picture01={AW23.tops.PrintedTee.picture01}
        picture02={AW23.tops.PrintedTee.picture02}
      />

      <Cube
        itemName={AW23.jackets.MemoryLeatherJacket.itemName}
        itemLink={AW23.jackets.MemoryLeatherJacket.link}
        itemPrice={AW23.jackets.MemoryLeatherJacket.itemPrice}
        picture01={AW23.jackets.MemoryLeatherJacket.picture01}
        picture02={AW23.jackets.MemoryLeatherJacket.picture02}
        soldOut={AW23.jackets.MemoryLeatherJacket.soldOut}
      />

      {/* 6 */}

      <Cube
        itemName={AW23.accessories.BraToteBag.itemName}
        itemLink={AW23.accessories.BraToteBag.link}
        itemPrice={AW23.accessories.BraToteBag.itemPrice}
        picture01={AW23.accessories.BraToteBag.picture01}
        picture02={AW23.accessories.BraToteBag.picture02}
      />

      {/* 7 */}

      <Cube
        itemName={AW23.jackets.FlameBikerJacket.itemName}
        itemLink={AW23.jackets.FlameBikerJacket.link}
        itemPrice={AW23.jackets.FlameBikerJacket.itemPrice}
        picture01={AW23.jackets.FlameBikerJacket.picture01}
        picture02={AW23.jackets.FlameBikerJacket.picture02}
      />

      {/* 8 */}

      <Cube
        itemName={AW23.tops.LogoZipUpKnit.itemName}
        itemLink={AW23.tops.LogoZipUpKnit.link}
        itemPrice={AW23.tops.LogoZipUpKnit.itemPrice}
        picture01={AW23.tops.LogoZipUpKnit.picture01}
        picture02={AW23.tops.LogoZipUpKnit.picture02}
      />

      {/* 9 */}

      <Cube
        itemName={AW23.accessories.LeatherBraToteBagRed.itemName}
        itemLink={AW23.accessories.LeatherBraToteBagRed.link}
        itemPrice={AW23.accessories.LeatherBraToteBagRed.itemPrice}
        picture01={AW23.accessories.LeatherBraToteBagRed.picture01}
        picture02={AW23.accessories.LeatherBraToteBagRed.picture02}
      />

      {/* 10 */}

      <Cube
        itemName={AW23.pants.LeatherGrippedPants.itemName}
        itemLink={AW23.pants.LeatherGrippedPants.link}
        itemPrice={AW23.pants.LeatherGrippedPants.itemPrice}
        picture01={AW23.pants.LeatherGrippedPants.picture01}
        picture02={AW23.pants.LeatherGrippedPants.picture02}
      />
      {/* 11 */}

      <Cube
        itemName={AW23.tops.SlouchyLongTee.itemName}
        itemLink={AW23.tops.SlouchyLongTee.link}
        itemPrice={AW23.tops.SlouchyLongTee.itemPrice}
        picture01={AW23.tops.SlouchyLongTee.picture01}
        picture02={AW23.tops.SlouchyLongTee.picture02}
      />

      {/* 12 */}

      <Cube
        itemName={AW23.jackets.SculpturedLeatherBlazer.itemName}
        itemLink={AW23.jackets.SculpturedLeatherBlazer.link}
        itemPrice={AW23.jackets.SculpturedLeatherBlazer.itemPrice}
        picture01={AW23.jackets.SculpturedLeatherBlazer.picture01}
        picture02={AW23.jackets.SculpturedLeatherBlazer.picture02}
      />

      {/* 13 */}

      <Cube
        itemName={AW23.tops.PrintedSweatshirt.itemName}
        itemLink={AW23.tops.PrintedSweatshirt.link}
        itemPrice={AW23.tops.PrintedSweatshirt.itemPrice}
        picture01={AW23.tops.PrintedSweatshirt.picture01}
        picture02={AW23.tops.PrintedSweatshirt.picture02}
      />
      {/* 14 */}

      <Cube
        itemName={AW23.pants.ChainlockBikerPantsAW23.itemName}
        itemLink={AW23.pants.ChainlockBikerPantsAW23.link}
        itemPrice={AW23.pants.ChainlockBikerPantsAW23.itemPrice}
        picture01={AW23.pants.ChainlockBikerPantsAW23.picture01}
        picture02={AW23.pants.ChainlockBikerPantsAW23.picture02}
      />
      {/* 15 */}

      <Cube
        itemName={AW23.accessories.StonewashedBraToteBag.itemName}
        itemLink={AW23.accessories.StonewashedBraToteBag.link}
        itemPrice={AW23.accessories.StonewashedBraToteBag.itemPrice}
        picture01={AW23.accessories.StonewashedBraToteBag.picture01}
        picture02={AW23.accessories.StonewashedBraToteBag.picture02}
        soldOut={AW23.accessories.StonewashedBraToteBag.soldOut}
      />

      {/* 16 */}

      <Cube
        itemName={AW23.tops.BaroqueShirtWhite.itemName}
        itemLink={AW23.tops.BaroqueShirtWhite.link}
        itemPrice={AW23.tops.BaroqueShirtWhite.itemPrice}
        picture01={AW23.tops.BaroqueShirtWhite.picture01}
        picture02={AW23.tops.BaroqueShirtWhite.picture02}
      />

      {/* 17 */}

      <Cube
        itemName={AW23.tops.TrippleBeltedHighNeckVest.itemName}
        itemLink={AW23.tops.TrippleBeltedHighNeckVest.link}
        itemPrice={AW23.tops.TrippleBeltedHighNeckVest.itemPrice}
        picture01={AW23.tops.TrippleBeltedHighNeckVest.picture01}
        picture02={AW23.tops.TrippleBeltedHighNeckVest.picture02}
      />

      {/* 18 */}

      <Cube
        itemName={AW23.pants.BeltedCombatLeatherPants.itemName}
        itemLink={AW23.pants.BeltedCombatLeatherPants.link}
        itemPrice={AW23.pants.BeltedCombatLeatherPants.itemPrice}
        picture01={AW23.pants.BeltedCombatLeatherPants.picture01}
        picture02={AW23.pants.BeltedCombatLeatherPants.picture02}
      />

      {/* 19 */}

      <Cube
        itemName={AW23.tops.CutOutRibKnitBlack.itemName}
        itemLink={AW23.tops.CutOutRibKnitBlack.link}
        itemPrice={AW23.tops.CutOutRibKnitBlack.itemPrice}
        picture01={AW23.tops.CutOutRibKnitBlack.picture01}
        picture02={AW23.tops.CutOutRibKnitBlack.picture02}
      />

      {/* 20 */}

      <Cube
        itemName={AW23.tops.SignatureTank.itemName}
        itemLink={AW23.tops.SignatureTank.link}
        itemPrice={AW23.tops.SignatureTank.itemPrice}
        picture01={AW23.tops.SignatureTank.picture01}
        picture02={AW23.tops.SignatureTank.picture02}
      />

      {/* 21 */}

      <Cube
        itemName={AW23.jackets.GrippedArmJacket.itemName}
        itemLink={AW23.jackets.GrippedArmJacket.link}
        itemPrice={AW23.jackets.GrippedArmJacket.itemPrice}
        picture01={AW23.jackets.GrippedArmJacket.picture01}
        picture02={AW23.jackets.GrippedArmJacket.picture02}
      />

      {/* 22 */}

      <Cube
        itemName={AW23.pants.CurtainPants.itemName}
        itemLink={AW23.pants.CurtainPants.link}
        itemPrice={AW23.pants.CurtainPants.itemPrice}
        picture01={AW23.pants.CurtainPants.picture01}
        picture02={AW23.pants.CurtainPants.picture02}
      />

      {/* 23 */}

      <Cube
        itemName={AW23.accessories.LeatherBraToteBagBlack.itemName}
        itemLink={AW23.accessories.LeatherBraToteBagBlack.link}
        itemPrice={AW23.accessories.LeatherBraToteBagBlack.itemPrice}
        picture01={AW23.accessories.LeatherBraToteBagBlack.picture01}
        picture02={AW23.accessories.LeatherBraToteBagBlack.picture02}
      />

      {/* 24 */}

      <Cube
        itemName={AW23.tops.ChunkyRibKnitBlack.itemName}
        itemLink={AW23.tops.ChunkyRibKnitBlack.link}
        itemPrice={AW23.tops.ChunkyRibKnitBlack.itemPrice}
        picture01={AW23.tops.ChunkyRibKnitBlack.picture01}
        picture02={AW23.tops.ChunkyRibKnitBlack.picture02}
      />

      {/* 25 */}

      <Cube
        itemName={AW23.tops.BaroqueShirtBlack.itemName}
        itemLink={AW23.tops.BaroqueShirtBlack.link}
        itemPrice={AW23.tops.BaroqueShirtBlack.itemPrice}
        picture01={AW23.tops.BaroqueShirtBlack.picture01}
        picture02={AW23.tops.BaroqueShirtBlack.picture02}
      />

      {/* 26 */}

      <Cube
        itemName={AW23.tops.SignatureTankBlack.itemName}
        itemLink={AW23.tops.SignatureTankBlack.link}
        itemPrice={AW23.tops.SignatureTankBlack.itemPrice}
        picture01={AW23.tops.SignatureTankBlack.picture01}
        picture02={AW23.tops.SignatureTankBlack.picture02}
      />

      {/* 27 */}

      <Cube
        itemName={AW23.tops.CutOutRibKnit.itemName}
        itemLink={AW23.tops.CutOutRibKnit.link}
        itemPrice={AW23.tops.CutOutRibKnit.itemPrice}
        picture01={AW23.tops.CutOutRibKnit.picture01}
        picture02={AW23.tops.CutOutRibKnit.picture02}
      />

      {/* 28 */}

      <Cube
        itemName={AW23.tops.ChunkyRibKnitRed.itemName}
        itemLink={AW23.tops.ChunkyRibKnitRed.link}
        itemPrice={AW23.tops.ChunkyRibKnitRed.itemPrice}
        picture01={AW23.tops.ChunkyRibKnitRed.picture01}
        picture02={AW23.tops.ChunkyRibKnitRed.picture02}
      />

      {/* 29 */}

      <Cube
        itemName={AW23.pants.WaterproofGrippedPants.itemName}
        itemLink={AW23.pants.WaterproofGrippedPants.link}
        itemPrice={AW23.pants.WaterproofGrippedPants.itemPrice}
        picture01={AW23.pants.WaterproofGrippedPants.picture01}
        picture02={AW23.pants.WaterproofGrippedPants.picture02}
      />

      {/* 30 */}

      <Cube
        itemName={AW23.pants.PUBeltedCombatPants.itemName}
        itemLink={AW23.pants.PUBeltedCombatPants.link}
        itemPrice={AW23.pants.PUBeltedCombatPants.itemPrice}
        picture01={AW23.pants.PUBeltedCombatPants.picture01}
        picture02={AW23.pants.PUBeltedCombatPants.picture02}
      />

      {/* 31 */}

      <Cube
        itemName={AW23.tops.CurtainShirt.itemName}
        itemLink={AW23.tops.CurtainShirt.link}
        itemPrice={AW23.tops.CurtainShirt.itemPrice}
        picture01={AW23.tops.CurtainShirt.picture01}
        picture02={AW23.tops.CurtainShirt.picture02}
      />

      {/* ///////// */}
      <Cube
        itemName={SS23.tops.SleazyNetKnitGreen.itemName}
        itemLink={SS23.tops.SleazyNetKnitGreen.link}
        itemPrice={SS23.tops.SleazyNetKnitGreen.itemPrice}
        re
        picture01={SS23.tops.SleazyNetKnitGreen.picture01}
        picture02={SS23.tops.SleazyNetKnitGreen.picture02}
        soldOut={SS23.tops.SleazyNetKnitGreen.soldOut}
      />

      <Cube
        itemName={SS23.jackets.PanelledCoat.itemName}
        itemLink={SS23.jackets.PanelledCoat.link}
        itemPrice={SS23.jackets.PanelledCoat.itemPrice}
        picture01={SS23.jackets.PanelledCoat.picture01}
        picture02={SS23.jackets.PanelledCoat.picture02}
        soldOut={SS23.jackets.PanelledCoat.soldOut}
      />

      <Cube
        itemName={SS23.jackets.ChainCuffLeatherJacket.itemName}
        itemLink={SS23.jackets.ChainCuffLeatherJacket.link}
        itemPrice={SS23.jackets.ChainCuffLeatherJacket.itemPrice}
        picture01={SS23.jackets.ChainCuffLeatherJacket.picture01}
        picture02={SS23.jackets.ChainCuffLeatherJacket.picture02}
      />

      <Cube
        itemName={SS23.tops.LeatherBondedCableKnitVest.itemName}
        itemLink={SS23.tops.LeatherBondedCableKnitVest.link}
        itemPrice={SS23.tops.LeatherBondedCableKnitVest.itemPrice}
        picture01={SS23.tops.LeatherBondedCableKnitVest.picture01}
        picture02={SS23.tops.LeatherBondedCableKnitVest.picture02}
        soldOut={SS23.tops.LeatherBondedCableKnitVest.soldOut}
      />

      <Cube
        itemName={SS23.tops.LeatherVest.itemName}
        itemLink={SS23.tops.LeatherVest.link}
        itemPrice={SS23.tops.LeatherVest.itemPrice}
        picture01={SS23.tops.LeatherVest.picture01}
        picture02={SS23.tops.LeatherVest.picture02}
        soldOut={SS23.tops.LeatherVest.soldOut}
      />

      <Cube
        itemName={SS23.pants.ChainlockBikerPants.itemName}
        itemLink={SS23.pants.ChainlockBikerPants.link}
        itemPrice={SS23.pants.ChainlockBikerPants.itemPrice}
        picture01={SS23.pants.ChainlockBikerPants.picture01}
        picture02={SS23.pants.ChainlockBikerPants.picture02}
      />

      <Cube
        itemName={SS23.tops.SleazyNetKnitApricot.itemName}
        itemLink={SS23.tops.SleazyNetKnitApricot.link}
        itemPrice={SS23.tops.SleazyNetKnitApricot.itemPrice}
        picture01={SS23.tops.SleazyNetKnitApricot.picture01}
        picture02={SS23.tops.SleazyNetKnitApricot.picture02}
        soldOut={SS23.tops.SleazyNetKnitApricot.soldOut}
      />

      <Cube
        itemName={SS23.pants.GatheredLeatherPants.itemName}
        itemLink={SS23.pants.GatheredLeatherPants.link}
        itemPrice={SS23.pants.GatheredLeatherPants.itemPrice}
        picture01={SS23.pants.GatheredLeatherPants.picture01}
        picture02={SS23.pants.GatheredLeatherPants.picture02}
      />

      <Cube
        itemName={SS23.jackets.PanelledLeatherCoat.itemName}
        itemLink={SS23.jackets.PanelledLeatherCoat.link}
        itemPrice={SS23.jackets.PanelledLeatherCoat.itemPrice}
        picture01={SS23.jackets.PanelledLeatherCoat.picture01}
        picture02={SS23.jackets.PanelledLeatherCoat.picture02}
        soldOut={SS23.jackets.PanelledLeatherCoat.soldOut}
      />

      <Cube
        itemName={SS23.pants.ChainlockLeatherBikerPants.itemName}
        itemLink={SS23.pants.ChainlockLeatherBikerPants.link}
        itemPrice={SS23.pants.ChainlockLeatherBikerPants.itemPrice}
        picture01={SS23.pants.ChainlockLeatherBikerPants.picture01}
        picture02={SS23.pants.ChainlockLeatherBikerPants.picture02}
        soldOut={SS23.pants.ChainlockLeatherBikerPants.soldOut}
      />

      <Cube
        itemName={SS23.tops.GatheredSleeveShirtRedBrown.itemName}
        itemLink={SS23.tops.GatheredSleeveShirtRedBrown.link}
        itemPrice={SS23.tops.GatheredSleeveShirtRedBrown.itemPrice}
        picture01={SS23.tops.GatheredSleeveShirtRedBrown.picture01}
        picture02={SS23.tops.GatheredSleeveShirtRedBrown.picture02}
        soldOut={SS23.tops.GatheredSleeveShirtRedBrown.soldOut}
      />

      <Cube
        itemName={SS23.tops.RibKnitVest.itemName}
        itemLink={SS23.tops.RibKnitVest.link}
        itemPrice={SS23.tops.RibKnitVest.itemPrice}
        picture01={SS23.tops.RibKnitVest.picture01}
        picture02={SS23.tops.RibKnitVest.picture02}
      />

      <Cube
        itemName={SS23.tops.PanelledTee.itemName}
        itemLink={SS23.tops.PanelledTee.link}
        itemPrice={SS23.tops.PanelledTee.itemPrice}
        picture01={SS23.tops.PanelledTee.picture01}
        picture02={SS23.tops.PanelledTee.picture02}
      />

      <Cube
        itemName={SS23.pants.CowhidePencilSkirt.itemName}
        itemLink={SS23.pants.CowhidePencilSkirt.link}
        itemPrice={SS23.pants.CowhidePencilSkirt.itemPrice}
        picture01={SS23.pants.CowhidePencilSkirt.picture01}
        picture02={SS23.pants.CowhidePencilSkirt.picture02}
        soldOut={SS23.pants.CowhidePencilSkirt.soldOut}
      />

      <Cube
        itemName={SS23.tops.GatheredSleeveShirtWhite.itemName}
        itemLink={SS23.tops.GatheredSleeveShirtWhite.link}
        itemPrice={SS23.tops.GatheredSleeveShirtWhite.itemPrice}
        picture01={SS23.tops.GatheredSleeveShirtWhite.picture01}
        picture02={SS23.tops.GatheredSleeveShirtWhite.picture02}
      />

      <Cube
        itemName={SS23.pants.GatheredPants.itemName}
        itemLink={SS23.pants.GatheredPants.link}
        itemPrice={SS23.pants.GatheredPants.itemPrice}
        picture01={SS23.pants.GatheredPants.picture01}
        picture02={SS23.pants.GatheredPants.picture02}
      />

      <Cube
        itemName={SS23.tops.VestDress.itemName}
        itemLink={SS23.tops.VestDress.link}
        itemPrice={SS23.tops.VestDress.itemPrice}
        picture01={SS23.tops.VestDress.picture01}
        picture02={SS23.tops.VestDress.picture02}
        soldOut={SS23.tops.VestDress.soldOut}
      />

      <Cube
        itemName={SS23.tops.PhotographicPrintTee.itemName}
        itemLink={SS23.tops.PhotographicPrintTee.link}
        itemPrice={SS23.tops.PhotographicPrintTee.itemPrice}
        picture01={SS23.tops.PhotographicPrintTee.picture01}
        picture02={SS23.tops.PhotographicPrintTee.picture02}
      />

      <Cube
        itemName={SS23.tops.GatheredSleeveShirtDarkBrown.itemName}
        itemLink={SS23.tops.GatheredSleeveShirtDarkBrown.link}
        itemPrice={SS23.tops.GatheredSleeveShirtDarkBrown.itemPrice}
        picture01={SS23.tops.GatheredSleeveShirtDarkBrown.picture01}
        picture02={SS23.tops.GatheredSleeveShirtDarkBrown.picture02}
        soldOut={SS23.tops.GatheredSleeveShirtDarkBrown.soldOut}
      />

      <Cube
        itemName={SS23.pants.TyreSkirt.itemName}
        itemLink={SS23.pants.TyreSkirt.link}
        itemPrice={SS23.pants.TyreSkirt.itemPrice}
        picture01={SS23.pants.TyreSkirt.picture01}
        picture02={SS23.pants.TyreSkirt.picture02}
        soldOut={SS23.pants.TyreSkirt.soldOut}
      />

      <Cube
        itemName={SS23.tops.SleazyNetKnitBlack.itemName}
        itemLink={SS23.tops.SleazyNetKnitBlack.link}
        itemPrice={SS23.tops.SleazyNetKnitBlack.itemPrice}
        picture01={SS23.tops.SleazyNetKnitBlack.picture01}
        picture02={SS23.tops.SleazyNetKnitBlack.picture02}
        soldOut={SS23.tops.SleazyNetKnitBlack.soldOut}
      />

      <Cube
        itemName={SS23.tops.CowhideVest.itemName}
        itemLink={SS23.tops.CowhideVest.link}
        itemPrice={SS23.tops.CowhideVest.itemPrice}
        picture01={SS23.tops.CowhideVest.picture01}
        picture02={SS23.tops.CowhideVest.picture02}
        soldOut={SS23.tops.CowhideVest.soldOut}
      />

      <Cube
        itemName={SS23.jackets.NylonBomberVest.itemName}
        itemLink={SS23.jackets.NylonBomberVest.link}
        itemPrice={SS23.jackets.NylonBomberVest.itemPrice}
        picture01={SS23.jackets.NylonBomberVest.picture01}
        picture02={SS23.jackets.NylonBomberVest.picture02}
        soldOut={SS23.jackets.NylonBomberVest.soldOut}
      />

      <Cube
        itemName={SS23.pants.ChainlockPencilSkirt.itemName}
        itemLink={SS23.pants.ChainlockPencilSkirt.link}
        itemPrice={SS23.pants.ChainlockPencilSkirt.itemPrice}
        picture01={SS23.pants.ChainlockPencilSkirt.picture01}
        picture02={SS23.pants.ChainlockPencilSkirt.picture02}
      />

      {/* ///////////// SALE */}

      <Cube
        itemName={AW22.jackets.CharcoalLizardBlazer.itemName}
        itemLink={AW22.jackets.CharcoalLizardBlazer.link}
        itemPrice={AW22.jackets.CharcoalLizardBlazer.itemPrice}
        soldOut={AW22.jackets.CharcoalLizardBlazer.soldOut}
        picture01={AW22.jackets.CharcoalLizardBlazer.picture01}
        picture02={AW22.jackets.CharcoalLizardBlazer.picture02}
      />

      <Cube
        itemName={AW22.jackets.DropShoulderPUCoat.itemName}
        itemLink={AW22.jackets.DropShoulderPUCoat.link}
        itemPrice={AW22.jackets.DropShoulderPUCoat.itemPrice}
        soldOut={AW22.jackets.DropShoulderPUCoat.soldOut}
        picture01={AW22.jackets.DropShoulderPUCoat.picture01}
        picture02={AW22.jackets.DropShoulderPUCoat.picture02}
      />

      <Cube
        itemName={AW22.jackets.BomberWithRestrainedArms.itemName}
        itemLink={AW22.jackets.BomberWithRestrainedArms.link}
        itemPrice={AW22.jackets.BomberWithRestrainedArms.itemPrice}
        soldOut={AW22.jackets.BomberWithRestrainedArms.soldOut}
        picture01={AW22.jackets.BomberWithRestrainedArms.picture01}
        picture02={AW22.jackets.BomberWithRestrainedArms.picture02}
      />

      <Cube
        itemName={AW22.tops.NightShoreSilkShirt.itemName}
        itemLink={AW22.tops.NightShoreSilkShirt.link}
        itemPrice={AW22.tops.NightShoreSilkShirt.itemPrice}
        soldOut={AW22.tops.NightShoreSilkShirt.soldOut}
        picture01={AW22.tops.NightShoreSilkShirt.picture01}
        picture02={AW22.tops.NightShoreSilkShirt.picture02}
      />
      <Cube
        itemName={AW22.tops.SnowWoodEmbroideredTee.itemName}
        itemLink={AW22.tops.SnowWoodEmbroideredTee.link}
        itemPrice={AW22.tops.SnowWoodEmbroideredTee.itemPrice}
        soldOut={AW22.tops.SnowWoodEmbroideredTee.soldOut}
        picture01={AW22.tops.SnowWoodEmbroideredTee.picture01}
        picture02={AW22.tops.SnowWoodEmbroideredTee.picture02}
      />
      <Cube
        itemName={AW22.pants.AdjustableDrawstringPants.itemName}
        itemLink={AW22.pants.AdjustableDrawstringPants.link}
        itemPrice={AW22.pants.AdjustableDrawstringPants.itemPrice}
        soldOut={AW22.pants.AdjustableDrawstringPants.soldOut}
        picture01={AW22.pants.AdjustableDrawstringPants.picture01}
        picture02={AW22.pants.AdjustableDrawstringPants.picture02}
      />
      <Cube
        itemName={AW22.jackets.BikerJacketWithArmStraps.itemName}
        itemLink={AW22.jackets.BikerJacketWithArmStraps.link}
        itemPrice={AW22.jackets.BikerJacketWithArmStraps.itemPrice}
        soldOut={AW22.jackets.BikerJacketWithArmStraps.soldOut}
        picture01={AW22.jackets.BikerJacketWithArmStraps.picture01}
        picture02={AW22.jackets.BikerJacketWithArmStraps.picture02}
      />
      <Cube
        itemName={AW22.tops.NightShoreEmbroideredTee.itemName}
        itemLink={AW22.tops.NightShoreEmbroideredTee.link}
        itemPrice={AW22.tops.NightShoreEmbroideredTee.itemPrice}
        soldOut={AW22.tops.NightShoreEmbroideredTee.soldOut}
        picture01={AW22.tops.NightShoreEmbroideredTee.picture01}
        picture02={AW22.tops.NightShoreEmbroideredTee.picture02}
      />
      <Cube
        itemName={AW22.jackets.LeatherBomberWithRestrainedArms.itemName}
        itemLink={AW22.jackets.LeatherBomberWithRestrainedArms.link}
        itemPrice={AW22.jackets.LeatherBomberWithRestrainedArms.itemPrice}
        soldOut={AW22.jackets.LeatherBomberWithRestrainedArms.soldOut}
        picture01={AW22.jackets.LeatherBomberWithRestrainedArms.picture01}
        picture02={AW22.jackets.LeatherBomberWithRestrainedArms.picture02}
      />
      <Cube
        itemName={AW22.tops.CamouflageZipUpWorkShirt.itemName}
        itemLink={AW22.tops.CamouflageZipUpWorkShirt.link}
        itemPrice={AW22.tops.CamouflageZipUpWorkShirt.itemPrice}
        soldOut={AW22.tops.CamouflageZipUpWorkShirt.soldOut}
        picture01={AW22.tops.CamouflageZipUpWorkShirt.picture01}
        picture02={AW22.tops.CamouflageZipUpWorkShirt.picture02}
      />
      <Cube
        itemName={AW22.pants.MudRootPrintedJeans.itemName}
        itemLink={AW22.pants.MudRootPrintedJeans.link}
        itemPrice={AW22.pants.MudRootPrintedJeans.itemPrice}
        soldOut={AW22.pants.MudRootPrintedJeans.soldOut}
        picture01={AW22.pants.MudRootPrintedJeans.picture01}
        picture02={AW22.pants.MudRootPrintedJeans.picture02}
      />
      <Cube
        itemName={AW22.tops.MudRootCompressionTop.itemName}
        itemLink={AW22.tops.MudRootCompressionTop.link}
        itemPrice={AW22.tops.MudRootCompressionTop.itemPrice}
        soldOut={AW22.tops.MudRootCompressionTop.soldOut}
        picture01={AW22.tops.MudRootCompressionTop.picture01}
        picture02={AW22.tops.MudRootCompressionTop.picture02}
      />
      <Cube
        itemName={AW22.tops.MixedRibZipUpCardigan.itemName}
        itemLink={AW22.tops.MixedRibZipUpCardigan.link}
        itemPrice={AW22.tops.MixedRibZipUpCardigan.itemPrice}
        soldOut={AW22.tops.MixedRibZipUpCardigan.soldOut}
        picture01={AW22.tops.MixedRibZipUpCardigan.picture01}
        picture02={AW22.tops.MixedRibZipUpCardigan.picture02}
      />
      <Cube
        itemName={AW22.jackets.PanelledMacCoat.itemName}
        itemLink={AW22.jackets.PanelledMacCoat.link}
        itemPrice={AW22.jackets.PanelledMacCoat.itemPrice}
        soldOut={AW22.jackets.PanelledMacCoat.soldOut}
        picture01={AW22.jackets.PanelledMacCoat.picture01}
        picture02={AW22.jackets.PanelledMacCoat.picture02}
      />
      {/*  <Cube
        itemName={AW22.tops.AdjustableDrawstringTopGrey.itemName}
        itemLink={AW22.tops.AdjustableDrawstringTopGrey.link}
        itemPrice={AW22.tops.AdjustableDrawstringTopGrey.itemPrice}
        soldOut={AW22.tops.AdjustableDrawstringTopGrey.soldOut}
        picture01={AW22.tops.AdjustableDrawstringTopGrey.picture01}
        picture02={AW22.tops.AdjustableDrawstringTopGrey.picture02}
      /> */}
      <Cube
        itemName={AW22.pants.LeatherBikerPants.itemName}
        itemLink={AW22.pants.LeatherBikerPants.link}
        itemPrice={AW22.pants.LeatherBikerPants.itemPrice}
        soldOut={AW22.pants.LeatherBikerPants.soldOut}
        picture01={AW22.pants.LeatherBikerPants.picture01}
        picture02={AW22.pants.LeatherBikerPants.picture02}
      />
      <Cube
        itemName={AW22.tops.NightShoreCompressionTop.itemName}
        itemLink={AW22.tops.NightShoreCompressionTop.link}
        itemPrice={AW22.tops.NightShoreCompressionTop.itemPrice}
        soldOut={AW22.tops.NightShoreCompressionTop.soldOut}
        picture01={AW22.tops.NightShoreCompressionTop.picture01}
        picture02={AW22.tops.NightShoreCompressionTop.picture02}
      />
      <Cube
        itemName={AW22.pants.CamouflageCowhidePants.itemName}
        itemLink={AW22.pants.CamouflageCowhidePants.link}
        itemPrice={AW22.pants.CamouflageCowhidePants.itemPrice}
        soldOut={AW22.pants.CamouflageCowhidePants.soldOut}
        picture01={AW22.pants.CamouflageCowhidePants.picture01}
        picture02={AW22.pants.CamouflageCowhidePants.picture02}
      />
      <Cube
        itemName={AW22.tops.KnittedTankWithShoulderCap.itemName}
        itemLink={AW22.tops.KnittedTankWithShoulderCap.link}
        itemPrice={AW22.tops.KnittedTankWithShoulderCap.itemPrice}
        picture01={AW22.tops.KnittedTankWithShoulderCap.picture01}
        picture02={AW22.tops.KnittedTankWithShoulderCap.picture02}
        soldOut={AW22.tops.KnittedTankWithShoulderCap.soldOut}
      />
      <Cube
        itemName={AW22.tops.AdjustableDrawstringTopBeige.itemName}
        itemLink={AW22.tops.AdjustableDrawstringTopBeige.link}
        itemPrice={AW22.tops.AdjustableDrawstringTopBeige.itemPrice}
        soldOut={AW22.tops.AdjustableDrawstringTopBeige.soldOut}
        picture01={AW22.tops.AdjustableDrawstringTopBeige.picture01}
        picture02={AW22.tops.AdjustableDrawstringTopBeige.picture02}
      />
      <Cube
        itemName={AW22.tops.MudRootEmbroideredTee.itemName}
        itemLink={AW22.tops.MudRootEmbroideredTee.link}
        itemPrice={AW22.tops.MudRootEmbroideredTee.itemPrice}
        soldOut={AW22.tops.MudRootEmbroideredTee.soldOut}
        picture01={AW22.tops.MudRootEmbroideredTee.picture01}
        picture02={AW22.tops.MudRootEmbroideredTee.picture02}
      />

      {/*   01 Chainlock Leather Coat   */}

      <Cube
        itemName={SS22.jackets.chainlockLeatherCoat.itemName}
        itemLink={SS22.jackets.chainlockLeatherCoat.link}
        itemPrice={SS22.jackets.chainlockLeatherCoat.itemPrice}
        soldOut={SS22.jackets.chainlockLeatherCoat.soldOut}
        picture01={SS22.jackets.chainlockLeatherCoat.picture01}
        picture02={SS22.jackets.chainlockLeatherCoat.picture02}
      />

      {/*   02 Mohair Loose Knit  Jumper Sand   */}

      <Cube
        itemName={SS22.tops.mohairLooseKnitJumper.itemName}
        itemPrice={SS22.tops.mohairLooseKnitJumper.itemPrice}
        itemLink={SS22.tops.mohairLooseKnitJumper.sand.link}
        soldOut={SS22.tops.mohairLooseKnitJumper.soldOut}
        picture01={SS22.tops.mohairLooseKnitJumper.sand.picture01}
        picture02={SS22.tops.mohairLooseKnitJumper.sand.picture02}
      />

      {/*   03  Cable Knit tank  black */}

      {/*    <Cube
        itemName={SS22.tops.cableKnitTank.itemName}
        itemPrice={SS22.tops.cableKnitTank.itemPrice}
        itemLink={SS22.tops.cableKnitTank.black.link}
        soldOut={SS22.tops.cableKnitTank.soldOut}
        picture01={SS22.tops.cableKnitTank.black.picture01}
        picture02={SS22.tops.cableKnitTank.black.picture02}
      /> */}

      {/*   04 Motobike Compression top    */}

      <Cube
        itemName={SS22.tops.motorbikeCompressionTop.itemName}
        itemPrice={SS22.tops.motorbikeCompressionTop.itemPrice}
        itemLink={SS22.tops.motorbikeCompressionTop.link}
        soldOut={SS22.tops.motorbikeCompressionTop.soldOut}
        picture01={SS22.tops.motorbikeCompressionTop.picture01}
        picture02={SS22.tops.motorbikeCompressionTop.picture02}
      />

      {/*   05 Two-piece_Leather_Vest orange  */}

      <Cube
        itemName={SS22.tops.twopieceLeatherVestOrange.itemName}
        itemPrice={SS22.tops.twopieceLeatherVestOrange.itemPrice}
        itemLink={SS22.tops.twopieceLeatherVestOrange.link}
        soldOut={SS22.tops.twopieceLeatherVestOrange.soldOut}
        picture01={SS22.tops.twopieceLeatherVestOrange.picture01}
        picture02={SS22.tops.twopieceLeatherVestOrange.picture02}
      />

      {/*   06 Chainlock_Nylon_Pants   */}

      <Cube
        itemName={SS22.pants.chainlockNylonPants.itemName}
        itemPrice={SS22.pants.chainlockNylonPants.itemPrice}
        itemLink={SS22.pants.chainlockNylonPants.link}
        soldOut={SS22.pants.chainlockNylonPants.soldOut}
        picture01={SS22.pants.chainlockNylonPants.picture01}
        picture02={SS22.pants.chainlockNylonPants.picture02}
      />

      {/*   07 Detachable_Sleeve_Bomber  grey */}

      <Cube
        itemName={SS22.jackets.detachableSleeveBomberGrey.itemName}
        itemPrice={SS22.jackets.detachableSleeveBomberGrey.itemPrice}
        itemLink={SS22.jackets.detachableSleeveBomberGrey.link}
        soldOut={SS22.jackets.detachableSleeveBomberGrey.soldOut}
        picture01={SS22.jackets.detachableSleeveBomberGrey.picture01}
        picture02={SS22.jackets.detachableSleeveBomberGrey.picture02}
      />

      {/*   08 Shark Speciment Resort shirt   */}

      <Cube
        itemName={SS22.tops.sharkSpecimenResortShirt.itemName}
        itemPrice={SS22.tops.sharkSpecimenResortShirt.itemPrice}
        itemLink={SS22.tops.sharkSpecimenResortShirt.link}
        soldOut={SS22.tops.sharkSpecimenResortShirt.soldOut}
        picture01={SS22.tops.sharkSpecimenResortShirt.picture01}
        picture02={SS22.tops.sharkSpecimenResortShirt.picture02}
      />

      {/*   09 Wet_Specimen_Tee   */}

      <Cube
        itemName={SS22.tops.wetSpecimenTee.itemName}
        itemPrice={SS22.tops.wetSpecimenTee.itemPrice}
        itemLink={SS22.tops.wetSpecimenTee.link}
        soldOut={SS22.tops.wetSpecimenTee.soldOut}
        picture01={SS22.tops.wetSpecimenTee.picture01}
        picture02={SS22.tops.wetSpecimenTee.picture02}
      />

      {/*   10 Shark_Specimen_Boxer_Shorts   */}

      <Cube
        itemName={SS22.pants.sharkSpecimenBoxerShorts.itemName}
        itemPrice={SS22.pants.sharkSpecimenBoxerShorts.itemPrice}
        itemLink={SS22.pants.sharkSpecimenBoxerShorts.link}
        soldOut={SS22.pants.sharkSpecimenBoxerShorts.soldOut}
        picture01={SS22.pants.sharkSpecimenBoxerShorts.picture01}
        picture02={SS22.pants.sharkSpecimenBoxerShorts.picture02}
      />

      {/*   11 Panneled Wool coat   */}

      <Cube
        itemName={SS22.jackets.panneledWoolCoat.itemName}
        itemLink={SS22.jackets.panneledWoolCoat.link}
        itemPrice={SS22.jackets.panneledWoolCoat.itemPrice}
        soldOut={SS22.jackets.panneledWoolCoat.soldOut}
        picture01={SS22.jackets.panneledWoolCoat.picture01}
        picture02={SS22.jackets.panneledWoolCoat.picture02}
      />

      {/*   13 Panneled_Leather_Biker_Pants  */}

      <Cube
        itemName={SS22.pants.panneledLeatherBikerPants.itemName}
        itemPrice={SS22.pants.panneledLeatherBikerPants.itemPrice}
        itemLink={SS22.pants.panneledLeatherBikerPants.link}
        soldOut={SS22.pants.panneledLeatherBikerPants.soldOut}
        picture01={SS22.pants.panneledLeatherBikerPants.picture01}
        picture02={SS22.pants.panneledLeatherBikerPants.picture02}
      />

      {/*   14 Leather_Zip_Shirt_Jacket  */}

      <Cube
        itemName={SS22.jackets.leatherZipShirtJacket.itemName}
        itemLink={SS22.jackets.leatherZipShirtJacket.link}
        itemPrice={SS22.jackets.leatherZipShirtJacket.itemPrice}
        picture01={SS22.jackets.leatherZipShirtJacket.picture01}
        picture02={SS22.jackets.leatherZipShirtJacket.picture02}
      />

      {/*   15 Two-Piece_Leather-Vest  black*/}

      <Cube
        itemName={SS22.tops.twopieceLeatherVestBlack.itemName}
        itemPrice={SS22.tops.twopieceLeatherVestBlack.itemPrice}
        itemLink={SS22.tops.twopieceLeatherVestBlack.link}
        soldOut={SS22.tops.twopieceLeatherVestBlack.soldOut}
        picture01={SS22.tops.twopieceLeatherVestBlack.picture01}
        picture02={SS22.tops.twopieceLeatherVestBlack.picture02}
      />

      {/*   16 Lamb_Skin_Work_Jacket green */}

      {/*  <Cube
        itemName={SS22.jackets.lambSkinWorkJacket.itemName}
        itemPrice={SS22.jackets.lambSkinWorkJacket.itemPrice}
        itemLink={SS22.jackets.lambSkinWorkJacket.green.link}
        soldOut={SS22.jackets.lambSkinWorkJacket.soldOut}
        picture01={SS22.jackets.lambSkinWorkJacket.green.picture01}
        picture02={SS22.jackets.lambSkinWorkJacket.green.picture02}
      /> */}

      {/*   17 Charcoal_Lizard_Speciment_pant  */}

      <Cube
        itemName={SS22.pants.charcoalLizardSpecimenPants.itemName}
        itemPrice={SS22.pants.charcoalLizardSpecimenPants.itemPrice}
        itemLink={SS22.pants.charcoalLizardSpecimenPants.link}
        soldOut={SS22.pants.charcoalLizardSpecimenPants.soldOut}
        picture01={SS22.pants.charcoalLizardSpecimenPants.picture01}
        picture02={SS22.pants.charcoalLizardSpecimenPants.picture02}
      />

      {/*   18 Asymetrical_Bleached_Tank  */}

      <Cube
        itemName={SS22.tops.asymetricalBleachedTank.itemName}
        itemPrice={SS22.tops.asymetricalBleachedTank.itemPrice}
        itemLink={SS22.tops.asymetricalBleachedTank.link}
        soldOut={SS22.tops.asymetricalBleachedTank.soldOut}
        picture01={SS22.tops.asymetricalBleachedTank.picture01}
        picture02={SS22.tops.asymetricalBleachedTank.picture02}
      />

      {/*   19 Iron Gate Compression top  */}

      <Cube
        itemName={SS22.tops.ironGateCompressionTop.itemName}
        itemPrice={SS22.tops.ironGateCompressionTop.itemPrice}
        itemLink={SS22.tops.ironGateCompressionTop.link}
        soldOut={SS22.tops.ironGateCompressionTop.soldOut}
        picture01={SS22.tops.ironGateCompressionTop.picture01}
        picture02={SS22.tops.ironGateCompressionTop.picture02}
      />

      {/*   20 Chainlock Leather vest  */}

      {/*  <Cube
        itemName={SS22.tops.chainlockLeatherVest.itemName}
        itemPrice={SS22.tops.chainlockLeatherVest.itemPrice}
        itemLink={SS22.tops.chainlockLeatherVest.link}
        soldOut={SS22.tops.chainlockLeatherVest.soldOut}
        picture01={SS22.tops.chainlockLeatherVest.picture01}
        picture02={SS22.tops.chainlockLeatherVest.picture02}
      /> */}

      {/*   21 Chainlock_Denim_Jacket  */}

      <Cube
        itemName={SS22.jackets.chainlockDenimJacket.itemName}
        itemPrice={SS22.jackets.chainlockDenimJacket.itemPrice}
        itemLink={SS22.jackets.chainlockDenimJacket.link}
        soldOut={SS22.jackets.chainlockDenimJacket.soldOut}
        picture01={SS22.jackets.chainlockDenimJacket.picture01}
        picture02={SS22.jackets.chainlockDenimJacket.picture02}
      />

      {/*   22 Skycrapers_Compression_Top */}

      {/* <Cube
        itemName={SS22.tops.skyscrapersCompressionTop.itemName}
        itemPrice={SS22.tops.skyscrapersCompressionTop.itemPrice}
        itemLink={SS22.tops.skyscrapersCompressionTop.link}
        soldOut={SS22.tops.skyscrapersCompressionTop.soldOut}
        picture01={SS22.tops.skyscrapersCompressionTop.picture01}
        picture02={SS22.tops.skyscrapersCompressionTop.picture02}
      /> */}

      {/*   23 Shark_Specimen_Shirt*/}

      <Cube
        itemName={SS22.tops.sharkSpecimenShirt.itemName}
        itemPrice={SS22.tops.sharkSpecimenShirt.itemPrice}
        itemLink={SS22.tops.sharkSpecimenShirt.link}
        soldOut={SS22.tops.sharkSpecimenShirt.soldOut}
        picture01={SS22.tops.sharkSpecimenShirt.picture01}
        picture02={SS22.tops.sharkSpecimenShirt.picture02}
      />

      {/*   24 Charcoal Lizard Specimen jacket*/}

      <Cube
        itemName={SS22.jackets.charcoalLizardSpecimenJacket.itemName}
        itemPrice={SS22.jackets.charcoalLizardSpecimenJacket.itemPrice}
        itemLink={SS22.jackets.charcoalLizardSpecimenJacket.link}
        soldOut={SS22.jackets.charcoalLizardSpecimenJacket.soldOut}
        picture01={SS22.jackets.charcoalLizardSpecimenJacket.picture01}
        picture02={SS22.jackets.charcoalLizardSpecimenJacket.picture02}
      />

      {/*   25 Detachable_Sleeve_Bomber_pink*/}

      <Cube
        itemName={SS22.jackets.detachableSleeveBomberPink.itemName}
        itemPrice={SS22.jackets.detachableSleeveBomberPink.itemPrice}
        itemLink={SS22.jackets.detachableSleeveBomberPink.link}
        soldOut={SS22.jackets.detachableSleeveBomberPink.soldOut}
        picture01={SS22.jackets.detachableSleeveBomberPink.picture01}
        picture02={SS22.jackets.detachableSleeveBomberPink.picture02}
      />

      {/*   26 Lamb_Skin_Work_Jacket black*/}

      <Cube
        itemName={SS22.jackets.lambSkinWorkJacket.itemName}
        itemPrice={SS22.jackets.lambSkinWorkJacket.itemPrice}
        itemLink={SS22.jackets.lambSkinWorkJacket.black.link}
        soldOut={SS22.jackets.lambSkinWorkJacket.soldOut}
        picture01={SS22.jackets.lambSkinWorkJacket.black.picture01}
        picture02={SS22.jackets.lambSkinWorkJacket.black.picture02}
      />

      {/*   27 Mohair_Loose_Knit_Jumper orange*/}

      {/*    <Cube
        itemName={SS22.tops.mohairLooseKnitJumper.itemName}
        itemPrice={SS22.tops.mohairLooseKnitJumper.itemPrice}
        itemLink={SS22.tops.mohairLooseKnitJumper.orange.link}
        soldOut={SS22.tops.mohairLooseKnitJumper.soldOut}
        picture01={SS22.tops.mohairLooseKnitJumper.orange.picture01}
        picture02={SS22.tops.mohairLooseKnitJumper.orange.picture02}
      /> */}

      {/*   28 Lizard_Specimen_Compression_Top*/}
      <Cube
        itemName={SS22.tops.lizardSpecimenCompressionTop.itemName}
        itemPrice={SS22.tops.lizardSpecimenCompressionTop.itemPrice}
        itemLink={SS22.tops.lizardSpecimenCompressionTop.link}
        soldOut={SS22.tops.lizardSpecimenCompressionTop.soldOut}
        picture01={SS22.tops.lizardSpecimenCompressionTop.picture01}
        picture02={SS22.tops.lizardSpecimenCompressionTop.picture02}
      />

      {/*   29 Cable_Knit_Tank cream*/}

      <Cube
        itemName={SS22.tops.cableKnitTank.itemName}
        itemPrice={SS22.tops.cableKnitTank.itemPrice}
        itemLink={SS22.tops.cableKnitTank.cream.link}
        soldOut={SS22.tops.cableKnitTank.soldOut}
        picture01={SS22.tops.cableKnitTank.cream.picture01}
        picture02={SS22.tops.cableKnitTank.cream.picture02}
      />

      {/*   30 Charcoal_Lizard_Specimen_Overshirt*/}

      <Cube
        itemName={SS22.tops.charcoalLizardSpecimenOvershirt.itemName}
        itemPrice={SS22.tops.charcoalLizardSpecimenOvershirt.itemPrice}
        itemLink={SS22.tops.charcoalLizardSpecimenOvershirt.link}
        soldOut={SS22.tops.charcoalLizardSpecimenOvershirt.soldOut}
        picture01={SS22.tops.charcoalLizardSpecimenOvershirt.picture01}
        picture02={SS22.tops.charcoalLizardSpecimenOvershirt.picture02}
      />

      {/*   31 Chainlock_Jeans*/}

      <Cube
        itemName={SS22.pants.chainlockJeans.itemName}
        itemPrice={SS22.pants.chainlockJeans.itemPrice}
        itemLink={SS22.pants.chainlockJeans.link}
        soldOut={SS22.pants.chainlockJeans.soldOut}
        picture01={SS22.pants.chainlockJeans.picture01}
        picture02={SS22.pants.chainlockJeans.picture02}
      />

      {/*   32 Chainlock_Tee*/}

      <Cube
        itemName={SS22.tops.chainlockTee.itemName}
        itemPrice={SS22.tops.chainlockTee.itemPrice}
        itemLink={SS22.tops.chainlockTee.link}
        soldOut={SS22.tops.chainlockTee.soldOut}
        picture01={SS22.tops.chainlockTee.picture01}
        picture02={SS22.tops.chainlockTee.picture02}
      />

      {/*   33 Eyelet Denim Shorts*/}

      <Cube
        itemName={SS22.pants.eyeletDenimShorts.itemName}
        itemPrice={SS22.pants.eyeletDenimShorts.itemPrice}
        itemLink={SS22.pants.eyeletDenimShorts.link}
        soldOut={SS22.pants.eyeletDenimShorts.soldOut}
        picture01={SS22.pants.eyeletDenimShorts.picture01}
        picture02={SS22.pants.eyeletDenimShorts.picture02}
      />

      {/*   34 Lizard_Specimen_Tee*/}

      <Cube
        itemName={SS22.tops.lizardSpecimenTee.itemName}
        itemPrice={SS22.tops.lizardSpecimenTee.itemPrice}
        itemLink={SS22.tops.lizardSpecimenTee.link}
        soldOut={SS22.tops.lizardSpecimenTee.soldOut}
        picture01={SS22.tops.lizardSpecimenTee.picture01}
        picture02={SS22.tops.lizardSpecimenTee.picture02}
      />

      {/*   3 Chainlock Leather shorts*/}

      {/*     <Cube
        itemName={SS22.pants.chainlockLeatherShorts.itemName}
        itemPrice={SS22.pants.chainlockLeatherShorts.itemPrice}
        itemLink={SS22.pants.chainlockLeatherShorts.link}
        picture01={SS22.pants.chainlockLeatherShorts.picture01}
        picture02={SS22.pants.chainlockLeatherShorts.picture02}
      /> */}

      {/*   36 Detachable Sleeve bomber black*/}

      <Cube
        itemName={SS22.jackets.detachableSleeveBomberBlack.itemName}
        itemPrice={SS22.jackets.detachableSleeveBomberBlack.itemPrice}
        itemLink={SS22.jackets.detachableSleeveBomberBlack.link}
        soldOut={SS22.jackets.detachableSleeveBomberBlack.soldOut}
        picture01={SS22.jackets.detachableSleeveBomberBlack.picture01}
        picture02={SS22.jackets.detachableSleeveBomberBlack.picture02}
      />

      {/*   37 Charcoal Wet Specimen shorts */}

      {/*  <Cube
        itemName={SS22.pants.charcoalWetSpecimenShorts.itemName}
        itemPrice={SS22.pants.charcoalWetSpecimenShorts.itemPrice}
        itemLink={SS22.pants.charcoalWetSpecimenShorts.link}
        soldOut={SS22.pants.charcoalWetSpecimenShorts.soldOut}
        picture01={SS22.pants.charcoalWetSpecimenShorts.picture01}
        picture02={SS22.pants.charcoalWetSpecimenShorts.picture02}
      />
 */}
      {/*   38 Double_Breasted_Lock_Blazer */}

      <Cube
        itemName={SS22.jackets.doubleBreastedLockBlazer.itemName}
        itemPrice={SS22.jackets.doubleBreastedLockBlazer.itemPrice}
        itemLink={SS22.jackets.doubleBreastedLockBlazer.link}
        soldOut={SS22.jackets.doubleBreastedLockBlazer.soldOut}
        picture01={SS22.jackets.doubleBreastedLockBlazer.picture01}
        picture02={SS22.jackets.doubleBreastedLockBlazer.picture02}
      />

      {/*   39 Skycrapers_Tee */}

      {/*     <Cube
        itemName={SS22.tops.skyscrapersTee.itemName}
        itemPrice={SS22.tops.skyscrapersTee.itemPrice}
        itemLink={SS22.tops.skyscrapersTee.link}
        soldOut={SS22.tops.skyscrapersTee.soldOut}
        picture01={SS22.tops.skyscrapersTee.picture01}
        picture02={SS22.tops.skyscrapersTee.picture02}
      /> */}
    </EshopContainer>
  );
};

export default Eshop;
