import React from "react";
import galleryImages from "../js/database/database-gallery.js";

import { SwiperSlide } from "swiper/react";

import setBodyColor from "../js/setBodyColor";
import setFontColor from "../js/setFontColor";

import MainPagesContainer from "../components/containers/MainPagesContainer.js";

const alt = "PRE23 collection";
const imgWidth = "1000";
const imgHeight = "1500";

const Gallery = () => {
  setBodyColor({ color: "#ffffff" });
  setFontColor({ color: "#c3c3c3" });

  return (
    <MainPagesContainer
      pageTitle={alt}
      videoDisplay={{ display: "none" }}
      topMenuDisplay={{ display: "none" }}
    >
      {React.Children.toArray(
        galleryImages.map(({ srcJpeg, srcWebp, loading }) => (
          <SwiperSlide>
            <picture>
              <source srcSet={srcWebp} type="image/webp" />
              <img
                src={srcJpeg}
                width={imgWidth}
                height={imgHeight}
                alt={alt}
                loading="eager"
              />
            </picture>
          </SwiperSlide>
        ))
      )}
    </MainPagesContainer>
  );
};

export default Gallery;
