import React from "react";

import Cube from "../../components/cube";

import { AW23 } from "../../js/database/database-aw23-eshop.js";
import { SS24 } from "../../js/database/database-ss24-eshop.js";

import EshopContainer from "../../components/containers/EshopContainer";

const Accessories = (props) => {
  const accessoriesSS24 = Object.keys(SS24.accessories).map((key) => {
    const accessorie = SS24.accessories[key];
    return (
      <Cube
        key={key}
        itemName={accessorie.itemName}
        itemLink={accessorie.link}
        itemPrice={accessorie.itemPrice}
        picture01={accessorie.picture01}
        picture02={accessorie.picture02}
        soldOut={accessorie.soldOut}
      />
    );
  });

  const accessoriesAW23 = Object.keys(AW23.accessories).map((key) => {
    const accessorie = AW23.accessories[key];
    return (
      <Cube
        key={key}
        itemName={accessorie.itemName}
        itemLink={accessorie.link}
        itemPrice={accessorie.itemPrice}
        picture01={accessorie.picture01}
        picture02={accessorie.picture02}
        soldOut={accessorie.soldOut}
      />
    );
  });

  return (
    <EshopContainer pageTitle={"Accessories"} shopClassName={"circle"}>
      {accessoriesSS24}
      {accessoriesAW23}
    </EshopContainer>
  );
};

export default Accessories;
