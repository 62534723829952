import React, { useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NavLink } from "react-router-dom"
/* import { eshopLoading } from "../js/eshopLoading";
 */

const Cube = (props) => {

    const [loaded, setLoaded] = useState(false);

    /*   useEffect(() => {
          eshopLoading();
      }); */

    return <NavLink to={props.itemLink}>
        <div className={`content ${props.itemCategory}`}>
            {/*          <img src={props.picture01} alt="Ximon Lee eshop" />
            <img src={props.picture02} className="img-bottom" alt="Ximon Lee eshop" /> */}

            {/*   <LazyLoadImage
                src={props.picture01}
                height="1250"
                width="833"
                effect="blur"
                alt={props.alt}
            />
            <LazyLoadImage
                src={props.picture02}
                height="1250"
                width="833"
                className="img-bottom"
                alt={props.alt}
            /> */}

            {/*      <img src={""} data-src={props.picture01} onLoad={() => setLoaded(true)} className={loaded ? 'loaded' : 'loading'} alt="." width="833"
                height="1250" />
            <img src={""} data-src={props.picture02} className="img-bottom" alt="." width="833"
                height="1250" /> */}

            <img src={props.picture01} alt="Ximon Lee eshop" height="1250"
                width="833" className="img-top" />
            <img src={props.picture02} loading="lazy" className="img-bottom" alt="Ximon Lee eshop" height="1250"
                width="833" />

            <p className="text">{props.itemName} · {props.itemPrice}</p>
            {/* <p className="sold-out">{props.soldOut}</p>  old */}
            {props.soldOut && <p className="sold-out">Sold Out</p>} {/* new */}


        </div>
    </NavLink>

}

export default Cube;