import React from "react";

import Cube from "../../components/cube";

import { SS23 } from "../../js/database/database-ss23.js";
import { AW23 } from "../../js/database/database-aw23-eshop.js";
import { SS24 } from "../../js/database/database-ss24-eshop.js";

import EshopContainer from "../../components/containers/EshopContainer";

const Pants = (props) => {
  const pantsSS24 = Object.keys(SS24.pants).map((key) => {
    const pant = SS24.pants[key];
    return (
      <Cube
        key={key}
        itemName={pant.itemName}
        itemLink={pant.link}
        itemPrice={pant.itemPrice}
        picture01={pant.picture01}
        picture02={pant.picture02}
        soldOut={pant.soldOut}
      />
    );
  });

  const pantsAW23 = Object.keys(AW23.pants).map((key) => {
    const pant = AW23.pants[key];
    return (
      <Cube
        key={key}
        itemName={pant.itemName}
        itemLink={pant.link}
        itemPrice={pant.itemPrice}
        picture01={pant.picture01}
        picture02={pant.picture02}
        soldOut={pant.soldOut}
      />
    );
  });

  const pantsSS23 = Object.keys(SS23.pants).map((key) => {
    const pant = SS23.pants[key];
    return (
      <Cube
        key={key}
        itemName={pant.itemName}
        itemLink={pant.link}
        itemPrice={pant.itemPrice}
        picture01={pant.picture01}
        picture02={pant.picture02}
        soldOut={pant.soldOut}
      />
    );
  });

  return (
    <EshopContainer pageTitle={"Pants"} shopClassName={"circle"}>
      {pantsSS24}
      {pantsAW23}
      {pantsSS23}
    </EshopContainer>
  );
};

export default Pants;
