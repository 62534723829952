import React, { useEffect, useRef, useState } from 'react';

const NewsletterModal = ({ isOpen, onClose }) => {

    const [isModalShown, setIsModalShown] = useState(false);

    useEffect(() => {
        const modalShown = localStorage.getItem('modalShown');
        if (modalShown !== 'true') {
            setIsModalShown(true);
            localStorage.setItem('modalShown', 'true');
        }
    }, []);

    const closeModal = () => {
        setIsModalShown(false);
        onClose();
    };


    useEffect(() => {

        const script1 = document.createElement('script');
        script1.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
        script1.type = 'text/javascript';
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.innerHTML = `(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);`;
        document.body.appendChild(script2);


        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);


    if (!isOpen) return null;

    return (

        isModalShown && (
            <div className="modal">
                <button className="modal-close-button" /* onClick={onClose}  */ onClick={closeModal}>X</button>
                <h4>Join our newsletter to get notified on new arrivals, events <br /> and special announcement. Get 10% off your first order.</h4>
                <form action="https://ximonlee.us22.list-manage.com/subscribe/post?u=52e3cb90c16ac3bc074a9245a&amp;id=f750569e53&amp;f_id=00dbc3e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">

                    <div className="mc-field-group">
                        <input type="email" name="EMAIL" /* className="required email" */ placeholder="email" id="mce-EMAIL" required="" />
                    </div>

                    <div className="optionalParent">
                        <div className="clear foot">
                            <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" />
                        </div>
                    </div>
                </form>

                <div id="mce-responses" className="clear foot">
                    <div className="response" id="mce-error-response" style={{ display: 'none', color: 'black' }}></div>
                    <div className="response" id="mce-success-response" style={{ display: 'none', color: 'black' }}>
                    </div>
                </div>

            </div>
        )
    )
};

export default NewsletterModal;