import React from "react";

import Cube from "../../components/cube";

import { SS23 } from "../../js/database/database-ss23.js";
import { AW23 } from "../../js/database/database-aw23-eshop.js";
import { SS24 } from "../../js/database/database-ss24-eshop.js";

import EshopContainer from "../../components/containers/EshopContainer";

const Jackets = () => {
  const jacketsSS24 = Object.keys(SS24.jackets).map((key) => {
    const jacket = SS24.jackets[key];
    return (
      <Cube
        key={key}
        itemName={jacket.itemName}
        itemLink={jacket.link}
        itemPrice={jacket.itemPrice}
        picture01={jacket.picture01}
        picture02={jacket.picture02}
        soldOut={jacket.soldOut}
      />
    );
  });

  const jacketsAW23 = Object.keys(AW23.jackets).map((key) => {
    const jacket = AW23.jackets[key];
    return (
      <Cube
        key={key}
        itemName={jacket.itemName}
        itemLink={jacket.link}
        itemPrice={jacket.itemPrice}
        picture01={jacket.picture01}
        picture02={jacket.picture02}
        soldOut={jacket.soldOut}
      />
    );
  });

  const jacketsSS23 = Object.keys(SS23.jackets).map((key) => {
    const jacket = SS23.jackets[key];
    return (
      <Cube
        key={key}
        itemName={jacket.itemName}
        itemLink={jacket.link}
        itemPrice={jacket.itemPrice}
        picture01={jacket.picture01}
        picture02={jacket.picture02}
        soldOut={jacket.soldOut}
      />
    );
  });

  return (
    <EshopContainer pageTitle={"Jackets"} shopClassName={"circle"}>
      {jacketsSS24}
      {jacketsAW23}
      {jacketsSS23}
    </EshopContainer>
  );
};

export default Jackets;
